import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import KadroModal from '@/components/common/KadroModal.jsx';

import AttendanceOvertimeTimeSpan from './AttendanceOvertimeTimeSpan/AttendanceOvertimeTimeSpan.jsx';

import './AcceptOvertimeModal.scss';

const messages = defineMessages({
  confirmText: {
    id: 'attendance.acceptOvertimeModal.confirmText',
    defaultMessage: 'Zatwierdź',
  },
  title: {
    id: 'attendance.acceptOvertimeModal.title',
    defaultMessage: 'Zatwierdź nadgodziny',
  },
  toastrAddSuccess: {
    id: 'attendance.acceptOvertimeModal.toastr.addSuccess',
    defaultMessage: 'Pomyślnie zaakceptowano nadgodziny',
  },
  toastrAddError: {
    id: 'attendance.acceptOvertimeModal.toastr.addError',
    defaultMessage: 'Wystąpił błąd podczas akceptowania nadgodzin',
  },
});

class AcceptOvertimeModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    this.hideAndClear = this.hideAndClear.bind(this);
    this.getRenderedRows = this.getRenderedRows.bind(this);
    this.onAttendanceOvertimeAccept = this.onAttendanceOvertimeAccept.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getInitialState() {
    return {
      attendanceDataForEmployees: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalObject && prevProps.modalObject !== this.props.modalObject) {
      this.setState({
        attendanceDataForEmployees: this.props.modalObject.selectedEmployeesAttendanceOvertimeData,
      });
    }
  }

  onAttendanceOvertimeAccept(index, types) {
    const dataToChange = this.state.attendanceDataForEmployees[index];
    this.setState({
      attendanceDataForEmployees: [
        ...this.state.attendanceDataForEmployees.slice(0, index),
        {
          ...dataToChange,
          attendance: {
            ...dataToChange.attendance,
            ...types.reduce((acc, type) => ({ ...acc, [type]: !dataToChange.attendance[type] }), {}),
          },
        },
        ...this.state.attendanceDataForEmployees.slice(index + 1),
      ],
    });
  }

  onSubmit() {
    const mapToObject = this.state.attendanceDataForEmployees.map(d => ({
      attendance_id: d.attendance.id,
      early_in: d.attendance.early_in || false,
      late_out: d.attendance.late_out || false,
    }));
    const attendances = this.state.attendanceDataForEmployees.map(({ attendance }) => attendance);

    this.props
      .addAttendancesOvertime(mapToObject, attendances)
      .then(() => {
        toastr.success(this.context.intl.formatMessage(messages.toastrAddSuccess, {}));
        this.hideAndClear();
      })
      .catch(() => toastr.error(this.context.intl.formatMessage(messages.toastrAddError, {})));
    this.hideAndClear();
  }

  getRenderedRows() {
    return this.state.attendanceDataForEmployees.map((d, index) => {
      const earlyInClassNames = classnames('k-acceptOvertimeModal__checkedField', {
        'k-acceptOvertimeModal__checkedField--disabled': !d.hasEarlyIn,
      });
      const lateOutClassNames = classnames('k-acceptOvertimeModal__checkedField', {
        'k-acceptOvertimeModal__checkedField--disabled': !d.hasLateOut,
      });

      return (
        <tr key={d.employee.id + index}>
          <td className="k-acceptOvertimeModal__nameField">
            <div className="space">{d.employee.first_name}</div>
            <div>{d.employee.last_name}</div>
          </td>
          <td className="k-acceptOvertimeModal__hoursField">
            {d.shift ? <div className="space">{d.shift.working_hours.split('-').join(' - ')}</div> : null}
            {d.attendance.hours && (
              <div>
                <AttendanceOvertimeTimeSpan data={d} />
              </div>
            )}
          </td>
          {d.shift ? (
            <>
              <td
                role="gridcell"
                className={earlyInClassNames}
                onClick={() => this.onAttendanceOvertimeAccept(index, ['early_in'])}
              >
                {d.attendance.early_in ? <i className="material-icons">done</i> : null}
              </td>
              <td
                role="gridcell"
                className={lateOutClassNames}
                onClick={() => this.onAttendanceOvertimeAccept(index, ['late_out'])}
              >
                {d.attendance.late_out ? <i className="material-icons">done</i> : null}
              </td>
            </>
          ) : (
            <td
              role="gridcell"
              className={lateOutClassNames}
              onClick={() => this.onAttendanceOvertimeAccept(index, ['early_in', 'late_out'])}
              colSpan="2"
            >
              {d.attendance.late_out && d.attendance.early_in ? <i className="material-icons">done</i> : null}
            </td>
          )}
        </tr>
      );
    });
  }

  hideAndClear() {
    this.props.hideModal();
    this.setState(this.getInitialState());
  }

  render() {
    return (
      <KadroModal
        confirmText={this.context.intl.formatMessage(messages.confirmText, {})}
        showModal={this.props.show}
        title={this.context.intl.formatMessage(messages.title, {})}
        onHide={this.hideAndClear}
        onSubmit={this.onSubmit}
      >
        <div>
          <table className="k-acceptOvertimeModal">
            <tbody>{this.getRenderedRows()}</tbody>
          </table>
        </div>
      </KadroModal>
    );
  }
}

AcceptOvertimeModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AcceptOvertimeModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  addAttendancesOvertime: PropTypes.func,
  modalObject: PropTypes.shape({
    selectedEmployeesAttendanceOvertimeData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default AcceptOvertimeModal;
