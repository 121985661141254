import classnames from 'classnames';
import { memo, useEffect, useRef } from 'react';

import { Location } from '@/types';

import { ScheduleTableBody } from './ScheduleTableBody/ScheduleTableBody';
import ScheduleTableContentBlocker from './ScheduleTableContentBlocker/ScheduleTableContentBlocker.redux';
import { ScheduleTableHead } from './ScheduleTableHead/ScheduleTableHead';
import { useScheduleTable } from './useScheduleTable';

import './ScheduleTable.scss';

type Props = {
  locationId: Location['id'];
  height: number;
};

const ScheduleTable = memo(({ locationId, height }: Props) => {
  const tableRef = useRef(null);
  const { isMonthlySchedule, isSingleDay, shouldBeTableFocused } = useScheduleTable();

  useEffect(() => {
    if (shouldBeTableFocused) tableRef?.current?.focus();
  }, [shouldBeTableFocused]);

  const scheduleTableClassName = classnames('k-newScheduleTable', {
    'k-newScheduleTable--month': isMonthlySchedule,
  });

  const scheduleTableScrollClassName = classnames('k-newScheduleTable__scroll', {
    'k-newScheduleTable__scroll--hideScroll': isSingleDay,
  });

  return (
    <div className={scheduleTableClassName}>
      <div style={{ maxHeight: `${height}px` }} className={scheduleTableScrollClassName} tabIndex={0} ref={tableRef}>
        <div className="k-tableWrapper">
          <table>
            <ScheduleTableHead locationId={locationId} />
            <ScheduleTableBody locationId={locationId} />
          </table>
          <ScheduleTableContentBlocker locationId={locationId} />
        </div>
      </div>
    </div>
  );
});

ScheduleTable.displayName = 'ScheduleTable';

export default ScheduleTable;
