import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';
import ScheduleEventsTable from '@/components/scheduler/Event/ScheduleEventsTable.jsx';
import { selectUserEventsArray, showDeleteUserEventConfirmModal } from '@/redux-store/events';

const mapStateToProps = state => ({
  scheduleUIState: state.reducer.scheduleUIState,
  holidays: state.reducer.calendarData.holidays,
  dateStore: state.reducer.mainDateStore,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  userEvents: selectUserEventsArray(state),
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  showModal,
  deleteUserEvent: showDeleteUserEventConfirmModal,
};

const ScheduleEventsTableContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleEventsTable);

export default ScheduleEventsTableContainer;
