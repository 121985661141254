/* eslint-disable @typescript-eslint/no-explicit-any */
import { produce } from 'immer';

import * as actionTypes from '@/constants/ActionTypes';
import { EmployeeAttendance } from '@/types';

interface Action {
  type: string;
  payload?: any;
}

interface Draft {
  attendances: {
    [employeeId: string]: EmployeeAttendance[];
  };
}

interface State {
  attendances: {
    [employeeId: string]: EmployeeAttendance[];
  };
}

const initialState = {
  attendances: {},
};

const attendancesData = (state: State = initialState, action: Action) =>
  produce(state, (draft: Draft) => {
    switch (action.type) {
      case actionTypes.GET_ATTENDANCES_SUCCESFUL: {
        const { payload } = action;
        Object.keys(payload).forEach(employeeId => {
          if (
            !draft.attendances[employeeId] ||
            JSON.stringify(draft.attendances[employeeId]) !== JSON.stringify(payload[employeeId])
          ) {
            draft.attendances[employeeId] = payload[employeeId];
          } else {
            payload[employeeId].forEach(newAttendance => {
              const existingAttendanceIds = draft.attendances[employeeId].map(attendance => attendance.id);
              if (!existingAttendanceIds.includes(newAttendance.id)) {
                draft.attendances[employeeId].push(newAttendance);
              }
            });
          }
        });

        break;
      }
      case actionTypes.ADD_ATTENDANCE_SUCCESFUL:
      case actionTypes.START_ATTENDANCE_SUCCESFUL: {
        const { payload } = action;

        const { employee_id: employeeId, createdAttendance } = payload;
        const newAttendance = {
          ...createdAttendance,
          employee: { id: employeeId },
          location: { id: createdAttendance.location_id },
        };
        if (!draft.attendances[employeeId]) {
          draft.attendances[employeeId] = [newAttendance];
        } else {
          draft.attendances[employeeId].push(newAttendance);
        }
        break;
      }
      case actionTypes.DELETE_ATTENDANCE_SUCCESFUL: {
        const { payload } = action;
        const { employee_id: employeeId, attendance_id: attendanceId } = payload;

        if (draft.attendances[employeeId]) {
          const filteredAttendances = draft.attendances[employeeId].filter(
            attendance => attendance.id !== attendanceId,
          );

          draft.attendances[employeeId] = filteredAttendances;

          if (draft.attendances[employeeId].length === 0) {
            delete draft.attendances[employeeId];
          }
        }

        break;
      }
      case actionTypes.ADD_MASS_ATTENDANCE_SUCCESSFUL: {
        const { payload } = action;

        payload.forEach(attendance => {
          const { employee_id: employeeId } = attendance;
          const newAttendance = {
            ...attendance,
            location: { id: attendance.location_id },
            employee: { id: attendance.employee_id },
          };
          if (!draft.attendances[employeeId]) {
            draft.attendances[employeeId] = [newAttendance];
          } else {
            draft.attendances[employeeId].push(newAttendance);
          }
        });
        break;
      }
      case actionTypes.CHANGE_ATTENDANCE_SUCCESFUL:
      case actionTypes.END_ATTENDANCE_SUCCESFUL: {
        const { payload } = action;
        const { employee_id: employeeId, newAttendance } = payload;

        if (draft.attendances[employeeId]) {
          const index = draft.attendances[employeeId].findIndex(attendance => attendance.id === newAttendance.id);
          if (index !== -1) {
            draft.attendances[employeeId][index] = newAttendance;
          }
        }
        break;
      }
      case actionTypes.ADD_ATTENDANCES_OVERTIME_SUCCESS: {
        const { payload } = action;
        const { attendances, attendancesOvertime } = payload;

        attendances.forEach(att => {
          const { employee_id: employeeId } = att;
          if (!draft.attendances[employeeId]) {
            draft.attendances[employeeId] = [att];
          } else {
            attendancesOvertime.forEach(overtime => {
              const { attendance_id: attendanceId, early_in: earlyIn, late_out: lateOut } = overtime;
              const index = draft.attendances[employeeId].findIndex(a => a.id === attendanceId);
              if (index !== -1) {
                draft.attendances[employeeId][index] = {
                  ...draft.attendances[employeeId][index],
                  early_in: earlyIn,
                  late_out: lateOut,
                };
              }
            });
          }
        });
        break;
      }
      case actionTypes.CHANGE_MULTIPLE_ATTENDANCES_SUCCESFUL: {
        const { payload } = action;
        const { newAttendances } = payload;

        newAttendances.forEach(newAttendance => {
          const { employee_id: employeeId, id, location_id: locationId } = newAttendance;
          const defaultAttendanceProps = {
            employee: { id: employeeId },
            location: { id: locationId },
          };

          if (!draft.attendances[employeeId]) {
            draft.attendances[employeeId] = [{ ...defaultAttendanceProps, ...newAttendance }];
          } else {
            const index = draft.attendances[employeeId].findIndex(a => a.id === id);
            if (index !== -1) {
              draft.attendances[employeeId][index] = {
                ...draft.attendances[employeeId][index],
                ...defaultAttendanceProps,
                ...newAttendance,
              };
            } else {
              draft.attendances[employeeId].push({ ...defaultAttendanceProps, ...newAttendance });
            }
          }
        });

        break;
      }

      case actionTypes.START_BREAK_SUCCESFUL:
      case actionTypes.ADD_BREAK_SUCCESFUL: {
        const { payload } = action;
        const { attendance_id: attendanceId } = payload;

        const attendance = Object.values(draft.attendances)
          .flat()
          .find(attendance => attendance.id === attendanceId);

        if (attendance) {
          if (!attendance.breaks) {
            attendance.breaks = [];
          }
          attendance.breaks.push(payload);
        }
        break;
      }
      case actionTypes.CHANGE_BREAK_SUCCESFUL: {
        const { payload } = action;
        const { attendance_id: attendanceId } = payload;

        const attendance = Object.values(draft.attendances)
          .flat()
          .find(attendance => attendance.id === attendanceId);
        if (attendance && attendance.breaks) {
          const breakIndex = attendance.breaks.findIndex(b => b.id === payload.id);
          if (breakIndex !== -1) {
            attendance.breaks[breakIndex] = payload;
          }
        }
        break;
      }
      case actionTypes.END_BREAK_SUCCESFUL:
      case actionTypes.DELETE_BREAK_SUCCESFUL: {
        const { payload } = action;
        const { attendance_id: attendanceId } = payload;

        const attendance = Object.values(draft.attendances)
          .flat()
          .find(attendance => attendance.id === attendanceId);
        if (attendance && attendance.breaks) {
          attendance.breaks = attendance.breaks.filter(b => b.id !== payload.id);
        }
        break;
      }

      case actionTypes.UNASSIGN_LABEL_FROM_ATTENDANCE: {
        const { unassignLabelIds, employeeId, attendanceId } = action.payload;

        if (draft.attendances[employeeId]) {
          const attendanceIndex = draft.attendances[employeeId].findIndex(a => a.id === attendanceId);
          if (attendanceIndex !== -1) {
            const relevantAttendance = draft.attendances[employeeId][attendanceIndex];
            relevantAttendance.labels = relevantAttendance.labels.filter(label => !unassignLabelIds.includes(label.id));
          }
        }
        break;
      }
      case actionTypes.ASSIGN_LABEL_TO_ATTENDANCE: {
        const { relevantLabels, employeeId, attendanceId } = action.payload;

        if (draft.attendances[employeeId]) {
          const attendanceIndex = draft.attendances[employeeId].findIndex(a => a.id === attendanceId);
          if (attendanceIndex !== -1) {
            draft.attendances[employeeId][attendanceIndex].labels.push(...relevantLabels);
          }
        }
        break;
      }
      case actionTypes.UPDATE_LABEL: {
        const { payload } = action;
        const { id, label, color, type } = payload;

        Object.values(draft.attendances)
          .flat()
          .forEach(attendance => {
            if (attendance.labels) {
              const labelIndex = attendance.labels.findIndex(l => l.id === id);
              if (labelIndex !== -1) {
                attendance.labels[labelIndex] = { id, label, color, type };
              }
            }
          });
        break;
      }
      case actionTypes.DELETE_LABEL: {
        const { payload } = action;
        Object.values(draft.attendances)
          .flat()
          .forEach(attendance => {
            if (attendance.labels) {
              attendance.labels = attendance.labels.filter(label => !payload.includes(label.id));
            }
          });
        break;
      }

      case actionTypes.EDIT_SHIFT_FOR_CURRENT_USER_SUCCESS:
      case actionTypes.EDIT_SHIFT_SUCCESFUL: {
        const { employee_id: employeeId, newShiftObject } = action.payload;

        const attendances = draft.attendances[employeeId];
        if (attendances?.length) {
          const matchingShift = attendances.find(attendance => attendance.matching_shift.id === newShiftObject.id);
          if (matchingShift) {
            matchingShift.matching_shift = {
              ...matchingShift.matching_shift,
              ...newShiftObject,
            };
          }
        }
        break;
      }

      case actionTypes.ADD_ATTENDANCE_BONUS_SUCCESFUL: {
        const { employee_id: employeeId, attendance_id: attendanceId, bonus_amount: bonusAmount } = action.payload;

        const attendances = draft.attendances[employeeId];
        if (attendances?.length) {
          const targetAttendance = attendances.find(({ id }) => id === attendanceId);
          if (targetAttendance) {
            targetAttendance.bonus_amount = bonusAmount;
          }
        }
        break;
      }

      case actionTypes.CALCULATE_SPMH_BASED_BONUSES_SUCCESS: {
        const { payload } = action;

        Object.values(draft.attendances).forEach(attendances => {
          attendances.forEach(attendance => {
            const updatedAttendance = payload.find(p => p.id === attendance.id);
            if (updatedAttendance) {
              attendance.bonus_amount = updatedAttendance.bonus_amount;
            }
          });
        });
        break;
      }

      case actionTypes.DELETE_RELEASED_EMPLOYEES_ATTENDANCES_SUCCESS: {
        return produce(state, draft => {
          const { employeeIds } = action.payload;

          Object.keys(draft.attendances).forEach(employeeId => {
            if (employeeIds.includes(employeeId)) {
              draft.attendances[employeeId] = [];
            }
          });
        });
      }
      case actionTypes.GET_ATTENDANCES_SUCCESSFUL_FOR_EMPLOYEE: {
        const { data, employeeId } = action.payload;
        return { ...state, [employeeId]: data };
      }
      default:
        return state;
    }
  });

export default attendancesData;
