import { memo } from 'react';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import TopBarSchedule from '@/components/TopBars/TopBarSchedule/TopBarSchedule.redux.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';
import { DisplayModeEnum } from '@/redux-store/scheduleUIState';

import AvailabilitySendPanel from '../Availability/AvailabilitySendPanel/AvailabilitySendPanel.redux';
import ScheduleBudgetTable from '../budgetBeta/ScheduleBudgetTable/ScheduleBudgetTable.redux.js';
import EmptyStateScheduleView from '../EmptyStates/EmptyStateScheduleView/EmptyStateScheduleView.redux.js';
import ScheduleSendPanel from '../ScheduleSendPanel/ScheduleSendPanel.redux.js';
import { EventsTable } from '../ScheduleTable/EventsTable/EventsTable';
import ScheduleButtonBar from '../ScheduleTable/ScheduleButtonBar/ScheduleButtonBar.redux.js';
import { ScheduleShortcutManager } from '../ScheduleTable/ScheduleShortcutManager/ScheduleShortcutManager';
import ScheduleTable from '../ScheduleTable/ScheduleTable';
import { ScheduleModals } from './modals/ScheduleModals';
import { useScheduleModeView } from './useScheduleModeView';

export const ScheduleBetaModeView = memo(() => {
  const {
    areLocationsNotSelected,
    displayMode,
    employeesPerLocation,
    height,
    noEmployeesAreVisible,
    scheduleLocationFilter,
    showBudgetTable,
    showEventsTable,
  } = useScheduleModeView();

  return (
    <div>
      {displayMode === DisplayModeEnum.AVAILABILITIES ? <AvailabilitySendPanel /> : <ScheduleSendPanel />}
      <TopBarSchedule />
      <div className="k-wrapper k-wrapper--tablePadding animated fadeInRight">
        <ScheduleModals />
        <ScheduleButtonBar />
        <ScheduleShortcutManager />
        {areLocationsNotSelected || noEmployeesAreVisible ? (
          <EmptyStateScheduleView />
        ) : (
          employeesPerLocation.map(location => (
            <ScheduleTable key={location.locationId} height={height} locationId={location.locationId} />
          ))
        )}
        {showBudgetTable && (
          <FeatureWrapper restriction={BUDGET_INFO_HIDE} key="budget-table">
            <ScheduleBudgetTable relevantLocations={scheduleLocationFilter} />
          </FeatureWrapper>
        )}
        {showEventsTable && <EventsTable />}
      </div>
    </div>
  );
});

ScheduleBetaModeView.displayName = 'ScheduleBetaModeView';
