import { connect } from 'react-redux';

import { assignUsersOpenShift } from '@/actions/openShifts.js';
import { closeAssignUsersOpenShiftModal } from '@/actions/uiState.js';
import AssignUsersOpenShiftModal from '@/components/scheduler/modals/AssignUsersOpenShiftModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showAssignUsersOpenShiftModal,
  modalObject: state.reducer.uiState.assignUsersOpenShiftModalObject,
  userEmployees: state.reducer.userEmployees,
  userLocations: state.reducer.userLocations,
  contracts: state.reducer.contracts,
  userJobTitles: state.reducer.userJobTitles,
  permissions: state.reducer.userPermissions.permissions,
  scheduleLoanedEmployees: state.reducer.scheduleLoanedEmployees.scheduleLoanedEmployees,
});

const mapDispatchToProps = {
  onHide: closeAssignUsersOpenShiftModal,
  assignUsersOpenShift,
};
const AssignUsersOpenShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(AssignUsersOpenShiftModal);

export default AssignUsersOpenShiftModalContainer;
