import moment from 'moment';

import * as actionTypes from '@/constants/ActionTypes.js';
import { OVERTIME_COLLECTION_HIDE } from '@/constants/Restrictions.js';
import { uuid4 } from '@/utils/baseHelpers.js';
import { combineLegacyAndNewOvertimeCollections } from '@/utils/overtimeCollectionsHelpers';

import { conn, showConfirmModal } from '../index';
import { messages } from './overtimeCollections.messages';

const addOvertimeCollectionFailure = (employeeId, temporaryId) => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_OVERTIME_COLLECTION_FAILURE,
    employeeId,
    temporaryId,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.error),
      description: intl.formatMessage(messages.addOvertimeCollectionError),
    },
  });
};

const addOldOvertimeCollectionSuccess = (temporaryId, newAbsence) => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_OLD_OVERTIME_COLLECTION_SUCCESS,
    id: temporaryId,
    newAbsence,
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.success),
      description: intl.formatMessage(messages.addOvertimeCollectionSuccess),
    },
  });
};

const addOldOvertimeCollectionFailure = (employeeId, temporaryId) => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_OLD_OVERTIME_COLLECTION_FAILURE,
    employeeId,
    temporaryId,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.error),
      description: intl.formatMessage(messages.addOvertimeCollectionError),
    },
  });
};

const addOvertimeCollectionSuccess = (temporaryId, newAbsence) => (dispatch, getState, intl) => {
  const { employees, currentUser } = getState().reducer;
  const employeeFullName = `${employees.data[newAbsence.employee_id].first_name} ${
    employees.data[newAbsence.employee_id].last_name
  }`;
  const changeByFullName = `${currentUser.user.first_name} ${currentUser.user.last_name}`;
  const modifiedData = {
    ...newAbsence,
    recent_status_change_by_full_name: changeByFullName,
    employee_full_name: employeeFullName,
  };
  dispatch({
    type: actionTypes.ADD_OVERTIME_COLLECTION_SUCCESS,
    id: temporaryId,
    newAbsence: modifiedData,
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.success),
      description: intl.formatMessage(messages.addOvertimeCollectionSuccess),
    },
  });
};

const addInitOvertimeCollection = absence => ({
  type: actionTypes.ADD_INIT_OVERTIME_COLLECTION,
  payload: absence,
});

const addInitOldOvertimeCollection = absence => ({
  type: actionTypes.ADD_INIT_OLD_OVERTIME_COLLECTION,
  payload: absence,
});

export const addOvertimeCollection = absence => async dispatch => {
  const uid = uuid4();
  absence.id = uid;

  try {
    dispatch(addInitOvertimeCollection(absence));
    const { data } = await conn.addOvertimeCollection(absence);
    dispatch(addOvertimeCollectionSuccess(uid, data));
  } catch (error) {
    dispatch(addOvertimeCollectionFailure(absence.employee_id, uid));
  }
};

export const addOldOvertimeCollection = absence => async dispatch => {
  const uid = uuid4();
  absence.id = uid;

  try {
    dispatch(addInitOldOvertimeCollection(absence));
    const { data } = await conn.addOldOvertimeCollection(absence);
    dispatch(addOldOvertimeCollectionSuccess(uid, data[0]));
  } catch (error) {
    dispatch(addOldOvertimeCollectionFailure(absence.employee_id, uid));
  }
};

export const editOldOvertimeCollection = absence => async (dispatch, getState, intl) => {
  try {
    const { data } = await conn.editOvertimeCollection(absence);
    dispatch({
      type: actionTypes.EDIT_OVERTIME_COLLECTION_SUCCESS,
      newAbsence: data,
      notification: {
        type: 'success',
        title: intl.formatMessage(messages.success),
        description: intl.formatMessage(messages.editOvertimeCollectionSuccess),
      },
    });
  } catch (error) {
    dispatch({
      type: actionTypes.EDIT_OVERTIME_COLLECTION_FAILURE,
      notification: {
        type: 'error',
        title: intl.formatMessage(messages.error),
        description: intl.formatMessage(messages.editOvertimeCollectionError),
      },
    });
  }
};

export const getOvertimeCollections = (from, to, employees, requestType) => async (dispatch, getState, intl) => {
  const { userPermissions, userEmployees, scheduleLocationFilter } = getState().reducer;
  const { restrictions } = userPermissions;
  if (restrictions.includes(OVERTIME_COLLECTION_HIDE)) {
    return;
  }

  const employeeIds =
    employees ||
    userEmployees
      .filter(employee => {
        if (employee.inactive) {
          return false;
        }
        const employeeLocationIds = employee.locations.map(location => location.id);
        return scheduleLocationFilter.some(locationId => employeeLocationIds.includes(locationId));
      })
      .map(employee => employee.id);
  if (!employeeIds?.length) {
    return;
  }
  await Promise.all([
    conn.getOvertimeCollections(from, to, employeeIds, requestType),
    conn.getNewOvertimeCollections(from, to, employeeIds, requestType),
  ])
    .then(([{ data }, { data: newOvertimeCollections }]) => {
      const combinedOvertimeCollections = combineLegacyAndNewOvertimeCollections(
        data.absences,
        newOvertimeCollections.overtime_collections,
      );
      dispatch({
        type: actionTypes.GET_OVERTIME_COLLECTIONS,
        payload: combinedOvertimeCollections,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_OVERTIME_COLLECTIONS_FAILURE,
        notification: {
          type: 'error',
          title: intl.formatMessage(messages.error),
          description: intl.formatMessage(messages.getOvertimeCollectionsFailure),
        },
      });
      console.error(error);
    });
};

export const showDeleteOvertimeCollectionConfirmModal = overtimeCollectionId => (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteOvertimeCollectionTitle),
      description: intl.formatMessage(messages.deleteOvertimeCollectionDescription),
      confirmText: intl.formatMessage(messages.delete, {}),
      confirmFunc: () => {
        dispatch(deleteOvertimeCollections([overtimeCollectionId]));
      },
    }),
  );
};

export const deleteOvertimeCollections = ids => async (dispatch, getState, intl) => {
  try {
    await conn.deleteOvertimeCollections(ids);

    dispatch({
      type: actionTypes.DELETE_OVERTIME_COLLECTIONS_SUCCESS,
      payload: ids,
      notification: {
        type: 'success',
        title: intl.formatMessage(messages.success),
        description: intl.formatMessage(messages.deleteOvertimeCollectionSuccess),
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: actionTypes.DELETE_OVERTIME_COLLECTIONS_FAILURE,
      notification: {
        type: 'error',
        title: intl.formatMessage(messages.error),
        description: intl.formatMessage(messages.deleteOvertimeCollectionError),
      },
    });
  }
};

export const publishOvertimeCollectionsSuccessful = (from, to, employeeIds) => (dispatch, getState) => {
  const { overtimeCollections } = getState().reducer;

  const publishedIds = Object.keys(overtimeCollections).reduce((result, employeeId) => {
    if (employeeIds.includes(employeeId)) {
      const absenceIds = overtimeCollections[employeeId]
        .filter(absence => moment(absence.start_timestamp).isBetween(from, to))
        .map(absence => absence.id);

      return [...result, ...absenceIds];
    }

    return result;
  }, []);

  dispatch({
    type: actionTypes.PUBLISH_OVERTIME_COLLECTIONS_SUCCESSFUL,
    absenceIds: publishedIds,
  });
};

export const deleteOvertimeCollectionsSuccess = ids => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_OVERTIME_COLLECTIONS_SUCCESS,
    payload: ids,
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.success),
      description: intl.formatMessage(messages.deleteOvertimeCollectionSuccess),
    },
  });
};

export const deleteOvertimeCollectionsFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_OVERTIME_COLLECTIONS_FAILURE,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.error),
      description: intl.formatMessage(messages.deleteOvertimeCollectionError),
    },
  });
};

export const deleteNewOvertimeCollections = ids => async (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteOvertimeCollectionsConfirmModalTitle),
      description: intl.formatMessage(messages.deleteOvertimeCollectionsConfirmModalDescription),
      confirmText: intl.formatMessage(messages.deleteOvertimeCollectionsConfirmModalText),
      showDeleteInput: true,
      confirmFunc: async () => {
        try {
          await conn.deleteNewOvertimeCollections(ids);
          dispatch(deleteOvertimeCollectionsSuccess(ids));
        } catch (error) {
          dispatch(deleteOvertimeCollectionsFailure());
          console.error(error);
        }
      },
    }),
  );
};
