import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fullTime: {
    id: 'employemtnConditions.partTimeMultiplier.fullTime',
    defaultMessage: 'Pełny wymiar',
  },
  threeQuarters: {
    id: 'employemtnConditions.partTimeMultiplier.threeQuarters',
    defaultMessage: '3/4 etatu',
  },
  halfTime: {
    id: 'employemtnConditions.partTimeMultiplier.halfTime',
    defaultMessage: '1/2 etatu',
  },
  oneQuarter: {
    id: 'employemtnConditions.partTimeMultiplier.oneQuarter',
    defaultMessage: '1/4 etatu',
  },
});

export const PODST_EMPLOYMENT_CONDITIONS_ID = 'podst';
export const ROWN_EMPLOYMENT_CONDITIONS_ID = 'rown';

export const employmentConditions = [
  {
    id: ROWN_EMPLOYMENT_CONDITIONS_ID,
    name: 'Równoważny',
    weekly_working_minutes: 40 * 60,
    max_daily_working_minutes: 12 * 60,
    validate_working_rules: true,
    schedule_cycle: null,
    availability_limits: [],
    show_absences: true,
    elastic_work_day: false,
    is_medical: false,
    allowed_to_work_at_night_hours: true,
  },
  {
    id: PODST_EMPLOYMENT_CONDITIONS_ID,
    name: 'Podstawowy',
    weekly_working_minutes: 40 * 60,
    max_daily_working_minutes: 8 * 60,
    validate_working_rules: true,
    schedule_cycle: null,
    availability_limits: [],
    show_absences: true,
    elastic_work_day: false,
    is_medical: false,
    allowed_to_work_at_night_hours: true,
  },

  {
    id: 'uoz',
    name: 'Umowa zlecenie',
    weekly_working_minutes: 0,
    max_daily_working_minutes: 0,
    validate_working_rules: false,
    schedule_cycle: 0,
    availability_limits: [],
    show_absences: false,
    elastic_work_day: false,
    is_medical: false,
    allowed_to_work_at_night_hours: false,
  },
];

export const employmentConditionsIds = employmentConditions.map(condition => condition.id);

export const employmentConditionsFilterOptions = [
  {
    id: 'none',
    name: 'Brak warunku',
  },
  {
    id: 'custom',
    name: 'Własny',
  },
];

export const weeklyWorkingHours = [
  {
    name: messages.fullTime,
    value: 2400,
  },
  {
    name: messages.threeQuarters,
    value: 1800,
  },
  {
    name: messages.halfTime,
    value: 1200,
  },
  {
    name: messages.oneQuarter,
    value: 600,
  },
];

export const defaultScheduleCycle = {
  month: 1,
  year: new Date().getFullYear(),
  duration: 1,
};
