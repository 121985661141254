import { Location } from '@/types';
import { HoursRange, StandardDate } from '@/types/dates.types';

export enum UserEventTypeEnum {
  IMPORTANT = 'important',
  NOTE = 'note',
}

export type UserEvent = {
  id: string;
  type: UserEventTypeEnum;
  date: StandardDate;
  description: string;
  location: {
    id: Location['id'];
  };
  hours: HoursRange;
};

export type UserEventsStoreState = {
  data: Record<UserEvent['id'], UserEvent>;
  structure: Record<StandardDate, Record<Location['id'], UserEvent['id'][]>>;
  lastFetchedData: {
    from?: StandardDate;
    to?: StandardDate;
    locationsIds?: Location['id'][];
  };
};
