import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { getRelevantContractForDate } from '@/utils/contracts';
import { calculateDurationMinutes } from '@/utils/dateHelper.js';
import { filterShiftEagerUsers } from '@/utils/schedulerHelpers';

import OpenShiftModalDetails from '../openShift/OpenShiftModalDetails.jsx';

import 'overlayscrollbars/styles/overlayscrollbars.css';

const modalMessages = defineMessages({
  title: {
    id: 'openShift.assignModal.title',
    defaultMessage: 'Potwierdź pracowników',
  },
  confirmText: {
    id: 'openShift.assignModal.confirmText',
    defaultMessage: 'Potwierdź',
  },
});

const errorMessages = defineMessages({
  errorChosenEmployee: {
    id: 'openShift.assignModal.errorChosenEmployee',
    defaultMessage: 'Musisz wybrać przynajmniej jednego pracownika',
  },
  tooManySelectedEmployees: {
    id: 'openShift.assignModal.tooManySelectedEmployees',
    defaultMessage: 'Możesz wybrać maksymalnie tylu pracowników ile jest dostępnych zmian',
  },
  employeeIsBusy: {
    id: 'openShift.error.addShift.busy',
    defaultMessage: 'Pracownik {name} ma już zaplanowaną zmianę w tym czasie',
  },
});

class AssignUsersOpenShiftModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      usersIds: [],
      errors: [],
    };
    bindPrototypeFunctions(this);
  }

  onSubmit() {
    const { intl } = this.context;
    const { modalObject, userEmployees } = this.props;
    this.setState({
      errors: [],
    });

    const newErrors = [];
    if (this.state.usersIds.length === 0) {
      newErrors.push(intl.formatMessage(errorMessages.errorChosenEmployee));
    }

    if (this.state.usersIds.length > modalObject.openShift.shifts_remaining) {
      newErrors.push(intl.formatMessage(errorMessages.tooManySelectedEmployees));
    }

    if (newErrors.length > 0) {
      this.setState({ errors: newErrors });
      return;
    }

    this.props
      .assignUsersOpenShift(modalObject.openShift, this.state.usersIds)
      .then(() => {
        this.onHide();
      })
      .catch(errors => {
        const messages = errors.map(err => {
          const employee = userEmployees.find(empl => empl.id === err.employeeId);
          return intl.formatMessage(errorMessages[err.errorCode], {
            name: `${employee.first_name} ${employee.last_name}`,
          });
        });

        this.setState({ errors: messages });
      });
  }

  onHide() {
    this.setState({
      usersIds: [],
      errors: [],
    });
    this.props.onHide();
  }

  getCost(user) {
    const hourlyWage = this.getHourlyWage(user);
    const workingHours = this.props.modalObject.openShift.working_hours;
    const shiftDuration = calculateDurationMinutes(workingHours) / 60;
    const cost = Math.round((hourlyWage * shiftDuration) / 100);
    return cost;
  }

  getHourlyWage(user) {
    const {
      contracts,
      modalObject: { openShift },
      userJobTitles,
      scheduleLoanedEmployees,
    } = this.props;

    const jobTitleId = openShift.job_title.id;
    const employeeContracts = contracts[user.id] || scheduleLoanedEmployees[user.id]?.contracts;
    const relevantContract = employeeContracts
      ? getRelevantContractForDate(employeeContracts, openShift.date)
      : openShift.terms;
    const contractJobTitle = relevantContract?.job_titles.find(jobTitle => jobTitle.job_title_id === jobTitleId);
    const hasIndividualHourlyWage = contractJobTitle?.wage || contractJobTitle?.wage === 0;
    if (hasIndividualHourlyWage) {
      return contractJobTitle.wage;
    }

    return userJobTitles.find(j => j.id === jobTitleId)?.hourly_wage || 0;
  }

  getLocationName() {
    const selectedLocationId = this.props.modalObject.openShift.location.id;
    const location = this.props.userLocations.find(loc => loc.id === selectedLocationId);
    return location.name;
  }

  assignUsersOpenShift(userId) {
    const isUserSelected = this.isUserSelected(userId);
    this.setState(prevState => ({
      usersIds: isUserSelected ? prevState.usersIds.filter(id => id !== userId) : [...prevState.usersIds, userId],
    }));
  }

  isUserSelected(userId) {
    return this.state.usersIds.includes(userId);
  }

  render() {
    const { contracts, modalObject, permissions } = this.props;
    const { openShift } = modalObject;
    const isOpenShiftUsersNotEmpty = openShift.users && openShift.users.length;
    const confirmBtnModifiers = isOpenShiftUsersNotEmpty ? ['orange'] : ['orange', 'disabled'];
    if (!openShift.id) return null;
    const users = this.props.userEmployees.filter(e =>
      openShift.users.some(u => {
        if (u.id) {
          return e.id.toString() === u.id.toString();
        }
        return e.id.toString() === u.toString();
      }),
    );
    const filteredEagerUsers =
      openShift?.detailedEagerUsers ||
      filterShiftEagerUsers(
        users,
        openShift.location.id,
        openShift.job_title.id,
        openShift.date,
        contracts,
        permissions,
      );
    const errorMessage = this.state.errors.join(', ');
    return (
      <MDKadroModal
        show={this.props.showModal}
        title={this.context.intl.formatMessage(modalMessages.title, {})}
        confirmText={this.context.intl.formatMessage(modalMessages.confirmText, {})}
        onSubmit={this.onSubmit}
        onHide={this.onHide}
        modifiers={['narrow']}
        errorMessage={errorMessage}
        confirmBtnModifiers={confirmBtnModifiers}
      >
        <OpenShiftModalDetails openShift={{ ...openShift, locationName: this.getLocationName() }} />
        {isOpenShiftUsersNotEmpty && filteredEagerUsers.length > 0 ? (
          <>
            <div className="k-open-shifts__containerHeader">
              <div className="k-open-shifts__containerHeader--employee">
                <FormattedMessage id="openShift.assignModal.employee" defaultMessage="Pracownik" />
              </div>
              <div className="k-open-shifts__containerHeader--cost">
                <FormattedMessage id="openShift.assignModal.cost" defaultMessage="Koszt" />
              </div>
            </div>
            <div className="k-open-shifts__container">
              <OverlayScrollbarsComponent className="k-overlay-scrollbar">
                {filteredEagerUsers.map(user => (
                  <div className="k-open-shifts__item" key={user.id}>
                    <div className="k-open-shifts__item--employee">
                      <MDCheckbox
                        value={this.isUserSelected(user.id)}
                        text={`${user.first_name} ${user.last_name}`}
                        onChange={() => this.assignUsersOpenShift(user.id)}
                        reverse
                      />
                    </div>
                    <div className="k-open-shifts__item--cost">
                      {this.getCost(user)} <FormattedMessage id="currencyCode" defaultMessage="PLN" />
                    </div>
                  </div>
                ))}
              </OverlayScrollbarsComponent>
            </div>
          </>
        ) : (
          <span className="k-open-shifts__noemployee">
            <FormattedMessage
              id="openShift.assignModal.assignUsersEmptyList"
              defaultMessage="Brak zapisanych pracowników"
            />
          </span>
        )}
      </MDKadroModal>
    );
  }
}

AssignUsersOpenShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AssignUsersOpenShiftModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    openShift: PropTypes.shape({
      users: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
      shifts_remaining: PropTypes.number,
      working_hours: PropTypes.string,
      id: PropTypes.string,
      location: PropTypes.shape({
        id: PropTypes.string,
      }),
      job_title: PropTypes.shape({
        id: PropTypes.string,
      }),
      detailedEagerUsers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  onHide: PropTypes.func,
  assignUsersOpenShift: PropTypes.func,
  userLocations: PropTypes.arrayOf(PropTypes.shape()),
};

export default AssignUsersOpenShiftModal;
