import { connect } from 'react-redux';

import EmployeeDashboard from '@/components/employee/dashboard/EmployeeDashboard.jsx';
import { selectUserEventsArray } from '@/redux-store/events';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  userEvents: selectUserEventsArray(state),
  userLocations: state.reducer.userLocations,
  mainDateStore: state.reducer.mainDateStore,
  locationSettings: state.reducer.settings.locationSettings,
  attendances: state.reducer.attendances.attendancesData,
});

const mapDispatchToProps = {};

const EmployeeDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeDashboard);

export default EmployeeDashboardContainer;
