import moment from 'moment-timezone';
import { defineMessages } from 'react-intl';

import { addObjWithRepeat, messages as shiftsMessages } from '@/actions';
import { LIGHTER_GRAY } from '@/constants/colors';
import { isEmployeeInactive } from '@/utils/userEmployeesHelpers';

import { getRelevantContractForDate } from './contracts';
import { calculateDurationBetweenTimestamps, calculateDurationMinutes, getRelevantDateRange } from './dateHelper';
import { createRepeatObject } from './repeatShiftHelpers';
import { checkShiftOverlap } from './schedulerHelpers';

const messages = defineMessages({
  editShiftFailureHourLimitExceed: {
    id: 'editShiftFailure.hourLimitExceed',
    defaultMessage: 'Nie można edytować zmiany. Przekracza ona czasowy limit targetów budżetu',
  },
  editShiftFailureMoneyLimitExceed: {
    id: 'editShiftFailure.moneyLimitExceed',
    defaultMessage: 'Nie można edytować zmiany. Przekracza ona kosztowy limit targetów budżetu',
  },
  addShiftFailureHourLimitExceed: {
    id: 'addShiftFailure.hourLimitExceed',
    defaultMessage: 'Nie można dodać zmiany. Przekracza ona czasowy limit targetów budżetu',
  },
  addShiftFailureMoneyLimitExceed: {
    id: 'addShiftFailure.moneyLimitExceed',
    defaultMessage: 'Nie można dodać zmiany. Przekracza ona kosztowy limit targetów budżetu',
  },
  addShiftErrorBusy: { id: 'error.addShift.busy', defaultMessage: 'Pracownik ma w tym czasie już inną zmiane' },
  addShiftErrorDefault: { id: 'error.addShift.default', defaultMessage: 'Nie udało się dodać zmiany!' },
  shiftsOverlappingWithBlockades: {
    id: 'error.addShift.shiftsOverlappingWithBlockades',
    defaultMessage: 'Pracownik ma zaplanowaną zmianę na innym profilu przez cały lub część wyznaczonego czasu',
  },
  shiftsOverlappingWithBlockadesTitle: {
    id: 'error.addShift.shiftsOverlappingWithBlockadesTitle',
    defaultMessage: 'Zmiany się pokrywają.',
  },
});

// TODO: HARDCODED CET timezone
export const timezone = 'CET';

export const editShiftModalOnSubmit = (props, shift) => {
  const { modalObject, editShift } = props;
  const shiftToAdd = { ...modalObject.shift, ...shift, date: modalObject.shift.date, draft: true };
  editShift(modalObject.employee, shiftToAdd);
};

export const addShiftModalOnSubmit = async (state, props, shift) => {
  const { modalObject, addShift, addShiftsWithRepeat } = props;
  if (state.displayRepeat) {
    const repeatObj = createRepeatObject(state);
    const error = addShiftsWithRepeat(modalObject.employee, shift, repeatObj);
    return error;
  }
  if (modalObject.customAddShift) {
    await modalObject.customAddShift(shift, state.addAttendanceForShift);
  } else {
    await addShift(modalObject.employee, shift, true);
  }
  return null;
};

export const getWorkingDaysShifts = (shifts, holidays) => {
  const freeFromWork = holidays.reduce((result, holiday) => {
    if (holiday.freeFromWork) {
      return [...result, moment(holiday.date, 'YYYY-MM-DD')];
    }

    return result;
  }, []);

  return shifts.filter(shift => !freeFromWork.some(freeDay => moment(shift.date).isSame(freeDay, 'day')));
};

export const generateTimestamps = (workingHours, date) => {
  const startHour = workingHours.slice(0, 5);
  const endHour = workingHours.slice(6, 11);
  const startTimestamp = moment.tz(`${date} ${startHour}`, timezone).format();
  const endTimestamp =
    startHour > endHour
      ? moment.tz(`${date} ${endHour}`, timezone).add(1, 'days').format()
      : moment.tz(`${date} ${endHour}`, timezone).format();

  return { startTimestamp, endTimestamp };
};

export const getNumberOfExistingShifts = (shift, existingShifts) => {
  const { working_hours: workingHours, date } = shift;
  const jobTitleId = shift.job_title_id || shift.job_title?.id;

  return existingShifts.filter(
    existingShift =>
      existingShift.workingHours === workingHours &&
      jobTitleId === existingShift.jobTitleId &&
      existingShift.date === date,
  ).length;
};

export const findJobTitleForShift = (shift, userJobTitles) => {
  const shiftJobTitleId = shift.job_title.id;
  const matchedJobTitle = userJobTitles.find(jobTitle => String(jobTitle.id) === String(shiftJobTitleId));
  return matchedJobTitle;
};

export const getScheduleShiftTitle = (shift, userJobTitles) => {
  const shiftJobTitleName = shift.job_title.name;
  if (shiftJobTitleName) return shiftJobTitleName;
  const shiftJobTitleId = shift.job_title.id;
  const matchedJobTitle = userJobTitles.find(jobTitle => jobTitle.id === shiftJobTitleId);
  return matchedJobTitle ? matchedJobTitle.title : '';
};

const getRelevantAbsences = (absences, dateRange, employeeIds) =>
  Object.values(absences).flatMap(absenceValues =>
    absenceValues.filter(
      absence =>
        absence.draft &&
        dateRange.includes(moment(absence.start_timestamp).format('YYYY-MM-DD')) &&
        employeeIds.includes(absence.employee_id),
    ),
  );

const getDraftShiftsCountForLocation = (locationId, employees, dateRange, openShifts, absences, selectedJobTitles) => {
  const employeesDraftsCount = employees
    .map(e => e.shifts.filter(s => dateRange.includes(s.date) && s.location.id === locationId && s.draft).length)
    .reduce((a, b) => a + b, 0);

  const selectedJobTitlesIds = selectedJobTitles.map(({ id }) => id);

  const openShiftsCount = openShifts.filter(
    shift =>
      shift.draft &&
      dateRange.includes(shift.date) &&
      shift.location.id === locationId &&
      selectedJobTitlesIds.includes(shift.job_title.id),
  ).length;

  const absencesCount = absences.length;
  return employeesDraftsCount + openShiftsCount + absencesCount;
};

export const getDraftShiftsCount = (
  locationsIds,
  locationSettings,
  userEmployees,
  mainDateStore,
  absences,
  openShifts,
  selectedJobTitles,
) =>
  locationsIds.reduce((acc, cur) => {
    const selectedLocationSettings = locationSettings[cur];
    const disabledUntilDate = selectedLocationSettings
      ? selectedLocationSettings.disable_location_schedule_shifts_edit_until
      : '';
    const relevantEmployees = userEmployees.filter(e => !e.inactive && e.locations.map(l => l.id).includes(cur));
    const relevantDateRange = getRelevantDateRange(mainDateStore.dateArray, disabledUntilDate);
    const relevantEmployeeIds = relevantEmployees.map(employee => employee.id);
    const relevantAbsences = getRelevantAbsences(absences, relevantDateRange, relevantEmployeeIds);
    const draftsCount = getDraftShiftsCountForLocation(
      cur,
      relevantEmployees,
      relevantDateRange,
      openShifts,
      relevantAbsences,
      selectedJobTitles,
    );

    return acc + draftsCount;
  }, 0);

export const getEmployeeIdsForLocation = (userEmployees, locationId, from, to, overtimeCollections) =>
  userEmployees
    .filter(
      employee =>
        employee.locations.some(l => l.id === locationId) &&
        (employee.shifts.some(s => s.date >= from && s.date <= to && s.draft && s.location.id === locationId) ||
          overtimeCollections[employee.id]?.some(
            absence => moment(absence.start_timestamp).isBetween(from, to) && absence.draft,
          )),
    )
    .map(e => e.id);

const popoverMessages = defineMessages({
  invalidJobTitle: {
    id: 'employeeSchedule.shiftBlock.invalidJobTitle',
    defaultMessage: 'Pracownik ma utworzoną zmianę na stanowisku {jobTitle} do którego nie został przypisany',
  },
  missingJobTitle: {
    id: 'employeeSchedule.shiftBlock.missingJobTitle',
    defaultMessage: 'Pracownik ma utworzoną zmianę na stanowisku, które zostało usunięte',
  },
  otherLocation: {
    id: 'employeeSchedule.shiftBlock.otherLocation',
    defaultMessage:
      'Ten pracownik ma zmianę w lokalizacji {locationName} {isOpenShiftFromLocationGroups, select, true {(w ramach grupowej otwartej zmiany)} other {}} {isLoaned, select, true {(w ramach udostępnienia)} other {}}',
  },
  otherUnknownLocation: {
    id: 'employeeSchedule.shiftBlock.otherUnknownLocation',
    defaultMessage: 'Ten pracownik ma zmiane w innej lokalizacji',
  },
  validComment: {
    id: 'employeeSchedule.shiftBlock.validComment',
    defaultMessage: 'Komentarz do zmiany: {comment}',
  },
  locationUndefined: {
    id: 'employeeSchedule.shiftBlock.locationUndefined',
    defaultMessage: 'nie przypisanej do niego',
  },
  locationRemoved: {
    id: 'employeeSchedule.shiftBlock.locationRemoved',
    defaultMessage: 'która została usunięta',
  },
});

export const getShiftPopover = (
  employeeName,
  date,
  comment,
  jobTitle,
  isJobTitleNotAssigned,
  isFromOtherLocation,
  shiftLocationName,
  intl,
  isLoaned,
  shouldShowNote = true,
  isOpenShiftFromLocationGroups = false,
) => {
  const isValidComment = comment && comment.length > 0;

  const content = [];

  if (isFromOtherLocation) {
    if (shiftLocationName) {
      content.push(
        intl.formatMessage(popoverMessages.otherLocation, {
          locationName: shiftLocationName,
          isLoaned,
          isOpenShiftFromLocationGroups,
        }),
      );
    } else {
      content.push(intl.formatMessage(popoverMessages.otherUnknownLocation));
    }
  }

  if (!jobTitle) {
    content.push(intl.formatMessage(popoverMessages.missingJobTitle));
  } else if (isJobTitleNotAssigned) {
    content.push(intl.formatMessage(popoverMessages.invalidJobTitle, { jobTitle: jobTitle.title }));
  }
  if (isValidComment && shouldShowNote) {
    content.push(intl.formatMessage(popoverMessages.validComment, { comment }));
  }

  const popoverTitle = `${employeeName} ${date}`;
  const popover = content.length > 0 && { title: popoverTitle, content };

  return popover;
};

export const checkIfEmployeeHasJobTitle = (jobTitle, employeeRelevantContract) =>
  employeeRelevantContract.job_titles.some(
    contractJobTitle => contractJobTitle.job_title_id === jobTitle.id.toString(),
  );

export const getPositionAndLengthForBlock = (start, end, date) => {
  const position = `${(start.split(':')[0] * 60 + 1 * start.split(':')[1]) / 14.4}%`;
  const startTimestamp = `${date} ${start}`;
  const endTimestamp = end < start ? `${date} 23:59` : `${date} ${end}`;
  const length = calculateDurationBetweenTimestamps(endTimestamp, startTimestamp) / 14.4;
  const minified = length < 7;
  return { position, length, minified };
};

export const shiftErrorCodesToMessages = {
  BUDGET_TARGETS_HOURS_LIMIT_EXCEEDED: messages.editShiftFailureHourLimitExceed,
  BUDGET_TARGETS_MONEY_LIMIT_EXCEEDED: messages.editShiftFailureMoneyLimitExceed,
  SHIFTS_OVERLAPPING_WITH_BLOCKADES: messages.shiftsOverlappingWithBlockades,
};

export const shiftErrorCodesToTitles = {
  SHIFTS_OVERLAPPING_WITH_BLOCKADES: messages.shiftsOverlappingWithBlockadesTitle,
};

export const handleAddShiftFailureErrorMessage = (error, intl) => {
  if (error && error.response.data.message === 'Employee is busy at this time') {
    return {
      description: intl.formatMessage(messages.addShiftErrorBusy),
    };
  }
  if (error && error.response.data.errorCode && shiftErrorCodesToMessages[error.response.data.errorCode]) {
    return {
      title: intl.formatMessage(shiftErrorCodesToTitles[error.response.data.errorCode]),
      description: intl.formatMessage(shiftErrorCodesToMessages[error.response.data.errorCode]),
    };
  }
  return {
    description: intl.formatMessage(messages.addShiftErrorDefault),
  };
};

export const splitShiftsPassingThroughMidnight = templateShifts =>
  templateShifts.reduce((shifts, shift) => {
    const [startTime, endTime] = shift.working_hours.split('-');
    const startHour = parseInt(startTime.split(':')[0], 10);
    const endHour = parseInt(endTime.split(':')[0], 10);
    let parseStartTime = '';
    let parseEndTime = '';
    const endMinute = parseInt(endTime.split(':')[1], 10);

    if (startHour > endHour) {
      parseStartTime = `${startHour < 10 ? `0${startHour}` : startHour}:00-23:59`;
      parseEndTime = endHour === 0 && endMinute === 0 ? `${endTime}-00:01` : `00:01-${endTime}`;

      return [
        ...shifts,
        { ...shift, working_hours: parseStartTime },
        {
          ...shift,
          date: moment(shift.date).add(1, 'day').format('YYYY-MM-DD'),
          working_hours: parseEndTime,
        },
      ];
    }
    return [...shifts, shift];
  }, []);

export const getShiftsToAdd = (
  employee,
  shift,
  repeatObj,
  userEmployees,
  settings,
  scheduleUIState,
  calendarData,
  intl,
) => {
  const relevantEmployee = userEmployees.find(e => e.id === employee.id);

  if (!relevantEmployee) return intl.formatMessage(shiftsMessages.repeatShiftErrorEmployee);

  const shiftsToAdd = scheduleUIState.nonWorkingDays
    ? getWorkingDaysShifts(addObjWithRepeat(shift, repeatObj), calendarData.holidays)
    : addObjWithRepeat(shift, repeatObj);

  const error = validateShifts(shiftsToAdd, relevantEmployee, settings, shift, intl);

  return { error, shiftsToAdd };
};

export const validateShifts = (shiftsToAdd, relevantEmployee, settings, shift, intl) => {
  const shiftLocationSettings = settings.locationSettings[shift.location.id];
  const disabledDate = shiftLocationSettings?.disable_location_schedule_shifts_edit_until;

  let overlaps = false;
  let disabled = false;
  shiftsToAdd.some(s => {
    if (checkShiftOverlap(relevantEmployee, s)) {
      overlaps = true;
      return true;
    }
    if (disabledDate && moment(s.date).isSameOrBefore(disabledDate)) {
      disabled = true;
      return true;
    }
    return false;
  });
  if (overlaps) return intl.formatMessage(shiftsMessages.repeatShiftErrorOverlap);
  if (disabled) return intl.formatMessage(shiftsMessages.repeatShiftErrorDisabled);
  if (!shiftsToAdd.length) return intl.formatMessage(shiftsMessages.repeatShiftNoDates);
  return '';
};

export const getSingleDayScheduleShiftBlockColor = (
  isFromOtherLocation,
  isEditingScheduleDisabled,
  jobTitleColor,
  shouldConnectWithAbsence,
) => {
  if (isFromOtherLocation) return 'none';
  if (shouldConnectWithAbsence) return LIGHTER_GRAY;
  return isEditingScheduleDisabled ? 'none' : jobTitleColor;
};

export const getSizeOfDates = dateArrayLength => {
  if (dateArrayLength > 10) return 'month';
  if (dateArrayLength === 1) return 'day';
  return 'week';
};

export const getTradeShiftsForDate = (date, tradeShiftsWithEmployeeData, userEmployees) =>
  tradeShiftsWithEmployeeData
    .filter(
      shift => shift.date === date && shift.status !== 'rejected' && !isEmployeeInactive(shift.user.id, userEmployees),
    )
    .sort((a, b) => (a.working_hours > b.working_hours ? 1 : -1));

export const getShiftsCostBasedOnJobTitleWage = (shifts, userJobTitles, scheduleLoanedEmployees) =>
  shifts.reduce((sum, shift) => {
    const jobTitle = userJobTitles.find(j => j.id === shift.job_title.id);

    if (!jobTitle) return sum;

    const relevantContract = getRelevantContractForDate(
      scheduleLoanedEmployees[shift.employee.id]?.contracts || [],
      shift.date,
    );

    const contractJobTitle = relevantContract?.job_titles.find(j => j.job_title_id === jobTitle.id);
    const wage = contractJobTitle?.wage === 0 ? 0 : contractJobTitle?.wage ?? jobTitle.hourly_wage;
    const shiftDuration = shift.duration || calculateDurationMinutes(shift.working_hours);

    return sum + (shiftDuration * wage) / 60;
  }, 0);

export const getJobTitleWage = (jobTitle, userJobTitles) =>
  userJobTitles.find(j => j.id === jobTitle.id)?.hourly_wage || 0;
