import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import MDSelectPopover from '@/components/common/inputs/MDSelect/MDSelectPopover/MDSelectPopover.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import './EmploymentConditionsDeleteModal.scss';

const messages = defineMessages({
  employmentConditionsDeleteModalTitle: {
    id: 'companymanage.employmentConditions.deleteTitle',
    defaultMessage: 'Usuń warunek',
  },
  employmentConditionsDeleteModalConfirmText: {
    id: 'companymanage.employmentConditions.deleteConfirmText',
    defaultMessage: 'Dalej',
  },
});

const Y_SELECT_OFFSET = -40;

class EmploymentConditionsDeleteModal extends Component {
  constructor(props) {
    super(props);
    bindPrototypeFunctions(this);
    this.state = { templateToReplace: '' };
  }

  componentDidUpdate(prevProps) {
    const { showModal, employmentConditions } = this.props;
    if (showModal && showModal !== prevProps.showModal) {
      this.setState({
        templateToReplace: employmentConditions[0],
      });
    }
  }

  onSubmit() {
    this.props.deleteEmploymentConditionConfirm(this.props.modalObject, this.state.templateToReplace);
  }

  handleSelectChange(value) {
    this.setState({
      templateToReplace: this.props.employmentConditions.find(condition => condition.id === value),
    });
  }

  render() {
    const { employmentConditions, modalObject, showModal, hideModal } = this.props;
    const { formatMessage } = this.context.intl;
    const relevantOptions = employmentConditions
      .filter(condition => condition.id !== modalObject.id)
      .map(condition => ({
        key: condition.name,
        value: condition.id,
      }));
    return (
      <MDKadroModal
        show={showModal}
        title={formatMessage(messages.employmentConditionsDeleteModalTitle, {})}
        confirmText={formatMessage(messages.employmentConditionsDeleteModalConfirmText, {})}
        onSubmit={this.onSubmit}
        onHide={hideModal}
        modifiers={['narrow']}
        withoutOverlayScroll
      >
        <div className="k-employmentConditonsDeleteModal__description">
          <FormattedMessage
            id="companymanage.employmentConditions.deleteModalDescription"
            defaultMessage="Wybierz warunek którym chcesz zastąpić usuwany warunek zatrudnienia"
          />
        </div>
        <MDSelectPopover
          name={
            <FormattedMessage id="companymanage.employmentConditions.replaceSelect" defaultMessage="Wybierz warunek" />
          }
          options={relevantOptions}
          onChange={this.handleSelectChange}
          closeOnClick
          defaultValue={this.state.templateToReplace.id}
          yDropdownOffset={Y_SELECT_OFFSET}
        />
      </MDKadroModal>
    );
  }
}

EmploymentConditionsDeleteModal.defaultProps = {
  modalObject: { id: null },
};

EmploymentConditionsDeleteModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmploymentConditionsDeleteModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    id: PropTypes.string,
  }),
  employmentConditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  deleteEmploymentConditionConfirm: PropTypes.func,
  hideModal: PropTypes.func,
};

export default EmploymentConditionsDeleteModal;
