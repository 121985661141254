import { useEffect, useMemo } from 'react';

import { getLoanEmployeesProposals } from '@/actions/loanEmployeesProposals';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectAreLoanEmployeesProposalsShown } from '@/redux-store/schedule/viewSettings';
import { selectScheduleLocationFilter } from '@/redux-store/scheduleLocationFilter';
import { getFromToFromDateStore } from '@/utils/dateHelper';

export const useFetchLoanEmployeesProposals = () => {
  const dispatch = useAppDispatch();
  const showLoanEmployeesProposals = useAppSelector(selectAreLoanEmployeesProposalsShown);
  const locationsIds = useAppSelector(selectScheduleLocationFilter);
  const dateArray = useAppSelector(selectDateArray);
  const [from, to] = useMemo(() => getFromToFromDateStore({ dateArray }), [dateArray]);

  useEffect(() => {
    if (showLoanEmployeesProposals) dispatch(getLoanEmployeesProposals(from, to, locationsIds));
  }, [showLoanEmployeesProposals, from, locationsIds, to, dispatch]);
};
