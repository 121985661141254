import {
  INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL,
  OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL,
} from '@/constants/ActionTypes';
import { User } from '@/types';
import { UserJobTitle } from '@/types/jobTitles.types';
import { MappedLoanEmployeesProposal } from '@/types/loanEmployeesProposals';

import ScheduleShiftBlock from '../../ScheduleShiftBlock/ScheduleShiftBlock';
import { getLoanEmployeesProposalBlockTitle } from './LoanEmployeesProposalBlock.helpers.tsx/LoanEmployeesProposalBlock';

interface LoanEmployeesProposalBlockProps {
  loanEmployeesProposal: MappedLoanEmployeesProposal;
  size: string;
  jobTitles: UserJobTitle;
  showModal: (
    modalName: string,
    data:
      | { data: MappedLoanEmployeesProposal; locationId: string }
      | { date: string; locationId: string; isPreview?: boolean; canDelete?: boolean },
  ) => void;
  locationId: string;
  showDeleteLoanEmployeesProposalConfirmModal: (id: string) => void;
  currentUser: User;
  date: string;
  isIncomingProposal?: boolean;
  isOutgoingProposal?: boolean;
  isEditDisabled?: boolean;
}

export const LoanEmployeesProposalBlock = ({
  loanEmployeesProposal,
  jobTitles,
  size,
  isIncomingProposal,
  isOutgoingProposal,
  showModal,
  locationId,
  currentUser,
  date,
  showDeleteLoanEmployeesProposalConfirmModal,
  isEditDisabled,
}: LoanEmployeesProposalBlockProps) => {
  const { color, title } = jobTitles[loanEmployeesProposal.job_title_id] || '#FF0000';

  const [from, to] = loanEmployeesProposal.working_hours.split('-');

  const blockTitle = getLoanEmployeesProposalBlockTitle(title, loanEmployeesProposal.employees_remaining);
  const onClick = () => {
    if (isIncomingProposal)
      showModal(INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL, { data: loanEmployeesProposal, locationId });
  };
  const onAdd = () => {
    showModal(OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL, { date, locationId });
  };

  const canDelete = isOutgoingProposal && loanEmployeesProposal.created_by.id === currentUser.id;

  const showDetails = () => {
    showModal(INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL, {
      data: loanEmployeesProposal,
      locationId,
      isPreview: Boolean(isOutgoingProposal),
      canDelete,
    });
  };

  const onDelete = () => showDeleteLoanEmployeesProposalConfirmModal(loanEmployeesProposal.id);

  return (
    <ScheduleShiftBlock
      className="k-openShiftBlock"
      color={color}
      from={from}
      to={to}
      size={size}
      title={blockTitle}
      isIncomingProposal={isIncomingProposal}
      isOutgoingProposal={isOutgoingProposal}
      onEdit={onClick}
      onAdd={onAdd}
      showDetails={showDetails}
      onDelete={canDelete && onDelete}
      isEditDisabled={isEditDisabled}
    />
  );
};

export default LoanEmployeesProposalBlock;
