import browserHistory from '@/constants/browserHistory';
import { INTERCOM_ID_PREFIX } from '@/constants/envVariables';
import { INTERCOM_APP_ID } from '@/constants/intercom';
import { TRIAL_STATUS } from '@/constants/status';

export const getIntercomData = (currentUser, currentCompany, plan) => {
  const prefixedCompanyId = INTERCOM_ID_PREFIX ? `${INTERCOM_ID_PREFIX}-${currentCompany.id}` : currentCompany.id;
  const prefixedUserId = INTERCOM_ID_PREFIX ? `${INTERCOM_ID_PREFIX}-${currentUser.user.id}` : currentUser.user.id;

  return {
    email: currentUser.user.email,
    first_name: currentUser.user.first_name,
    name: `${currentUser.user.first_name} ${currentUser.user.last_name}`,
    last_name: currentUser.user.last_name,
    phone: currentUser.user.phone,
    user_id: prefixedUserId,
    user_role: currentUser.user.role,
    company_id: prefixedCompanyId,
    company_name: currentCompany.name,
    user_hash: currentUser.user.intercomHash,

    company: {
      id: prefixedCompanyId,
      name: currentCompany.name,
      'account-status': currentCompany.status.status,
      'trial-expired-date':
        currentCompany.status.status === TRIAL_STATUS ? currentCompany.status.trial_expiration : null,
      plan,
      customAttributes: {
        id: prefixedCompanyId,
        companyName: currentCompany.name,
      },
    },
  };
};

export const handleIntercomClick = event => {
  const element = event.target;
  if (element.tagName.toUpperCase() === 'A') {
    if (~element.href.search('.kadromierz.pl/')) {
      event.preventDefault();
      browserHistory.push(`/${element.href.split('.kadromierz.pl/')[1]}`);
    }
  }
};

export const intercomUpdate = intercomData => {
  try {
    window.Intercom('update', intercomData);
  } catch (error) {
    console.error(error);
  }
};

export const intercomTrackEvent = (intercomEventType, intercomData) => {
  try {
    window.Intercom('trackEvent', intercomEventType, intercomData);
  } catch (error) {
    console.error(error);
  }
};

export const intercomBoot = intercomData => {
  const { company, ...userProps } = intercomData;
  const intercomAppId = getIntercomAppIdBasedOnUrl();

  try {
    window.Intercom('onHide', () => {
      const intercomIframe = document.getElementsByName('intercom-messenger-frame')[0];
      intercomIframe.contentDocument.removeEventListener('click', handleIntercomClick);
    });

    window.Intercom('onShow', () => {
      setTimeout(() => {
        const intercomIframe = document.getElementsByName('intercom-messenger-frame')[0];
        intercomIframe.contentDocument.addEventListener('click', handleIntercomClick);
      }, 1000);
    });
    window.Intercom('boot', {
      app_id: intercomAppId,
      company,
      ...userProps,
    });
    intercomUpdate(intercomData);
  } catch (error) {
    console.error(error);
  }
};

export const intercomShutdown = () => {
  try {
    window.Intercom('shutdown');
  } catch (error) {
    console.error(error);
  }
};

export const intercomShowNewMessage = message => {
  try {
    window.Intercom('showNewMessage', message);
  } catch (error) {
    console.error(error);
  }
};

export const startTour = tourId => {
  try {
    window.Intercom('startTour', tourId);
  } catch (error) {
    console.error(error);
  }
};

export const getIntercomAppIdBasedOnUrl = () => {
  if (import.meta.env.VITE_NODE_ENV === 'development') {
    if (import.meta.env.VITE_TARGET_API === 'staging') return INTERCOM_APP_ID.test;
    if (import.meta.env.VITE_TARGET_API === 'master') return INTERCOM_APP_ID.master;
  }
  const url = window.location.href;
  if (url.includes('staging.fb.kadro.dev') || url.includes('staging.kadromierz.pl')) return INTERCOM_APP_ID.test;
  return INTERCOM_APP_ID.master;
};
