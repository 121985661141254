import { conn } from '@/actions';
import { Location } from '@/types';
import { StandardDate } from '@/types/dates.types';

import { createActionName, createAppAsyncThunk } from '../utils';
import { UserEvent } from './userEvents.types';
import { sliceName } from './userEvents.utils';

export const getManyEvents = createAppAsyncThunk(
  createActionName(sliceName, 'getManyEvents'),
  async ({ from, to, locationsIds }: { from: StandardDate; to: StandardDate; locationsIds: Location['id'][] }) => {
    const response = await conn.getMultipleScheduleEventsWithPost(locationsIds, from, to);
    const userEvents = response.data.events as UserEvent[];
    return userEvents;
  },
);

export const createUserEvent = createAppAsyncThunk(
  createActionName(sliceName, 'createUserEvent'),
  async (event: Omit<UserEvent, 'id'>): Promise<UserEvent> => {
    const response = await conn.createEvent(event);
    const userEvent = response.data as UserEvent;
    return userEvent;
  },
);

export const createUserEvents = createAppAsyncThunk(
  createActionName(sliceName, 'createUserEvents'),
  async (events: Omit<UserEvent, 'id'>[]): Promise<UserEvent[]> => {
    const response = await conn.createMultipleEvents(events);
    const userEvents = response.data as UserEvent[];
    return userEvents;
  },
);

export const deleteUserEvent = createAppAsyncThunk(
  createActionName(sliceName, 'deleteUserEvent'),
  async (event: UserEvent): Promise<UserEvent> => {
    await conn.deleteEvent(event.id);
    return event;
  },
);

export const updateUserEvent = createAppAsyncThunk(
  createActionName(sliceName, 'updateUserEvent'),
  async (event: UserEvent): Promise<UserEvent> => {
    await conn.changeEvent(event);
    return event;
  },
);
