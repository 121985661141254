import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';

import './noLocationModal.scss';

const messages = defineMessages({
  logout: {
    id: 'noLocationModal.logout',
    defaultMessage: 'Powrót do logowania',
  },
  noLocationsTitle: {
    id: 'noLocationModal.noLocationsTitle',
    defaultMessage: 'Brak przypisanych lokalizacji',
  },
  noLocationsInformation: {
    id: 'noLocationModal.noLocationsInformation',
    defaultMessage:
      'Brak przypisanych lokalizacji do Twojego konta. Skontaktuj się z administratorem konta w celu dodania lokalizacji.',
  },
});

const NoLocationModal = ({ showModal, hideModal, logoutUser }, { intl }) => {
  const onHide = () => {
    console.log('handling logout 11');
    hideModal();
    logoutUser();
  };
  return (
    <MDKadroModal
      show={showModal}
      onHide={onHide}
      onSubmit={onHide}
      modifiers={['narrow']}
      hideCancelButton
      confirmText={intl.formatMessage(messages.logout)}
      title={intl.formatMessage(messages.noLocationsTitle)}
    >
      <div className="noLocationsModal__text">{intl.formatMessage(messages.noLocationsInformation)}</div>
    </MDKadroModal>
  );
};

NoLocationModal.contextTypes = {
  intl: PropTypes.object.isRequired,
};

NoLocationModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  logoutUser: PropTypes.func,
};

export default NoLocationModal;
