import { useMemo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectIsBudgetTableShown, selectIsEventsTableShown } from '@/redux-store/schedule/viewSettings';
import { selectScheduleLocationFilter } from '@/redux-store/scheduleLocationFilter';
import { selectScheduleStateLocations } from '@/redux-store/scheduleState/locations';
import { selectSelectedDisplayModeType } from '@/redux-store/scheduleUIState';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { calculateScheduleTableHeight } from '@/utils/schedulerHelpers';

import { useScheduleHooks } from './hooks';

export const useScheduleModeView = () => {
  const scheduleLocationFilter = useAppSelector(selectScheduleLocationFilter);
  const showEventsTable = useAppSelector(selectIsEventsTableShown);
  const showBudgetTable = useAppSelector(selectIsBudgetTableShown);
  const scheduleStateLocations = useAppSelector(selectScheduleStateLocations);
  const displayMode = useAppSelector(selectSelectedDisplayModeType);
  const windowHeight = window.innerHeight;

  useScheduleHooks();

  const height = useMemo(
    () => calculateScheduleTableHeight(windowHeight, { showBudgetTable, showEventsTable }),
    [windowHeight, showBudgetTable, showEventsTable],
  );

  const employeesPerLocation = useMemo(
    () => Object.values(scheduleStateLocations).filter(l => scheduleLocationFilter.includes(l.locationId)),
    [scheduleStateLocations, scheduleLocationFilter],
  );

  const areLocationsNotSelected = isEmptyArray(employeesPerLocation);

  const noEmployeesAreVisible = employeesPerLocation.length === 1 && isEmptyArray(employeesPerLocation[0].visible);

  return {
    areLocationsNotSelected,
    displayMode,
    employeesPerLocation,
    height,
    noEmployeesAreVisible,
    scheduleLocationFilter,
    showBudgetTable,
    showEventsTable,
  };
};
