import { createSelector } from '@reduxjs/toolkit';

import { UserLocation } from '@/types';
import { transformToDict } from '@/utils/objectHelpers/objectHelpers';

import { RootState } from '../types';

export const selectLocationById = (id?: UserLocation['id']) =>
  createSelector(selectUserLocations, userLocations =>
    id ? userLocations.find(location => location.id === id) : undefined,
  );

export const selectUserLocations = (state: RootState) => state.reducer.userLocations;

export const selectUserLocationsDict = createSelector(selectUserLocations, userLocations =>
  transformToDict(userLocations, 'id'),
);
