import { EXPORT_OPTIONS } from '@/constants/exportConstants';

export const getUpdatedOptions = selectedFormat =>
  (selectedFormat?.options || []).reduce((prev, curr) => {
    if (curr.type === EXPORT_OPTIONS.CHECKBOX) return { ...prev, [curr.id]: false };
    if (curr.type === EXPORT_OPTIONS.RADIO) return { ...prev, [curr.id]: curr.defaultValue };
    if (curr.type === EXPORT_OPTIONS.DYNAMIC_LIST) return { ...prev, [curr.id]: curr.items };
    if (curr.type === EXPORT_OPTIONS.MULTI_SELECT) return { ...prev, [curr.id]: curr.items };
    return prev;
  }, {});

export const getColumnsForFormat = selectedFormat =>
  selectedFormat?.options?.find(({ type }) =>
    [EXPORT_OPTIONS.LIST_SELECTION, EXPORT_OPTIONS.MULTI_SELECT].includes(type),
  )?.items || [];

const getAbsenceTypesExportOptions = (absenceTypesItems, absenceTypes) =>
  absenceTypesItems.reduce(
    (result, item) => {
      const isSelected = item.active;
      const absenceType = absenceTypes.find(type => type.id === item.value);
      if (isSelected) {
        result.absenceTypesToShow.push(absenceType);
        return result;
      }

      result.otherAbsenceTypes.push(absenceType);
      return result;
    },
    { absenceTypesToShow: [], otherAbsenceTypes: [] },
  );

export const formatAdditionalOptions = (multiSelectOptions, multiSelects, absenceTypes) =>
  multiSelectOptions.reduce((result, option) => {
    const { id } = option;

    switch (id) {
      case 'columns':
        return {
          ...result,
          selectedColumns: multiSelects.columnsItems.filter(({ active }) => active).map(({ value }) => value),
        };
      case 'absenceTypesToShow': {
        const { absenceTypesToShow, otherAbsenceTypes } = getAbsenceTypesExportOptions(
          multiSelects.absenceTypesItems,
          absenceTypes,
        );
        return {
          ...result,
          absenceTypesToShow,
          otherAbsenceTypes,
        };
      }
      default:
        return result;
    }
  }, {});
