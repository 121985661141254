import { FormattedMessage } from 'react-intl';

import { defaultScheduleCycle } from '@/constants/employmentConditions.js';
import { validateRecommendedScheduleFileHandler } from '@/utils/inputValidation.js';
import KadroImport from '@/utils/KadroImport.js';
import { groupAndAdjustRecommendedSchedule } from '@/utils/recommendedScheduleHelpers.js';

import { addEmployee, prepareAddJobTitle, prepareAddLocation } from '@/actions/companymanage';

export const iDontWantDefaultExport = 1;

function ImportMessage(message, type, line = null) {
  this.message = message;
  this.type = type;
  this.line = line;
}

export const importRecommendedScheduleFile = file => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { userJobTitles, mainDateStore } = getState().reducer;
    const importControler = new KadroImport();
    importControler
      .readXLSFromBuffer(file)
      .then(() => {
        importControler.parseRecommendedScheduleFile(userJobTitles);
        const importData = importControler.getObject();
        const { recommendedScheduleData } = importData;
        const messages = validateRecommendedScheduleFileHandler(
          file.name,
          'szablon_prognozowanego_grafiku',
          recommendedScheduleData,
          mainDateStore.dateArray,
          false,
        );

        if (messages.length) {
          reject(messages);
        }

        resolve(recommendedScheduleData);
      })
      .catch(err => {
        reject(err);
      });
  });

export const importScheduleTemplate = file => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { userJobTitles, mainDateStore, locationFilter } = getState().reducer;

    const importControler = new KadroImport();
    importControler
      .readXLSFromBuffer(file)
      .then(() => {
        importControler.parseRecommendedScheduleFile(userJobTitles);
        const importData = importControler.getObject();
        const { recommendedScheduleData } = importData;
        const messages = validateRecommendedScheduleFileHandler(
          file.name,
          'szablon_prognozowanego_grafiku',
          recommendedScheduleData,
          mainDateStore.dateArray,
          false,
        );

        if (messages.length) {
          reject(messages.map(m => m.message).join('. '));
        }
        resolve(groupAndAdjustRecommendedSchedule(recommendedScheduleData, locationFilter.selectedLocation.id));
      })
      .catch(err => {
        reject(err);
      });
  });

export const importFromFile = file => (dispatch, getState) =>
  new Promise(resolve => {
    const { currentCompany } = getState().reducer;
    const importControler = new KadroImport();
    importControler.readXLSFromBuffer(file).then(() => {
      importControler.parse();
      const importData = importControler.getObject();
      const { locations, jobTitles, employees } = importData;

      const filteredEmployees = employees.map(({ pin: _, ...employee }) => employee);

      const messages = [];
      let addedEmployees = 0;
      const addingLocations = locations.map(
        (location, i) =>
          new Promise(resolve => {
            dispatch(prepareAddLocation(location)).then(result => {
              locations[i] = Object.assign({}, result, { uuid: location.id });
              resolve();
            });
          }),
      );

      Promise.all(addingLocations).then(() => {
        const addingJobTitles = jobTitles.map(
          (job, i) =>
            new Promise(resolve => {
              dispatch(prepareAddJobTitle({ ...job, id: undefined })).then(result => {
                jobTitles[i] = Object.assign({}, result, { uuid: job.id });
                resolve();
              });
            }),
        );

        Promise.all(addingJobTitles).then(() => {
          const { userLocations, userJobTitles, employmentConditions } = getState().reducer;
          filteredEmployees.forEach((employee, i) => {
            let add = true;
            const empLocations = [];
            if (employee.locations)
              employee.locations.split(',').forEach(l => {
                const locName = importControler.processString(l);
                const loc = userLocations.find(l2 => l2.name === locName);
                if (loc) {
                  empLocations.push(loc);
                } else {
                  add = false;
                  messages.push(
                    new ImportMessage(
                      (
                        <FormattedMessage
                          id="settings.import.noLocation"
                          defaultMessage='Brak lokalizacji "{locName}". Pracownik nie zostanie dodany.'
                          values={{ locName }}
                        />
                      ),
                      'error',
                      i + 2,
                    ),
                  );
                }
              });
            employee.locations = empLocations;
            const employeesJobTitles = [];
            if (employee.jobTitles)
              employee.jobTitles.split(',').forEach(j => {
                const jobName = importControler.processString(j);
                const jobTitle = userJobTitles.find(j2 => j2.title === jobName);
                if (jobTitle) {
                  employeesJobTitles.push({ job_title_id: jobTitle.id });
                } else {
                  add = false;
                  messages.push(
                    new ImportMessage(
                      (
                        <FormattedMessage
                          id="settings.import.noJobTitle"
                          defaultMessage='Brak stanowiska "{jobName}". Pracownik nie zostanie dodany.'
                          values={{ jobName }}
                        />
                      ),
                      'error',
                      i + 2,
                    ),
                  );
                }
              });
            employee.contracts = [
              {
                job_titles: employeesJobTitles,
              },
            ];
            let employmentCondition = null;
            if (employee.employment_conditions) {
              const employmentConditionName = importControler.processString(employee.employment_conditions);
              employmentCondition = employmentConditions.find(ec => ec.name === employmentConditionName);
              if (employmentCondition) {
                employmentCondition = {
                  ...employmentCondition,
                  template_id: employmentCondition.id,
                };
              } else {
                add = false;
                messages.push(
                  new ImportMessage(
                    (
                      <FormattedMessage
                        id="settings.import.noEmploymentCondition"
                        defaultMessage='Brak warunku zatrudnienia "{employmentConditionName}". Pracownik nie zostanie dodany.'
                        values={{ employmentConditionName }}
                      />
                    ),
                    'error',
                    i + 2,
                  ),
                );
              }
            }
            employee.employment_conditions = employmentCondition || {
              ...employmentConditions.find(ec => ec.id === 'rown'),
              template_id: 'rown',
            };
            if (!employee.employment_conditions.schedule_cycle) {
              employee.employment_conditions.schedule_cycle = defaultScheduleCycle;
            }
            if (add) {
              addedEmployees++;
              dispatch(
                addEmployee(
                  Object.assign({}, employee, {
                    company_id: currentCompany.id,
                    supplementary_locations: '',
                  }),
                  false,
                ),
              );
            }
          });
          if (addedEmployees > 0)
            messages.unshift(
              new ImportMessage(
                (
                  <FormattedMessage
                    id="settings.import.addedEmployees"
                    defaultMessage={`Dodano {addedEmployees, number} {addedEmployees, plural,
                      one {pracownika}
                      other {pracowników}
                    }`}
                    values={{ addedEmployees }}
                  />
                ),
                'info',
              ),
            );
          if (jobTitles.length > 0)
            messages.unshift(
              new ImportMessage(
                (
                  <FormattedMessage
                    id="settings.import.addedJobTitles"
                    defaultMessage={`Dodano {length, number} {length, plural,
                                      one {stanowisko}
                                      few {stanowiska}
                                      other {stanowisk}
                                    }`}
                    values={{ length: jobTitles.length }}
                  />
                ),
                'info',
              ),
            );
          if (locations.length > 0)
            messages.unshift(
              new ImportMessage(
                (
                  <FormattedMessage
                    id="settings.import.addedLocations"
                    defaultMessage={`Dodano {length, number} {length, plural,
                                one {lokalizację}
                                few {lokalizacje}
                                other {lokalizacji}
                              }`}
                    values={{ length: locations.length }}
                  />
                ),
                'info',
              ),
            );
          resolve(messages);
        });
      });
    });
  });
