import { defineMessages } from 'react-intl';

import { conn, showConfirmModal } from '@/actions';
import * as actionTypes from '@/constants/ActionTypes';
import { Location } from '@/types';
import { CreatedProposalData, LoanEmployeesProposals } from '@/types/loanEmployeesProposals';
import { MainDateStore } from '@/types/mainDateStore';

import { addMultipleShiftsSuccess } from './schedule';
import { addLoanedEmployees } from './scheduleLoanedEmployees';

export const messages = defineMessages({
  getLoanEmployeesProposalDataError: {
    id: 'employee.loanEmployeesProposal.getData.error',
    defaultMessage: 'Nie udało się pobrać danych dotyczących prośby udostępnionych pracowników',
  },
  acceptLoanEmployeesProposalDataError: {
    id: 'employee.loanEmployeesProposal.accept.error',
    defaultMessage: 'Nie udało się zaakceptować prośby',
  },
  createLoanEmployeesProposalError: {
    id: 'employee.loanEmployeesProposal.create.error',
    defaultMessage: 'Nie udało się dodać prośby',
  },
  acceptLoanEmployeesProposalSuccessful: {
    id: 'employee.loanEmployeesProposal.accept.success',
    defaultMessage: 'Zaakceptowano prośbę',
  },
  createLoanEmployeesProposalSuccessful: {
    id: 'employee.loanEmployeesProposal.create.success',
    defaultMessage: 'Dodano prośbę',
  },

  deleteLoanEmployeesProposalSuccessful: {
    id: 'employee.loanEmployeesProposal.delete.error',
    defaultMessage: 'Usunięto prośbę',
  },
  deleteLoanEmployeesProposalError: {
    id: 'employee.deleteLoanEmployeesProposallError.delete.success',
    defaultMessage: 'Nie udało się usunąć prośby',
  },

  deleteLoanEmployeesProposalTitle: {
    id: 'openShift.deleteLoanEmployeesProposalTitle',
    defaultMessage: 'Usuń prośbę o udostępnienie',
  },
  deleteLoanEmployeesProposalDescription: {
    id: 'openShift.deleteLoanEmployeesProposalDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć prośbę o udostępnienie?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
});

export const getLoanEmployeesProposalsError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.GET_LOAN_EMPLOYEES_PROPOSAL_ERROR,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.getLoanEmployeesProposalDataError),
      type: 'error',
    },
  });
};

export const acceptLoanEmployeesProposalError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.ACCEPT_LOAN_EMPLOYEES_PROPOSAL_ERROR,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.acceptLoanEmployeesProposalDataError),
      type: 'error',
    },
  });
};

export const createLoanEmployeesProposalError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.CREATE_LOAN_EMPLOYEES_POROPOSAL_ERROR,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.createLoanEmployeesProposalError),
      type: 'error',
    },
  });
};

export const getLoanEmployeesProposalsSuccessful = (data: LoanEmployeesProposals[]) => ({
  type: actionTypes.GET_LOAN_EMPLOYEES_PROPOSALS_SUCCESSFUL,
  payload: data,
});

export const getLoanEmployeesProposals = (from: string, to: string, locationIds: string[]) => async dispatch => {
  try {
    const { data } = await conn.getLoanEmployeesProposalsWitPost(from, to, locationIds);

    dispatch(getLoanEmployeesProposalsSuccessful(data));
  } catch (e) {
    dispatch(getLoanEmployeesProposalsError());
  }
};

export const acceptLoanEmployeesProposalSuccessful = (data: LoanEmployeesProposals) => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.ACCEPT_LOAN_EMPLOYEES_PROPOSAL_SUCCESSFUL,
    payload: data,
    notification: {
      title: intl.formatMessage(messages.acceptLoanEmployeesProposalSuccessful),
      type: 'success',
    },
  });
};

export const processLoanedEmployees = addedShifts => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;

  const loanedEmployees = userEmployees.reduce((acc, employee) => {
    const shiftsForCurrentAccount = addedShifts.filter(shift => shift.employee.id === employee.id);

    if (!shiftsForCurrentAccount.length) return acc;
    const parsedEmployee = { ...employee, shifts: shiftsForCurrentAccount };

    acc.push(parsedEmployee);
    return acc;
  }, []);

  if (loanedEmployees.length) dispatch(addLoanedEmployees(loanedEmployees));
};

export const acceptLoanEmployeesProposal = (data: { id: string; selected_employees: string[] }) => async dispatch => {
  try {
    const response = await conn.acceptLoanEmployeesProposal(data);

    dispatch(acceptLoanEmployeesProposalSuccessful(response.data));
    dispatch(addMultipleShiftsSuccess(response.data.addedShifts));

    dispatch(processLoanedEmployees(response.data.addedShifts));
  } catch (e) {
    dispatch(acceptLoanEmployeesProposalError());
  }
};

export const createLoanEmployeesProposalSuccessful = (data: CreatedProposalData) => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.CREATE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL,
    payload: data,
    notification: {
      title: intl.formatMessage(messages.createLoanEmployeesProposalSuccessful),
      type: 'success',
    },
  });
};

export const createLoanEmployeesProposal = (data: CreatedProposalData) => async dispatch => {
  try {
    const response = await conn.createLoanEmployeesProposal(data);
    dispatch(createLoanEmployeesProposalSuccessful(response.data));
  } catch (e) {
    dispatch(createLoanEmployeesProposalError());
  }
};

export const deleteLoanEmployeesProposalSuccessful = (id: string) => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL,
    payload: id,
    notification: {
      title: intl.formatMessage(messages.deleteLoanEmployeesProposalSuccessful),
      type: 'success',
    },
  });
};

export const deleteLoanEmployeesProposalError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_LOAN_EMPLOYEES_POROPOSAL_ERROR,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.deleteLoanEmployeesProposalError),
      type: 'error',
    },
  });
};

export const deleteLoanEmployeesProposal = (proposalId: string) => async dispatch => {
  try {
    await conn.deleteLoanEmployeesProposal(proposalId);

    dispatch(deleteLoanEmployeesProposalSuccessful(proposalId));
  } catch (e) {
    dispatch(deleteLoanEmployeesProposalError());
  }
};

export const showDeleteLoanEmployeesProposalConfirmModal = (proposalId: string) => (dispatch, _getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteLoanEmployeesProposalTitle),
      description: intl.formatMessage(messages.deleteLoanEmployeesProposalDescription),
      confirmText: intl.formatMessage(messages.delete),
      confirmFunc: () => {
        dispatch(deleteLoanEmployeesProposal(proposalId));
      },
    }),
  );
};

export const getLoanEmployeesProposalsAssignments =
  (from: MainDateStore['dateArray'][number], to: MainDateStore['dateArray'][number], locationIds: Location['id'][]) =>
  async dispatch => {
    try {
      const { data } = await conn.getLoanEmployeesProposalsAssignments(from, to, locationIds);
      const { loanEmployeesProposalsAssignments } = data;
      dispatch({
        type: actionTypes.GET_LOAN_EMPLOYEES_PROPOSALS_ASSIGNMENTS_SUCCESSFUL,
        payload: loanEmployeesProposalsAssignments,
      });
      return loanEmployeesProposalsAssignments;
    } catch (e) {
      console.error(e);
    }
  };
