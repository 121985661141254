import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux-store';

export const selectLocationGroups = (state: RootState) => state.reducer.locationGroups.locationGroups;

export const selectAllLocationGroupsNames = createSelector(selectLocationGroups, locationGroups =>
  locationGroups.map(locationGroup => locationGroup.name),
);

export const selectLocationGroupsForLocationId = (locationId: string) =>
  createSelector(selectLocationGroups, locationGroups =>
    locationGroups.filter(locationGroup => locationGroup.locationIds.includes(locationId)),
  );
