import classnames from 'classnames';
import { memo, useMemo } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon';
import { useAppSelector } from '@/redux-store';
import { selectDateArray, selectIsDayDateMode, selectTodayFromMainDateStore } from '@/redux-store/mainDateStore';
import { selectHasLocationCoordinates } from '@/redux-store/settings/locationSettings/locationSettings.selectors';
import { selectLocationById } from '@/redux-store/userLocations';
import { selectWeatherForecastByLocationIdAndDate } from '@/redux-store/weatherForecast';
import { Location } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { isBeforeTimestamp } from '@/utils/dateHelper';

type Props = {
  date: StandardDate;
  locationId: Location['id'];
};

export const ScheduleTableWeatherPreview = memo(({ date, locationId }: Props) => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const location = useAppSelector(selectLocationById(locationId));
  const weatherForecast = useAppSelector(selectWeatherForecastByLocationIdAndDate(locationId, date));
  const locationCoordinatesExist = useAppSelector(selectHasLocationCoordinates(locationId));
  const today = useAppSelector(selectTodayFromMainDateStore);
  const dateArray = useAppSelector(selectDateArray);
  const numberOfDaysInSchedule = dateArray.length;
  const showTemperature = useMemo(() => numberOfDaysInSchedule < 14, [numberOfDaysInSchedule]);
  const showPrecipitation = useMemo(() => numberOfDaysInSchedule < 5, [numberOfDaysInSchedule]);
  const isDayFromThePast = isBeforeTimestamp(date, today, 'day');
  const noWeatherClassNames = classnames(
    'k-weatherRow__previewIcon',
    isDayFromThePast ? 'k-weatherRow__previewIcon--gray' : 'k-weatherRow__previewIcon--orange',
  );
  const previewContainerClassNames = classnames('k-weatherRow__previewContainer', {
    'k-weatherRow__previewContainer--centered':
      (!showTemperature && !showPrecipitation) || !locationCoordinatesExist || !weatherForecast,
    'k-weatherRow__previewContainer--singleDay': isSingleDay,
  });
  const iconClassNames = classnames('k-weatherRow__previewIcon ', 'k-weatherRow__previewIcon--orange', {
    'k-weatherRow__previewIcon--higher': isSingleDay,
  });

  if (!locationCoordinatesExist)
    return (
      <div className={previewContainerClassNames}>
        <Icon
          type="material-outlined"
          className="k-weatherRow__previewIcon k-weatherRow__previewIcon--orange"
          name="wrong_location"
        />
      </div>
    );

  if (!weatherForecast)
    return (
      <div className={previewContainerClassNames}>
        <Icon type="material-outlined" className={noWeatherClassNames} name="cloud_off" />
      </div>
    );

  const { minTemp, maxTemp, chanceForPrecipitation, weatherType, description } = weatherForecast;

  return (
    <div className={previewContainerClassNames}>
      {showTemperature && (
        <div className="k-weatherRow__temperatureContainer">
          <div className="k-weatherRow__temperature k-weatherRow__temperature--high">{maxTemp}°</div>
          <div className="k-weatherRow__temperature k-weatherRow__temperature--low">{minTemp}°</div>
          {showPrecipitation && (
            <div className="k-weatherRow__precipitation">
              <Icon
                type="material-outlined"
                className="k-weatherRow__previewIcon k-weatherRow__previewIcon--small"
                name="water_drop"
              />
              {chanceForPrecipitation}%
            </div>
          )}
        </div>
      )}
      <div className="k-weatherRow__iconAndCity">
        {isSingleDay && <span className="k-weatherRow__city">{location?.city}</span>}
        <Icon type="material-symbols" className={iconClassNames} name={weatherType} />
      </div>
      {isSingleDay && <div className="k-weatherRow__description">{description}</div>}
    </div>
  );
});

ScheduleTableWeatherPreview.displayName = 'ScheduleTableWeatherPreview';
