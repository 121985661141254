import { createSlice } from '@reduxjs/toolkit';

import { getWeatherForecast } from './weatherForecast.asyncActions';
import { WeatherForecastStoreState } from './weatherForecast.types';

export const sliceName = 'weatherForecast';

export const initialState: WeatherForecastStoreState = {};

const WeatherForecastSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWeatherForecast.fulfilled, (state, action) => ({ ...state, ...action.payload }));
  },
});

export const weatherForecastReducer = WeatherForecastSlice.reducer;
