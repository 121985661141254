import { memo } from 'react';

import AbsenceHistoryModalContainer from '@/components/absences/modals/AbsenceHistoryModal/AbsenceHistoryModal.redux.js';
import AbsenceProposalModal from '@/components/absences/modals/AbsenceProposalModal/AbsenceProposalModal.redux.js';
import AutoGenerateScheduleModal from '@/components/autoscheduler/AutoGenerateScheduleModal/AutoGenerateScheduleModal.redux.js';
import AutoGenerateScheduleModalViaProxy from '@/components/autoscheduler/AutoGenerateScheduleModal/AutoGenerateScheduleModalForProxy.redux.js';
import AutoGenerateStepsModal from '@/components/autoscheduler/AutoGenerateStepsModal/AutoGenerateStepsModal.redux';
import EmployeeModal from '@/components/companymanage/employees/EmployeeModal/EmployeeModal/EmployeeModal.redux';
import AddOpenShiftModalContainer from '@/components/scheduler/modals/AddOpenShiftModal/AddOpenShiftModal.redux.js';
import AddShiftAndAbsenceModalContainer from '@/components/scheduler/modals/AddShiftAndAbsenceModal/AddShiftAndAbsenceModal.redux.js';
import AddShiftModalContainer from '@/components/scheduler/modals/AddShiftModal/AddShiftModal.redux.js';
import ApplyForOpenShiftModalContainer from '@/components/scheduler/modals/ApplyForOpenShiftModal/ApplyForOpenShiftModal.redux';
import EditEventModalContainer from '@/components/scheduler/modals/EventModal/EditEventModal.redux.js';
import { AUTOSCHEDULER_PROXY_ENABLE } from '@/constants/Permissions.js';
import AddAvailabilityModal from '@/containers/availability/AddAvailabilityModalContainer';
import EditAvailabilityModalContainer from '@/containers/availability/EditAvailabilityModalContainer';
import ObjectHistoryModalContainer from '@/containers/common/ObjectHistoryModalContainer.js';
import ApprovalTradeShiftModalContainer from '@/containers/scheduler/ApprovalTradeShiftModalContainer.jsx';
import AssignUsersOpenShiftModalContainer from '@/containers/scheduler/AssignUsersOpenShiftModalContainer.js';
import CoworkerTradeShiftModalContainer from '@/containers/scheduler/CoworkerTradeShiftModalContainer.jsx';
import ImportOpenShiftModalContainer from '@/containers/scheduler/openShift/ImportOpenShiftModalContainer.jsx';
import RequestTradeShiftModalContainer from '@/containers/scheduler/RequestTradeShiftModalContainer.jsx';
import ScheduleHelpModalContainer from '@/containers/scheduler/ScheduleHelpModalContainer.js';
import { useAppSelector } from '@/redux-store';
import { selectScheduleLocationFilter } from '@/redux-store/scheduleLocationFilter';
import { selectPermissionsDict } from '@/redux-store/userPermissions';

import IncomingLoanEmployeesProposalModal from '../../loanEmployeesProposalModals/incomingLoanEmployeesProposalModal/incomingLoanEmployeesProposalModal/IncomingLoanEmployeesProposalModal.redux.js';
import OutgoingLoanEmployeesProposalModal from '../../loanEmployeesProposalModals/outgoingLoanEmployeesProposalModal/OutgoingLoanEmployeesProposalModal.redux.js';
import { DeleteScheduleItemsModal } from '../../modals/DeleteScheduleItemsModal/DeleteScheduleItemsModal';
import ExportModal from '../../modals/ExportScheduleModal/ExportScheduleModal.redux.js';
import ImportBudgetMetricsModal from '../../modals/ImportBudgetMetricsModal/ImportBudgetMetricsModal.redux';
import ImportBudgetModal from '../../modals/ImportBudgetModal/ImportBudgetModal.redux.js';
import ImportBudgetTargetModal from '../../modals/ImportBudgetTargetModal/ImportBudgetTargetModal.redux.js';
import ImportScheduleModal from '../../modals/ImportScheduleModal/ImportScheduleModal.redux.js';
import LoadBudgetTargetModal from '../../modals/LoadBudgetTargetModal/LoadBudgetTargetModal.redux.js';
import ImportRecommendedScheduleModal from '../../modals/LoadRecommendedScheduleModal/ImportRecommendedScheduleModal/ImportRecommendedScheduleModal.redux.js';
import LoadRecommendedScheduleModal from '../../modals/LoadRecommendedScheduleModal/LoadRecommendedScheduleModal.redux';
import RecommendedScheduleFromExistingTemplate from '../../modals/LoadRecommendedScheduleModal/RecommendedScheduleFromExistingTemplate/RecommendedScheduleFromExistingTemplate.redux';
import { ManagerNoteModal } from '../../modals/ManagerNotesModal/ManagerNotesModal';
import QuickPlanningWarningModal from '../../modals/QuickPlanningWarningModal/QuickPlanningWarningModal.redux';
import SaveScheduleAsTemplateModal from '../../modals/SaveScheduleAsTemplateModal/SaveScheduleAsTemplateModal.redux.js';
import ShortcutsModal from '../../modals/ShortcutsModal/ShortcutsModal.redux.js';
import ShowConflictsModal from '../../modals/ShowConflictsModal/ShowConflictsModal.redux.js';

export const ScheduleModals = memo(() => {
  const scheduleLocationFilter = useAppSelector(selectScheduleLocationFilter);
  const permissionsDict = useAppSelector(selectPermissionsDict);

  return (
    <>
      <ExportModal
        // @ts-ignore
        exportRawData={() => ({
          locationsToDisplay: scheduleLocationFilter,
          type: 'schedule',
        })}
      />
      <ImportBudgetMetricsModal />
      <ImportBudgetModal />
      <LoadRecommendedScheduleModal />
      <ImportBudgetTargetModal />
      <LoadBudgetTargetModal />
      <ImportRecommendedScheduleModal />
      <RecommendedScheduleFromExistingTemplate />
      <ImportScheduleModal />
      <EditEventModalContainer />
      <ObjectHistoryModalContainer />
      <AddShiftModalContainer />
      <AddShiftAndAbsenceModalContainer />
      <AbsenceHistoryModalContainer />
      <AutoGenerateStepsModal />
      {permissionsDict[AUTOSCHEDULER_PROXY_ENABLE] ? (
        <AutoGenerateScheduleModalViaProxy />
      ) : (
        <AutoGenerateScheduleModal />
      )}
      <AddOpenShiftModalContainer />
      <ApplyForOpenShiftModalContainer />
      <AssignUsersOpenShiftModalContainer />
      <ScheduleHelpModalContainer />
      <RequestTradeShiftModalContainer />
      <CoworkerTradeShiftModalContainer />
      <ApprovalTradeShiftModalContainer />
      <ShowConflictsModal />
      <ImportOpenShiftModalContainer />
      <SaveScheduleAsTemplateModal />
      <ManagerNoteModal />
      <AbsenceProposalModal />
      <EditAvailabilityModalContainer />
      <AddAvailabilityModal />
      <QuickPlanningWarningModal />
      <ShortcutsModal />
      <EmployeeModal />
      <IncomingLoanEmployeesProposalModal />
      <OutgoingLoanEmployeesProposalModal />
      <DeleteScheduleItemsModal />
    </>
  );
});

ScheduleModals.displayName = 'ScheduleModals';
