import { DateModeEnum } from '@/types/mainDateStore';

import { RootState } from '../types';

export const selectDateArray = (state: RootState) => state.reducer.mainDateStore.dateArray;

export const selectIsDayDateMode = (state: RootState) =>
  state.reducer.mainDateStore.dateMode === DateModeEnum.DAY || state.reducer.mainDateStore.dateArray.length === 1;

export const selectTodayFromMainDateStore = (state: RootState) => state.reducer.mainDateStore.today;
