import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectIsWeatherForecastShown } from '@/redux-store/schedule/viewSettings';
import { selectScheduleLocationFilter } from '@/redux-store/scheduleLocationFilter';
import { getWeatherForecastIfNecessary } from '@/redux-store/weatherForecast';

export const useFetchWeather = () => {
  const scheduleLocationFilter = useAppSelector(selectScheduleLocationFilter);
  const isWeatherShown = useAppSelector(selectIsWeatherForecastShown);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isWeatherShown) dispatch(getWeatherForecastIfNecessary(scheduleLocationFilter));
  }, [dispatch, scheduleLocationFilter, isWeatherShown]);
};
