import moment from 'moment';

import * as actionTypes from '@/constants/ActionTypes.js';
import { kilometers2meters, meters2kilometers } from '@/utils/unitsHelpers.js';

import { logoutUser } from './auth.jsx';
import { conn, connectionError, showConfirmModal } from './index';
import { LocationSettingsChangeMassDescription, messages } from './settings.messages';
import { changeBonusSystemSettings, changeBonusSystemSettingsMass } from './spmhBasedBonuses.js';
import { setProductionQuotasPermission } from './userPermissions.js';

export const getLocationSettingsSuccessful = settings => ({
  type: actionTypes.GET_LOCATION_SETTINGS_SUCCESSFUL,
  payload: settings,
});

export const setCompanySettingsSuccesful = settings => ({
  type: actionTypes.SET_COMPANY_SETTINGS_SUCCESSFUL,
  payload: settings,
});

export const getUsersSettingsSuccesful = settings => ({
  type: actionTypes.GET_USERS_SETTINGS_SUCCESFUL,
  payload: settings,
});

export const setUserSettingsSuccesful = (userId, name, data) => ({
  type: actionTypes.SET_USERS_SETTINGS_SUCCESFUL,
  payload: { userId, name, data },
});

export const getLocationSettings = locationId => dispatch =>
  new Promise(resolve => {
    conn
      .getLocationSettings(locationId)
      .then(response => {
        dispatch(getLocationSettingsSuccessful({ settings: response.data.settings, id: locationId }));
        resolve();
      })
      .catch(err => {
        throw err;
      });
  });

export const getMultipleLocationSettings = (locations, requestType) => dispatch => {
  conn
    .getMultipleLocationSettings(
      locations.map(location => location.id),
      requestType,
    )
    .then(response => {
      const allSettings = response.data.map(result => {
        dispatch(getLocationSettingsSuccessful({ settings: result.settings, id: result.location.id }));
        return result.settings;
      });
      dispatch(setProductionQuotasPermission(allSettings));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const changeLocationSettingsSuccessful = settings => ({
  type: actionTypes.CHANGE_LOCATION_SETTINGS_SUCCESSFUL,
  payload: settings,
});

const changeMassLocationsSettingsSuccessful = settings => ({
  type: actionTypes.CHANGE_MASS_LOCATIONS_SETTINGS_SUCCESSFUL,
  payload: settings,
});

export const changeLocationSettings = (locationId, settingsObject) => (dispatch, getState) =>
  new Promise(resolve => {
    conn
      .changeLocationSettings(locationId, settingsObject)
      .then(() => {
        dispatch(changeBonusSystemSettings(settingsObject.settings, locationId));
        dispatch(changeLocationSettingsSuccessful({ settings: settingsObject.settings, id: locationId }));
        dispatch(setProductionQuotasPermission(Object.values(getState().reducer.settings.locationSettings)));
        resolve();
      })
      .catch(err => {
        throw err;
      });
  });

const createNewSettingsForAllLocations = (locationSettings, locationIds, settingsObject, selectedLocationId) =>
  locationIds.map(locId => {
    if (locId === selectedLocationId)
      return {
        id: locId,
        settings: {
          ...settingsObject,
          attendance_location_radius_limit: settingsObject.attendance_location_radius_limit,
        },
      };
    const oldLocationSetting = locationSettings[locId];
    return {
      id: locId,
      settings: {
        ...settingsObject,
        attendance_location_radius_limit: meters2kilometers(oldLocationSetting.attendance_location_radius_limit),
        enable_localization_check: oldLocationSetting.enable_localization_check,
        location_coordinates: oldLocationSetting.location_coordinates,
        rcp_requires_geolocation: oldLocationSetting.rcp_requires_geolocation,
      },
    };
  });

const getLocationsSettingsForRedux = locationsSettingsToSend =>
  locationsSettingsToSend.reduce(
    (prev, locationData) => ({
      ...prev,
      [locationData.id]: {
        ...locationData.settings,
        attendance_location_radius_limit: kilometers2meters(locationData.settings.attendance_location_radius_limit),
      },
    }),
    {},
  );

export const changeMassLocationsSettings = (settingsObject, selectedLocationId) => (dispatch, getState, intl) => {
  const { userLocations, settings } = getState().reducer;
  const locationIds = userLocations.map(loc => loc.id);
  const locationsSettingsToSend = createNewSettingsForAllLocations(
    settings.locationSettings,
    locationIds,
    settingsObject,
    selectedLocationId,
  );
  const title = intl.formatMessage(messages.locationSettingsChangeMassTitle);
  const description = <LocationSettingsChangeMassDescription />;
  const confirmText = intl.formatMessage(messages.locationSettingsChangeMassConfirmText);
  const actionText = intl.formatMessage(messages.locationSettingsChangeMassActionText);
  const confirmFunc = () => {
    conn
      .changeLocationsSettingsMass({ settings: locationsSettingsToSend })
      .then(() => {
        dispatch(changeBonusSystemSettingsMass(settingsObject));
        const newLocationsSettings = getLocationsSettingsForRedux(locationsSettingsToSend);
        dispatch(changeMassLocationsSettingsSuccessful(newLocationsSettings));
      })
      .catch(err => {
        console.error('error', err);
      });
  };
  const deletedThing = intl.formatMessage(messages.locationSettingsChangeMassTitle);
  dispatch(
    showConfirmModal({
      title,
      description,
      actionText,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

export const showPasswordRefreshConfirmModal = (passwordInterval, passwordChangedAt) => (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.passwordRefreshModalTitle, {}),
      description: intl.formatMessage(messages.passwordRefreshModalDescription, {}),
      confirmText: intl.formatMessage(messages.save, {}),
      confirmFunc: () => {
        dispatch(setCompanySettings({ settings: { password_change_interval: passwordInterval } }));
        if (moment().diff(moment(passwordChangedAt), 'days') >= passwordInterval) {
          console.log('handling logout 8');
          dispatch(logoutUser());
        }
      },
    }),
  );
};

const changePasswordSuccess = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.CHANGE_PASSWORD_SUCCESSFUL,
    notification: {
      title: intl.formatMessage(messages.changePasswordSuccess),
      type: 'success',
    },
  });
};

const changePasswordError = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.CHANGE_PASSWORD_ERROR,
    notification: {
      title: intl.formatMessage(messages.changePasswordError),
      type: 'error',
    },
  });
};

export const changePassword = (oldPassword, newPassword) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .changePassword(oldPassword, newPassword)
      .then(() => {
        dispatch(changePasswordSuccess());
        resolve();
      })
      .catch(err => {
        dispatch(changePasswordError());
        reject(err);
      });
  });

export const setCompanySettings = settings => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .setCompanySettings(settings)
      .then(response => {
        dispatch(setCompanySettingsSuccesful(response.data.settings));
        resolve();
      })
      .catch(err => {
        dispatch(connectionError(err));
        reject(err);
      });
  });

export const getNotificationSettings = () => (dispatch, getState) => {
  const currentUser = getState().reducer.currentUser.user;
  conn
    .getNotificationSettings()
    .then(response => {
      const { data } = response;
      dispatch(getUsersSettingsSuccesful({ [currentUser.id]: { notifications: data } }));
    })
    .catch(err => {
      console.error(err);
    });
};

export const setNotificationSettings = settingValues => (dispatch, getState) => {
  const currentUser = getState().reducer.currentUser.user;
  const currentSetting = getState().reducer.settings.usersSettings;
  const notifications = currentSetting[currentUser.id]?.notifications || [];

  conn
    .setNotificationSettings(settingValues)
    .then(response => {
      const { data } = response;
      const newSettings = [...data, ...notifications].reduce(
        (acc, cur) => (acc.some(sett => sett.notification_name === cur.notification_name) ? acc : [...acc, cur]),
        [],
      );
      dispatch(setUserSettingsSuccesful(currentUser.id, 'notifications', newSettings));
    })
    .catch(err => {
      console.error(err);
    });
};

export const toggleAttendanceSettings = setting => ({
  type: actionTypes.TOGGLE_ATTENDANCE_SETTINGS,
  payload: setting,
});

export const sellectAllAttendancesSettings = () => ({
  type: actionTypes.SELECT_ALL_ATENDANCES_SETTINGS,
});

export const desellectAllAttendancesSettings = () => ({
  type: actionTypes.DESELLECT_ALL_ATTENDNACES_SETTINGS,
});
