import { conn } from '@/actions';
import { CurrentUser, Location } from '@/types';

import { createActionName, createAppAsyncThunk } from '../utils';
import { sliceName } from './weatherForecast.slice';
import { WeatherForecastDto, WeatherForecastStoreState } from './weatherForecast.types';
import { weatherForecastToStore } from './weatherForecast.utils';

export const getWeatherForecast = createAppAsyncThunk(
  createActionName(sliceName, 'getWeatherForecast'),
  async ({
    locationsIds,
    language,
  }: {
    locationsIds: Location['id'][];
    language: CurrentUser['user']['settings']['language'];
  }): Promise<WeatherForecastStoreState> => {
    const response = await conn.getWeatherForecast(locationsIds, language);
    const weatherForecast = response.data as WeatherForecastDto;
    return weatherForecastToStore(weatherForecast);
  },
);
