import { combineReducers } from 'redux';

import { buttonsStateReducer } from '@/redux-store/schedule/buttonsState/buttonsState.slice';

import availabilityBlocks from './availabilityBlocks';
import budget from './budget';
import dayTypes from './dayTypes';
import dragAndDrop from './dragAndDrop';
import openShifts from './openShifts';
import openShiftsRowSelect from './openShiftsRowSelect';
import quickPlanning from './quickPlanning';
import rowStatistics from './rowStatistics';
import scheduleCharts from './scheduleCharts';
import selectedCells from './selectedCells';
import stickyRows from './stickyRows';
import tradeShiftsRowSelect from './tradeShiftsRowSelect';
import viewMode from './viewMode';
import viewSettings from './viewSettings';

export default combineReducers({
  availabilityBlocks,
  budget,
  buttonsState: buttonsStateReducer,
  dayTypes,
  dragAndDrop,
  openShifts,
  openShiftsRowSelect,
  quickPlanning,
  rowStatistics,
  scheduleCharts,
  selectedCells,
  stickyRows,
  viewMode,
  viewSettings,
  tradeShiftsRowSelect,
});
