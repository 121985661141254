import PropTypes from 'prop-types';
import { useCallback } from 'react';

import DropArea from '../DropArea/DropArea';

const OpenShiftDropArea = ({ enabled, draggedShiftToOpenShift, date, locationId }) => {
  const onDrop = useCallback(() => {
    draggedShiftToOpenShift(date, locationId);
  }, [draggedShiftToOpenShift, date, locationId]);

  return <DropArea enabled={enabled} onDrop={onDrop} />;
};

OpenShiftDropArea.propTypes = {
  enabled: PropTypes.bool,
};
export default OpenShiftDropArea;
