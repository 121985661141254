import classnames from 'classnames';
import { memo, useCallback, useMemo } from 'react';

import { showModal } from '@/actions/uiState';
import { ADD_EVENT_MODAL } from '@/constants/modalTypes';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectUserEventIdsForDate } from '@/redux-store/events';
import { selectScheduleLocationFilter } from '@/redux-store/scheduleLocationFilter';
import { selectIsLoggedUserEmployee } from '@/redux-store/userPermissions';
import { StandardDate } from '@/types/dates.types';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { ScheduleEvent } from '../../ScheduleEvent/ScheduleEvent';
import ScheduleTableItemBackground from '../../ScheduleTableItemBackground/ScheduleTableItemBackground.redux';
import { getEvents } from './EventsTableCell.utils';

type Props = {
  date: StandardDate;
};

export const EventsTableCell = memo(({ date }: Props) => {
  const dispatch = useAppDispatch();
  const locationIds = useAppSelector(selectScheduleLocationFilter);
  const isEmployee = useAppSelector(selectIsLoggedUserEmployee);
  const eventIds = useAppSelector(selectUserEventIdsForDate(date));

  const eventsForSelectedLocations = useMemo(() => getEvents(locationIds, eventIds), [locationIds, eventIds]);

  const empty = isEmptyArray(eventsForSelectedLocations) && !isEmployee;
  const tableItemClassName = classnames('scheduleTable__item', { empty });

  const onClick = useCallback(() => {
    if (empty) dispatch(showModal(ADD_EVENT_MODAL, { date }));
  }, [empty, dispatch, date]);

  return (
    <td className={tableItemClassName} role="presentation" onClick={onClick}>
      <ScheduleTableItemBackground date={date} />
      {!isEmployee && <span className="material-icons-outlined scheduleTable__item__add">add</span>}
      {eventsForSelectedLocations.map(eventId => (
        <ScheduleEvent {...{ eventId }} key={eventId} />
      ))}
    </td>
  );
});

EventsTableCell.displayName = 'EventsTableCell';
