import { IntlShape } from 'react-intl';

import { showConfirmModal } from '@/actions';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getFromToFromDateStore } from '@/utils/dateHelper';

import { selectDateArray } from '../mainDateStore';
import { selectScheduleLocationFilter } from '../scheduleLocationFilter';
import { AppDispatch, GetStateType } from '../types';
import { deleteUserEvent, getManyEvents } from './userEvents.asyncActions';
import { selectLastFetchedData } from './userEvents.selectors';
import { setLastFetchedData } from './userEvents.slice';
import { UserEvent } from './userEvents.types';
import { checkIfShouldNotRefetchUserEvents, messages } from './userEvents.utils';

export const getUserEventsForSchedule = () => (dispatch: AppDispatch, getState: GetStateType) => {
  const state = getState();
  const locationsIds = selectScheduleLocationFilter(state);
  const lastFetchedData = selectLastFetchedData(state);
  const [from, to] = getFromToFromDateStore({ dateArray: selectDateArray(state) });

  if (isEmptyArray(locationsIds) || checkIfShouldNotRefetchUserEvents(lastFetchedData, from, to, locationsIds)) return;
  dispatch(getManyEvents({ from, to, locationsIds }));
  dispatch(setLastFetchedData({ from, to, locationsIds }));
};

export const showDeleteUserEventConfirmModal =
  (event: UserEvent) => (dispatch: AppDispatch, _getState: GetStateType, intl: IntlShape) => {
    dispatch(
      showConfirmModal({
        title: intl.formatMessage(messages.deleteUserEventTitle),
        description: intl.formatMessage(messages.deleteUserEventDescription),
        confirmFunc: () => dispatch(deleteUserEvent(event)),
      }),
    );
  };
