import moment from 'moment';

import { changeShiftsSummary } from '@/actions/schedule/budget.js';
import * as AT from '@/constants/ActionTypes.js';
import { getRelevantContractForDate } from '@/utils/contracts';

import {
  formatFlatShiftsToSummary,
  getNewSummary,
  getShiftsWithWage,
  getSingleShiftWithWage,
  updateShiftsHoursWithAbsences,
} from './shiftsSummary.helpers.js';

const shiftsSummaryMiddleware = store => next => action => {
  const {
    userEmployees,
    scheduleState,
    scheduleLocationFilter,
    schedule: {
      budget: { shiftsSummary },
    },
    mainDateStore: { dateArray },
    employees,
    absences,
    contracts,
    userJobTitles,
    scheduleLoanedEmployees,
    shifts,
    scheduleUIState,
  } = store.getState().reducer;
  const selectedLocationIds = scheduleLocationFilter;
  const absencesPerEmployee = { ...absences.scheduleAbsences };
  switch (action.type) {
    case AT.UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST: {
      const showLoanedEmployees = scheduleUIState.settings.find(s => s.type === 'loanedEmployees')?.value;
      const result = getNewSummary(
        action.payload,
        userEmployees,
        selectedLocationIds,
        dateArray,
        absencesPerEmployee,
        contracts,
        userJobTitles,
        scheduleLoanedEmployees,
        shifts,
        showLoanedEmployees,
      );
      store.dispatch(changeShiftsSummary(result));
      break;
    }
    case AT.GET_SCHEDULE_SUCCESFUL: {
      const showLoanedEmployees = scheduleUIState.settings.find(s => s.type === 'loanedEmployees')?.value;
      const result = getNewSummary(
        scheduleState.locations,
        action.payload,
        selectedLocationIds,
        dateArray,
        absencesPerEmployee,
        contracts,
        userJobTitles,
        scheduleLoanedEmployees,
        shifts,
        showLoanedEmployees,
      );
      store.dispatch(changeShiftsSummary(result));
      break;
    }
    case AT.ADD_SHIFT_SUCCESFUL: {
      const { new_shift: shift } = action.payload;
      const shiftDuration = moment(shift.end_timestamp).diff(shift.start_timestamp, 'minutes');
      const employee = userEmployees.find(e => e.id === shift.employee.id);
      const employeeContracts = contracts[employee.id];
      const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
      const relevantContractJobTitle = relevantContract?.job_titles.find(
        j => j.job_title_id === String(shift.job_title.id),
      );
      const wage =
        relevantContractJobTitle.wage || relevantContractJobTitle.wage === 0
          ? relevantContractJobTitle.wage
          : userJobTitles.find(j => j.id === shift.job_title.id)?.hourly_wage || 0;

      const [updatedShift] = updateShiftsHoursWithAbsences(
        [
          {
            ...shift,
            duration: shiftDuration,
            wage,
          },
        ],
        absencesPerEmployee,
      );

      if (!updatedShift) break;

      const shiftCost = (updatedShift.duration / 60) * updatedShift.wage;

      const newState = {
        [shift.date]: {
          totalMinutes: (shiftsSummary[shift.date]?.totalMinutes || 0) + updatedShift.duration,
          totalCosts: (shiftsSummary[shift.date]?.totalCosts || 0) + shiftCost,
          employees: (shiftsSummary[shift.date]?.employees || []).includes(employee.id)
            ? shiftsSummary[shift.date].employees
            : [...(shiftsSummary[shift.date]?.employees || []), employee.id],
        },
      };
      store.dispatch(changeShiftsSummary(newState));

      break;
    }
    case AT.DELETE_SHIFT: {
      const { shift, isLoanedEmployee } = action.payload;
      const shiftDuration = moment(shift.end_timestamp).diff(shift.start_timestamp, 'minutes');
      const employee = userEmployees.find(e => e.id === shift.employee.id);

      let updatedShift;
      let shiftCost = 0;
      const employeeShifts = [];
      const employees = [];
      if (!isLoanedEmployee) {
        const employeeContracts = contracts[employee.id];
        const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
        const relevantContractJobTitle = relevantContract?.job_titles.find(
          j => j.job_title_id === String(shift.job_title.id),
        );
        const wage =
          relevantContractJobTitle.wage || relevantContractJobTitle.wage === 0
            ? relevantContractJobTitle.wage
            : userJobTitles.find(j => j.id === shift.job_title.id)?.hourly_wage || 0;

        [updatedShift] = updateShiftsHoursWithAbsences(
          [
            {
              ...shift,
              duration: shiftDuration,
              wage,
            },
          ],
          absencesPerEmployee,
        );

        shiftCost = ((updatedShift?.duration ?? shiftDuration) / 60) * wage;
        const relevantShifts = employee.shifts.filter(s => s.id !== shift.id && s.date === shift.date);
        employeeShifts.push(relevantShifts);

        const relevantEmployees =
          employeeShifts.length === 0
            ? shiftsSummary[shift.date]?.employees.filter(id => id !== employee.id)
            : shiftsSummary[shift.date]?.employees;

        employees.push(relevantEmployees);
      } else updatedShift = shift;

      if (!updatedShift) break;

      const newState = {
        [shift.date]: {
          totalMinutes: shiftsSummary[shift.date]?.totalMinutes - updatedShift.duration,
          totalCosts: shiftsSummary[shift.date]?.totalCosts - shiftCost,
          employees,
        },
      };
      store.dispatch(changeShiftsSummary(newState));

      break;
    }
    case AT.EDIT_SHIFT_SUCCESFUL: {
      const shift = action.payload.newShiftObject;
      const shiftDuration = moment(shift.end_timestamp).diff(shift.start_timestamp, 'minutes');
      const employee = userEmployees.find(e => e.id === shift.employee.id);
      const employeeContracts = contracts[employee.id];
      const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
      const relevantContractJobTitle = relevantContract?.job_titles.find(
        j => j.job_title_id === String(shift.job_title.id),
      );
      const wage =
        relevantContractJobTitle.wage || relevantContractJobTitle.wage === 0
          ? relevantContractJobTitle.wage
          : userJobTitles.find(j => j.id === shift.job_title.id)?.hourly_wage || 0;

      const oldShift = employee.shifts.find(s => s.id === shift.id);
      const oldRelevantContract = getRelevantContractForDate(employeeContracts, oldShift.date);
      const oldRelevantContractJobTitle = oldRelevantContract?.job_titles.find(
        j => j.job_title_id === String(shift.job_title.id),
      );
      const oldWage =
        oldRelevantContractJobTitle.wage || oldRelevantContractJobTitle.wage === 0
          ? oldRelevantContractJobTitle.wage
          : userJobTitles.find(j => j.id === shift.job_title.id)?.hourly_wage || 0;
      const oldShiftDuration = moment(oldShift.end_timestamp).diff(oldShift.start_timestamp, 'minutes');

      const updatedShifts = updateShiftsHoursWithAbsences(
        [
          {
            ...shift,
            duration: shiftDuration,
            id: 'newShift',
          },
          {
            ...oldShift,
            duration: oldShiftDuration,
            id: 'oldShift',
          },
        ],
        absencesPerEmployee,
      );

      const updatedNewShift = updatedShifts.find(s => s.id === 'newShift') || {
        ...shift,
        duration: 0,
      };
      const updatedOldShift = updatedShifts.find(s => s.id === 'oldShift') || {
        ...oldShift,
        duration: 0,
      };

      const shiftCost = (updatedNewShift.duration / 60) * wage;
      const oldCost = (updatedOldShift.duration / 60) * oldWage;

      const newState = {
        [shift.date]: {
          totalMinutes:
            (shiftsSummary[shift.date]?.totalMinutes || 0) + (updatedNewShift.duration - updatedOldShift.duration),
          totalCosts: (shiftsSummary[shift.date]?.totalCosts || 0) + (shiftCost - oldCost),
          employees: shiftsSummary[shift.date]?.employees,
        },
      };
      store.dispatch(changeShiftsSummary(newState));

      break;
    }
    case AT.DUPLICATE_PREVIOUS_VIEW_SUCCESS: {
      const shiftsWithWage = getShiftsWithWage(action.payload, contracts, userJobTitles);
      const showLoanedEmployees = scheduleUIState.settings.find(s => s.type === 'loanedEmployees')?.value;
      const newState = formatFlatShiftsToSummary(
        shiftsWithWage,
        action.payload[0].location.id,
        dateArray,
        absencesPerEmployee,
        scheduleLoanedEmployees,
        shifts,
        showLoanedEmployees,
        userJobTitles,
      );
      store.dispatch(changeShiftsSummary(newState));
      break;
    }
    case AT.ADD_SHIFTS_SUCCESFUL: {
      const shiftsWithWage = getShiftsWithWage(action.newShifts, contracts, userJobTitles);
      const showLoanedEmployees = scheduleUIState.settings.find(s => s.type === 'loanedEmployees')?.value;
      const newState = formatFlatShiftsToSummary(
        shiftsWithWage,
        action.newShifts[0].location.id,
        dateArray,
        absencesPerEmployee,
        scheduleLoanedEmployees,
        shifts,
        showLoanedEmployees,
        userJobTitles,
      );
      store.dispatch(changeShiftsSummary(newState));
      break;
    }
    case AT.ADD_MASS_SHIFTS_SUCCESFUL: {
      const flatShifts = userEmployees.flatMap(employee => employee.shifts);
      const showLoanedEmployees = scheduleUIState.settings.find(s => s.type === 'loanedEmployees')?.value;
      const newState = formatFlatShiftsToSummary(
        getShiftsWithWage([...flatShifts, ...action.payload], contracts, userJobTitles),
        action.payload[0].location.id,
        dateArray,
        absencesPerEmployee,
        scheduleLoanedEmployees,
        shifts,
        showLoanedEmployees,
        userJobTitles,
      );
      store.dispatch(changeShiftsSummary(newState));
      break;
    }
    case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL: {
      const newEmployees = store.getState().reducer.userEmployees;

      const { shiftIds, dates } = Object.values(action.payload).reduce(
        (result, employeeItems) => {
          const shiftIds = employeeItems.map(i => i.shiftId);
          const dates = employeeItems.map(i => i.date);

          return {
            shiftIds: [...result.shiftIds, ...shiftIds],
            dates: [...result.dates, ...dates],
          };
        },
        { dates: [], shiftIds: [] },
      );
      const uniqueDates = Array.from(new Set(dates));
      const flatShifts = newEmployees.flatMap(employee =>
        employee.shifts.map(s => getSingleShiftWithWage(s, contracts[employee.id] || [], userJobTitles)),
      );
      const deletedShifts = userEmployees
        .flatMap(employee => employee.shifts)
        .filter(shift => shiftIds.includes(shift.id));

      const deletedShiftsLocationId = deletedShifts.length ? deletedShifts[0]?.location?.id : [];
      const showLoanedEmployees = scheduleUIState.settings.find(s => s.type === 'loanedEmployees')?.value;
      const newState = formatFlatShiftsToSummary(
        flatShifts,
        deletedShiftsLocationId,
        dateArray,
        absencesPerEmployee,
        scheduleLoanedEmployees,
        shifts,
        showLoanedEmployees,
        userJobTitles,
      );
      const emptyDays = uniqueDates.filter(date => !flatShifts.some(shift => shift.date === date));
      const newStateForEmptyDays = emptyDays.reduce(
        (result, date) => ({
          [date]: {
            totalMinutes: 0,
            totalCosts: 0,
            employees: [],
          },
        }),
        {},
      );

      store.dispatch(changeShiftsSummary({ ...newState, ...newStateForEmptyDays }));
      break;
    }
    default:
      break;
  }
  const stateAfter = next(action);
  postActionMiddleware(action, store);

  return stateAfter;
};

const postActionMiddleware = (action, store) => {
  const {
    userEmployees,
    scheduleState,
    scheduleLocationFilter,
    mainDateStore: { dateArray },
    absences,
    contracts,
    userJobTitles,
    scheduleLoanedEmployees,
    shifts,
    scheduleUIState,
  } = store.getState().reducer;
  const selectedLocationIds = scheduleLocationFilter;
  const absencesPerEmployee = { ...absences.scheduleAbsences };

  switch (action.type) {
    case AT.ADD_ABSENCE_SUCCESS:
    case AT.GET_ABSENCES_FOR_SCHEDULE:
    case AT.DELETE_ABSENCE_SUCCESS: {
      const showLoanedEmployees = scheduleUIState.settings.find(s => s.type === 'loanedEmployees')?.value;
      const result = getNewSummary(
        scheduleState.locations,
        userEmployees,
        selectedLocationIds,
        dateArray,
        absencesPerEmployee,
        contracts,
        userJobTitles,
        scheduleLoanedEmployees,
        shifts,
        showLoanedEmployees,
      );
      store.dispatch(changeShiftsSummary(result));
      break;
    }
    default:
      break;
  }
};

export default shiftsSummaryMiddleware;
