import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { SCHEDULE_NEW_SHIFT } from '@/constants/colors';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { calculateDurationBetweenTimestamps } from '@/utils/dateHelper';

import ScheduleSingleDayShiftBlock from '../ScheduleSingleDayShiftBlock/ScheduleSingleDayShiftBlock';
import { getBlockEnd, getBlockHours, getPropertiesForDrawing } from './ScheduleSingleDayNewShiftLayer.helpers';

const ScheduleSingleDayNewShiftLayer = ({
  date,
  restrictions,
  onAdd,
  isAnyShiftDragged,
  isAvaDragged,
  isAnyOpenShiftDragged,
}) => {
  const isEditingScheduleDisabled = restrictions.includes(SCHEDULE_EDIT_DISABLE);
  const addLayerRef = useRef(null);
  const [blockStart, setBlockStart] = useState(null);
  const [blockEnd, setBlockEnd] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [containerWidth, setContainerWidth] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMouseMove = e => {
    if (!drawing) return;
    e.preventDefault();
    const end = getBlockEnd(e.clientX, offsetLeft, containerWidth);
    if (blockStart < end) setBlockEnd(end);
    else setBlockStart(end);
  };

  const handleMouseUp = async e => {
    const { newOffsetLeft, newContainerWidth } = getPropertiesForDrawing(e.clientX, addLayerRef);
    const { startTime, endTime } = getBlockHours(blockStart, getBlockEnd(e.clientX, newOffsetLeft, newContainerWidth));
    const duration = calculateDurationBetweenTimestamps(`2018-04-01 ${endTime}`, `2018-04-01 ${startTime}`);
    try {
      setLoading(true);
      await onAdd(duration, startTime, endTime);
    } finally {
      setLoading(false);
      setDrawing(false);
      setBlockStart(null);
      setBlockEnd(null);
    }
  };

  const removeListeners = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (drawing && !loading) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      removeListeners();
    }
    return () => {
      removeListeners();
    };
  }, [drawing, loading]);

  const isAnyOpenShiftOrShiftDragged = isAnyShiftDragged || isAnyOpenShiftDragged;

  const layerClassname = classnames('k-singleDayRow__addLayer', {
    'k-singleDayRow__addLayer--disabled': isEditingScheduleDisabled,
    'k-singleDayRow__addLayer--overDndLayer': !isAnyOpenShiftOrShiftDragged,
    'k-singleDayRow__addLayer--whenAvaDragged': isAvaDragged,
  });

  const startDrawing = e => {
    if (drawing || isEditingScheduleDisabled) return;
    const { newOffsetLeft, newContainerWidth, start } = getPropertiesForDrawing(e.clientX, addLayerRef);
    setOffsetLeft(newOffsetLeft);
    setContainerWidth(newContainerWidth);
    setBlockStart(start);
    setBlockEnd(start);
    setDrawing(true);
  };

  return (
    <>
      <div role="presentation" ref={addLayerRef} className={layerClassname} onMouseDown={startDrawing} />
      {blockStart && (
        <ScheduleSingleDayShiftBlock
          title=""
          from={loading ? '' : blockStart}
          date={date}
          to={loading ? '' : blockEnd}
          draft
          color={SCHEDULE_NEW_SHIFT}
          fullOpacity
        />
      )}
    </>
  );
};

ScheduleSingleDayNewShiftLayer.propTypes = {
  date: PropTypes.string,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  isAnyShiftDragged: PropTypes.bool,
  isAnyOpenShiftDragged: PropTypes.bool,
  isAvaDragged: PropTypes.bool,
  onAdd: PropTypes.func,
};

export default ScheduleSingleDayNewShiftLayer;
