import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import SidebarAbsenceContent from './SidebarAbsenceContent/SidebarAbsenceContent';
import SidebarShiftContent from './SidebarShiftContent/SidebarShiftContent.redux';

export const Sidebar = props => {
  const {
    mainDateStore,
    modalObject,
    absenceLimitsForEmployee,
    employeeShifts,
    activeTab,
    working_hours: workingHours,
    selectedJobTitle,
    selectedAbsence,
    date,
  } = props;

  const relevantShifts = useMemo(
    () => (employeeShifts ? Object.values(employeeShifts).filter(s => mainDateStore.dateArray.includes(s.date)) : []),
    [employeeShifts, mainDateStore],
  );
  const relevantAvailabilities = useMemo(
    () =>
      modalObject?.employee?.availability_blocks
        ? Object.values(modalObject.employee.availability_blocks).filter(s => mainDateStore.dateArray.includes(s.date))
        : [],
    [modalObject, mainDateStore],
  );
  const absenceLimits = useMemo(
    () => absenceLimitsForEmployee.filter(absenceLimit => absenceLimit.absence_type_id === selectedAbsence.id),
    [selectedAbsence?.id, absenceLimitsForEmployee],
  );

  switch (activeTab) {
    case 0:
      return (
        <SidebarShiftContent
          employee={modalObject?.employee}
          workingHours={workingHours}
          jobTitle={selectedJobTitle}
          shifts={relevantShifts}
          availabilities={relevantAvailabilities}
          date={date}
          isLoanedEmployee={modalObject?.isLoanedEmployee}
        />
      );
    case 1:
      return <SidebarAbsenceContent absenceType={selectedAbsence} absenceLimits={absenceLimits} />;
    default:
      return null;
  }
};

Sidebar.propTypes = {
  absenceLimitsForEmployee: PropTypes.arrayOf(PropTypes.shape({})),
  activeTab: PropTypes.number,
  working_hours: PropTypes.string,
  selectedJobTitle: PropTypes.string,
  selectedAbsence: PropTypes.shape({ id: PropTypes.string }),
  employeeShifts: PropTypes.shape({}),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  modalObject: PropTypes.shape({
    employee: PropTypes.shape({
      availability_blocks: PropTypes.shape({}),
    }),
    isLoanedEmployee: PropTypes.bool,
  }),
};

export default memo(Sidebar);
