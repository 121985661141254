import Popover from '@/components/common/Popover/Popover';
import { DIRECTIONS } from '@/constants/ui.ts';
import { useAppSelector } from '@/redux-store/hooks';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore/mainDateStore.selectors';
import { selectHasLocationCoordinates } from '@/redux-store/settings/locationSettings/locationSettings.selectors';
import { Location } from '@/types';
import { StandardDate } from '@/types/dates.types';

import { ScheduleTableWeatherPopoverContent } from './ScheduleTableWeatherPopoverContent/ScheduleTableWeatherPopoverContent';

type Props = {
  children: React.ReactNode;
  date: StandardDate;
  locationId: Location['id'];
};

export const ScheduleTableWeatherPopover = ({ children, locationId, date }: Props) => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const locationCoordinatesExist = useAppSelector(selectHasLocationCoordinates(locationId));

  if (isSingleDay && locationCoordinatesExist) return children;

  return (
    <Popover
      showOnHover
      preventPopoverHide
      position={DIRECTIONS.TOP}
      showShadow={false}
      content={
        <ScheduleTableWeatherPopoverContent
          date={date}
          locationCoordinatesExist={locationCoordinatesExist}
          locationId={locationId}
        />
      }
      hideTimeout={0}
      yOffset={20}
      useParentDimensions
    >
      <div>{children}</div>
    </Popover>
  );
};
