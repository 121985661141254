import { WritableDraft } from 'immer';
import { defineMessages } from 'react-intl';

import { Location } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { difference, isEmptyArray } from '@/utils/array/array.helpers';

import { UserEvent, UserEventsStoreState } from './userEvents.types';

export const sliceName = 'userEvents';

export const messages = defineMessages({
  deleteUserEventTitle: {
    id: 'userEvent.deleteUserEventTitle',
    defaultMessage: 'Usuń wydarzenie',
  },
  deleteUserEventDescription: {
    id: 'userEvent.deleteUserEventDescription',
    defaultMessage: 'Czy na pewno chcesz usunąc to wydarzenie',
  },
});

export const checkIfShouldNotRefetchUserEvents = (
  lastFetchedData: UserEventsStoreState['lastFetchedData'],
  from: StandardDate,
  to: StandardDate,
  locationsIds: Location['id'][],
) =>
  lastFetchedData.from === from &&
  lastFetchedData.to === to &&
  lastFetchedData.locationsIds &&
  isEmptyArray(difference(locationsIds, lastFetchedData.locationsIds));

export const getUserEvents = (userEvents: UserEvent[], state: WritableDraft<UserEventsStoreState>) => {
  userEvents.forEach(event => {
    state.data[event.id] = event;
    const { date, id, location } = event;
    if (!state.structure[date]) state.structure[date] = {};
    if (!state.structure[date][location.id]) state.structure[date][location.id] = [];
    if (!state.structure[date][location.id].includes(id)) state.structure[date][location.id].push(id);
  });
  sortStructure(state);
};

export const createEvent = (userEvent: UserEvent, state: WritableDraft<UserEventsStoreState>) => {
  state.data[userEvent.id] = userEvent;
  const { date, id, location } = userEvent;
  if (!state.structure[date]) state.structure[date] = {};
  if (!state.structure[date][location.id]) state.structure[date][location.id] = [];
  state.structure[date][location.id].push(id);
};

export const deleteEvent = (userEvent: UserEvent, state: WritableDraft<UserEventsStoreState>) => {
  delete state.data[userEvent.id];
  state.structure[userEvent.date][userEvent.location.id] = state.structure[userEvent.date][
    userEvent.location.id
  ].filter(id => id !== userEvent.id);
};

export const updateEvent = (userEvent: UserEvent, state: WritableDraft<UserEventsStoreState>) => {
  const oldLocationId = state.data[userEvent.id].location.id;
  const newLocationId = userEvent.location.id;
  const { date } = userEvent;
  if (oldLocationId !== newLocationId) {
    state.structure[date][oldLocationId] = state.structure[date][oldLocationId].filter(id => id !== userEvent.id);
    if (!state.structure[date][newLocationId]) state.structure[date][newLocationId] = [];
    state.structure[date][newLocationId].push(userEvent.id);
  }
  state.data[userEvent.id] = userEvent;
  sortStructure(state);
};

export const sortStructure = (state: WritableDraft<UserEventsStoreState>) => {
  Object.keys(state.structure).forEach(date => {
    Object.keys(state.structure[date]).forEach(locationId => {
      state.structure[date][locationId] = state.structure[date][locationId].sort((eventIdA, eventIdB) => {
        const eventA = state.data[eventIdA];
        const eventB = state.data[eventIdB];
        return eventA.hours.localeCompare(eventB.hours);
      });
    });
  });
};
