import { connect } from 'react-redux';

import { addAvailability, changeAvailability, deleteAvailability } from '@/actions';
import { addEmpAva, deleteEmpAva, editEmpAva } from '@/actions/availability.js';
import { mainDateChangeMode, mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import { hideShadow, showShadow } from '@/actions/uiState.js';
import CalendarView from '@/components/common/CalendarView.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  mainDateStore: state.reducer.mainDateStore,
  userCustomTypes: state.reducer.userCustomTypes,
  singleEmployeeFilter: state.reducer.singleEmployeeFilter,
  uiState: state.reducer.uiState,
  currentCompany: state.reducer.currentCompany,
});
const mapDispatchToProps = {
  showShadow,
  hideShadow,
  mainDateMoveLeft,
  mainDateMoveRight,
  mainDateChangeMode,
  deleteEmpAva,
  addEmpAva,
  editEmpAva,
  addAvailability,
  changeAvailability,
  deleteAvailability,
};
const AvailabilityCalendarViewContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarView);

export default AvailabilityCalendarViewContainer;
