import PropTypes from 'prop-types';
import { Component } from 'react';
import TagManager from 'react-gtm-module';
import { FormattedMessage } from 'react-intl';

import ExportsWidget from '@/components/common/exports/ExportsWidget/ExportsWidget.redux.js';
import { INTERCOM_DISABLE } from '@/constants/Restrictions.js';
import NotificationPanelContainer from '@/containers/notifications/NotificationPanelContainer.jsx';
import CreateOpenShiftFromAutoGenerateModal from '@/containers/scheduler/CreateOpenShiftFromAutoGenerateModalContainer.js';
import { getCookie } from '@/utils/cookieHandlers.js';
import { getNowTimestamp } from '@/utils/dateHelper.js';
import { getIntercomData, intercomBoot, intercomUpdate } from '@/utils/intercomHelpers.js';
import { initSockets } from '@/utils/sockets';

import ErrorBoundary from './common/ErrorBoundary/ErrorBoundary.jsx';
import TranslatorHelper from './common/TranslatorHelper.jsx';
import { SideBar } from './SideBar/SideBar.tsx';

class AuthenticatedApp extends Component {
  componentDidMount() {
    TagManager.initialize({ gtmId: 'GTM-NBVJFJ3' });
    initSockets(this.handleTaskEvent);

    if (
      !['Chrome', 'Firefox', 'Safari'].includes(this.props.deviceInfo.userBrowser.name) &&
      !this.props.deviceInfo.isMobile
    ) {
      /* eslint-disable max-len */
      this.props.showNotificationBar(
        <FormattedMessage
          id="app.browserWarning"
          defaultMessage="Zauważyliśmy że twoja przeglądarka to {browser}, niestety nie wspieramy jej i podczas używania aplikacji mogą wystąpić błędy.\\n Zachęcamy do korzystania z Chrome, Firefox lub Safari w najnowszych wersjach."
          values={{
            browser: this.props.deviceInfo.userBrowser.name,
          }}
        />,
        true,
      );
      /* eslint-enable max-len */
    }
    if (this.props.deviceInfo.supportsNotifications && Notification.permission !== 'denied') {
      Notification.requestPermission();
    }

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = () => {
    const rememberedUserId = getCookie('kadroUserId');

    if (rememberedUserId) {
      return;
    }
    console.log('handling logout 10');

    this.props.logoutUser();
  };

  handleTaskEvent = data => {
    if (data.type === 'TASK_FINISHED') {
      const { id, payload, type } = data.data;

      switch (type) {
        case 'AUTO_SCHEDULER':
          this.props.finishAutoscheduler(payload.result, this.props.autoschedulerLocation);
          break;
        case 'REPORT_VIEW_DATA':
          this.props.getReportDataSuccess(payload.result);
          break;
        default:
          this.props.getExportSuccess({ id, link: payload.fileUrl, result: payload.result });
      }
    }
    if (data.type === 'TASK_FAILED') {
      const { id } = data.data;
      this.props.getExportFailed(id);
    }
  };

  render() {
    const { userPermissions, children, currentUser, currentCompany, isGhostLogin } = this.props;
    const intercomData = currentUser.user ? getIntercomData(currentUser, currentCompany, userPermissions.plan) : {};
    if (currentUser.newCompanyDetails) {
      const { purposes, industry, company_size: companySize } = this.props.currentUser.newCompanyDetails;

      if (industry) intercomData.company.company_industry_2 = industry;
      intercomData.company.company_purpose_2 = purposes.sort().join();
      intercomData.company.Company_created_at = getNowTimestamp();
      intercomData.company.company_size = companySize;
    }

    if (
      import.meta.env.VITE_NODE_ENV !== 'development' &&
      userPermissions.initialized &&
      !userPermissions.restrictions.includes(INTERCOM_DISABLE) &&
      !userPermissions.isEmployee &&
      !isGhostLogin
    ) {
      intercomBoot(intercomData);
      if (currentUser.onboardingOption) {
        intercomUpdate({
          customAttributes: { onboarding_option: currentUser.onboardingOption },
        });
      }
    }

    return (
      <div>
        <div className="k-app">
          <CreateOpenShiftFromAutoGenerateModal />
          <SideBar />
          <div className="k-app__wrapper">
            <div data-test="appWrapper">
              <ErrorBoundary>{children}</ErrorBoundary>
            </div>
          </div>
          <NotificationPanelContainer />
          {import.meta.env.VITE_ENABLE_TRANSLATION_HELPER ? (
            <TranslatorHelper updateIntlMessages={this.props.updateIntlMessages} />
          ) : null}
          <ExportsWidget />
        </div>
      </div>
    );
  }
}

AuthenticatedApp.propTypes = {
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      role: PropTypes.string,
      intercomHash: PropTypes.string,
    }),
    newCompanyDetails: PropTypes.shape({
      purposes: PropTypes.arrayOf(PropTypes.string),
      industry: PropTypes.string,
      company_size: PropTypes.string,
    }),
    onboardingOption: PropTypes.string,
    authToken: PropTypes.shape({
      auth_token: PropTypes.string,
    }),
  }),
  currentCompany: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    initialized: PropTypes.bool,
    isEmployee: PropTypes.bool,
  }),
  deviceInfo: PropTypes.shape({
    isMobile: PropTypes.bool,
    userBrowser: PropTypes.shape({
      name: PropTypes.string,
    }),
    supportsNotifications: PropTypes.bool,
  }),
  children: PropTypes.node,
  showNotificationBar: PropTypes.func,
  updateIntlMessages: PropTypes.func,
  getExportSuccess: PropTypes.func,
  getExportFailed: PropTypes.func,
  finishAutoscheduler: PropTypes.func,
  autoschedulerLocation: PropTypes.string,
};

export default AuthenticatedApp;
