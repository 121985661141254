import moment from 'moment';
import { v4 } from 'uuid';

import { EMPLOYMENT_CONDITIONS_DISABLE_DEFAULT } from '@/constants/Restrictions.js';
import { getInitialLimitsState } from '@/utils/absenceHelpers.js';

export const changeVariablesTypes = (name: string, value: any) => {
  switch (name) {
    case 'scheduleCycleDuration':
      return parseInt(value);
    default:
      return value;
  }
};

export const formFields = {
  employmentConditions: [
    'weeklyWorkingMinutes',
    'maxDailyWorkingMinutes',
    'partTimeMultiplier',
    'scheduleCycleDuration',
    'validateWorkingRules',
    'elasticWrokDay',
  ],
};

export const getInitialState = (
  absencesTypes = [],
  absencesLimitTemplates = [],
  companyRoles = [],
  restrictions = [],
) => {
  const limits = getInitialLimitsState(absencesTypes, absencesLimitTemplates);
  let result = {
    name: '',
    lastName: '',
    inactive: false,
    manager: false,
    role: companyRoles.find(role => role.id === 'employee')?.id || null,
    role_id: companyRoles.find(role => role.id === 'employee')?.id || null,
    email2: '',
    phone: '',
    phone_prefix: '',
    referenceId: '',
    alias: '',
    nfcCode: '',
    activeTab: 0,
    locations: [],
    supplementaryLocations: [],
    employmentConditionsTemplateId: 'rown' as string | -1,
    availabilityLimits: [],
    weeklyWorkingMinutes: '40:00',
    maxDailyWorkingMinutes: '12:00',
    validateWorkingRules: true,
    elasticWorkDay: false,
    showAbsences: true,
    isMedical: false,
    allowedToWorkAtNightHours: true,
    scheduleCycleStart: {
      day: 1,
      month: moment().month() + 1,
      year: moment().year(),
    },
    scheduleCycleDuration: 1,
    hireDate: [],
    releaseDate: [],
    changedTemplate: false,
    limits,
    changedLimits: [],
    errors: {},
    errorMessage: '',
    sendInvitation: false,
    contracts: [
      {
        id: v4(),
        job_titles: [],
      },
    ],
    customFields: [],
  };

  if (restrictions.includes(EMPLOYMENT_CONDITIONS_DISABLE_DEFAULT)) {
    result = {
      ...result,
      employmentConditionsTemplateId: -1,
      weeklyWorkingMinutes: '__:__',
      maxDailyWorkingMinutes: '__:__',
    };
  }

  return result;
};

export const getSupplementaryLocationIds = (isManager: boolean, supplementaryLocations: string[]) => {
  if (isManager) return '';
  return supplementaryLocations.join(',');
};
