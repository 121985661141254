import { defineMessages } from 'react-intl';

export const BUTTON_MODIFIERS = ['orange', 'teeny'];

export const messages = defineMessages({
  noCoordinatesTitle: {
    id: 'weatherForecast.noCoordinates.title',
    defaultMessage: 'Brak koordynatów dla lokalizacji',
  },
  noCoordinatesText: {
    id: 'weatherForecast.noCoordinates.text',
    defaultMessage:
      'Aby mieć dostęp do prognozy pogody dla wybranej lokalizacji, przejdź do ustawień w celu podania koordynatów.',
  },
  noWeatherText: {
    id: 'weatherForecast.noWeather.text',
    defaultMessage: 'Brak prognozy dla danego dnia',
  },
  settingsButton: {
    id: 'common.locationSettings',
    defaultMessage: 'Ustawienia lokalizacji',
  },
});
