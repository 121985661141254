import { connect } from 'react-redux';

import { getDaysFreeFromWork } from '@/actions';
import { toggleNonWorkingDays } from '@/actions/schedule';
import { hideModal } from '@/actions/uiState.js';
import {
  createUserEvent,
  createUserEvents,
  showDeleteUserEventConfirmModal,
  updateUserEvent,
} from '@/redux-store/events';

import EditEventModal from './EditEventModal';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal,
  modalObject: state.reducer.uiState.modalObject,
  companyId: state.reducer.currentCompany.id,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  mainDateStore: state.reducer.mainDateStore,
  nonWorkingDays: state.reducer.scheduleUIState.nonWorkingDays,
});

const mapDispatchToProps = {
  changeUserEvent: updateUserEvent,
  addUserEvent: createUserEvent,
  hideModal,
  deleteUserEvent: showDeleteUserEventConfirmModal,
  toggleNonWorkingDays,
  getDaysFreeFromWork,
  addMassUserEvent: createUserEvents,
};
const EditEventModalContainer = connect(mapStateToProps, mapDispatchToProps)(EditEventModal);

export default EditEventModalContainer;
