import { Location, UserLocation } from '@/types';
import { isNumberType } from '@/utils/variableTypes/variableTypes';

import { WeatherForecast, WeatherForecastDto, WeatherForecastStoreState } from './weatherForecast.types';

const weatherIconMap = {
  Sunny: 'sunny',
  Snow: 'cloudy_snowing',
  Clouds: 'cloud',
  PartlyCloudy: 'partly_cloudy_day',
  Thunderstorm: 'thunderstorm',
  Rain: 'rainy',
};

const formatWeatherForecast = (weatherForecast: WeatherForecast) => ({
  ...weatherForecast,
  minTemp: Math.round(weatherForecast.minTemp),
  maxTemp: Math.round(weatherForecast.maxTemp),
  weatherType: weatherIconMap[weatherForecast.weatherType] || 'partly_cloudy_day',
  chanceForPrecipitation: Math.round(weatherForecast.chanceForPrecipitation * 100),
});

export const weatherForecastToStore = (weatherForecast: WeatherForecastDto): WeatherForecastStoreState => {
  const weatherForecastStore: WeatherForecastStoreState = {};
  Object.keys(weatherForecast).forEach(locationId => {
    weatherForecastStore[locationId] = {};
    weatherForecast[locationId].forEach(forecast => {
      weatherForecastStore[locationId][forecast.date] = formatWeatherForecast(forecast);
    });
  });
  return weatherForecastStore;
};

export const getLocationsWithCoordinates = (
  locationIds: Location['id'][],
  userLocationsDict: Record<Location['id'], UserLocation>,
) =>
  locationIds.reduce<Location['id'][]>((prev, locationId) => {
    const location = userLocationsDict[locationId];
    return isNumberType(location?.lat) && isNumberType(location?.lng) ? [...prev, locationId] : prev;
  }, []);
