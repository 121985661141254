import { Location } from '@/types';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getRangeBetweenDates, getToday } from '@/utils/dateHelper';
import { addNDaysToDate } from '@/utils/dates/dates.helpers';

import { selectCurrentUserLanguage } from '../currentUser';
import { selectDateArray } from '../mainDateStore';
import { AppDispatch, GetStateType } from '../types';
import { selectUserLocationsDict } from '../userLocations';
import { getWeatherForecast } from './weatherForecast.asyncActions';
import { selectWeatherForecast } from './weatherForecast.selectors';
import { getLocationsWithCoordinates } from './weatherForecast.utils';

export const getWeatherForecastIfNecessary =
  (initialLocationsIds: Location['id'][]) => (dispatch: AppDispatch, getState: GetStateType) => {
    const state = getState();
    const userLocationsDict = selectUserLocationsDict(state);
    const dateArray = selectDateArray(state);
    const language = selectCurrentUserLanguage(state);
    const weatherForecast = selectWeatherForecast(state);
    const locationsIdsWithCoordinates = getLocationsWithCoordinates(initialLocationsIds, userLocationsDict);
    const forecastDateRange = getRangeBetweenDates(getToday(), addNDaysToDate(15));
    const isInRange = forecastDateRange.some(date => dateArray.includes(date));
    const locationsIds = locationsIdsWithCoordinates.filter(locationId => !weatherForecast[locationId]);
    if (!isEmptyArray(locationsIds) && isInRange) dispatch(getWeatherForecast({ locationsIds, language }));
  };
