import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ATTENDANCE_ABSENCE_COLOR } from '@/constants/colors';
import { DATE_MODE_DAY } from '@/constants/dateModes';

import ScheduleShiftBlock from '../ScheduleShiftBlock/ScheduleShiftBlock.jsx';
import ScheduleSingleDayShiftBlock from '../ScheduleSingleDayShiftBlock/ScheduleSingleDayShiftBlock.jsx';

import './TrandeShiftShift.scss';

const TradeShiftShift = ({ shift, handleAction, size, currentUserId, isEmployee, date, isEditingScheduleDisabled }) => {
  const color = shift.job_title.color || ATTENDANCE_ABSENCE_COLOR;
  const hasComment = shift.comment && shift.comment.length > 0;
  const employeeName = `${shift.user.first_name} ${shift.user.last_name}`;
  const [from, to] = shift.working_hours.split('-');
  const { draft, indicated_coworkers: coworkers } = shift;
  const usersWhoAcceptedCount = coworkers.filter(indication => indication.status === 'approved').length;
  const currentUserTradeShiftData = coworkers.find(coworker => coworker.user.id === currentUserId);
  const userApprovedTradeShift = currentUserTradeShiftData?.status === 'approved';
  const userRejectedTradeShift = currentUserTradeShiftData?.status === 'rejected';
  const tooltipText = userRejectedTradeShift ? (
    <FormattedMessage
      id="schedule.tradeShift.rejected.tooltip"
      defaultMessage="Nie można zapisać się na odrzuconą wymianę zmian"
    />
  ) : null;

  return size === DATE_MODE_DAY && !isEmployee ? (
    <ScheduleSingleDayShiftBlock
      color={color}
      from={from}
      to={to}
      date={date}
      size={size}
      isDraft={draft}
      hasComment={hasComment}
      onClick={handleAction}
      title={employeeName}
      blocked
      isEditingScheduleDisabled
      className="k-openShiftBlock"
      hourFirst
    />
  ) : (
    <ScheduleShiftBlock
      color={color}
      from={from}
      to={to}
      size={size}
      isDraft={draft}
      hasComment={hasComment}
      onClick={handleAction}
      title={employeeName}
      isDisabled={userRejectedTradeShift}
      tooltipText={tooltipText}
      isEditDisabled={isEditingScheduleDisabled}
    >
      {!isEmployee && usersWhoAcceptedCount > 0 && (
        <div className={`k-openShiftBlock__eagerUsers  k-openShiftBlock__eagerUsers--${size}`}>
          {usersWhoAcceptedCount}
        </div>
      )}
      {userApprovedTradeShift && <div className="k-trade-shifts__triangle k-trade-shifts__triangle--approved" />}
      {userRejectedTradeShift && <div className="k-trade-shifts__triangle k-trade-shifts__triangle--rejected" />}
    </ScheduleShiftBlock>
  );
};

TradeShiftShift.defaultProps = {
  size: 10,
  jobTitle: {},
  modifiers: [],
};

TradeShiftShift.propTypes = {
  size: PropTypes.string,
  shift: PropTypes.shape({
    working_hours: PropTypes.string,
    shifts_remaining: PropTypes.number,
    job_title: PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
    date: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({})),
    draft: PropTypes.bool,
    comment: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    indicated_coworkers: PropTypes.arrayOf(PropTypes.shape({ status: PropTypes.string })),
  }),
  jobTitle: PropTypes.shape({
    color: PropTypes.string,
  }),
  handleAction: PropTypes.func,
  currentUserId: PropTypes.string,
  isEmployee: PropTypes.bool,
  date: PropTypes.string,
  isEditingScheduleDisabled: PropTypes.bool,
};

export default TradeShiftShift;
