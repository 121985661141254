export const AVAILABILITY_BLOCK_CREATE = 'AVAILABILITY_BLOCK:CREATE';

export const AVAILABILITY_LIMITS_VIEW = 'AVAILABILITY_LIMITS:VIEW';

export const BUDGET_ESTIMATES_EDIT = 'BUDGET_ESTIMATES:EDIT';

export const BUDGET_ESTIMATES_VIEW = 'BUDGET_ESTIMATES:VIEW';

export const ECP_GET = 'ECP:GET';

export const EMPLOYMENT_CONDITIONS_CREATE = 'EMPLOYMENT_CONDITIONS:CREATE';

export const OPEN_SHIFTS_GET = 'OPEN_SHIFTS:GET';

export const OPEN_SHIFTS_SETTING_SHOW = 'OPEN_SHIFTS:SETTING_SHOW';

export const PAYROLL_VIEW_GET = 'PAYROLL_VIEW:GET';

export const PERMISSIONS_VIEW_GET = 'PERMISSIONS_VIEW:GET';

export const PRODUCTION_QUOTAS_GET = 'PRODUCTION_QUOTAS:GET';

export const PRODUCTION_QUOTAS_SETTING_SHOW = 'PRODUCTION_QUOTAS:SETTING_SHOW';

export const SMS_NOTIFICATION_SEND = 'SMS_NOTIFICATION:SEND';

export const SUPPLEMENTARY_LOCATIONS_VIEW = 'SUPPLEMENTARY_LOCATIONS:VIEW';

export const TRADE_SHIFTS_GET = 'TRADE_SHIFTS:GET';

export const TRADE_SHIFTS_SETTING_SHOW = 'TRADE_SHIFTS:SETTING_SHOW';

export const WORKING_RULES_CHECK_CHANGE = 'WORKING_RULES_CHECK:CHANGE';

export const WORKING_RULES_GET = 'WORKING_RULES:GET';

export const COMPANY_MANAGE_PERMISSIONS_HIDE = 'COMPANY_MANAGE_PERMISSIONS:HIDE';

export const REVERSE_SUPPLEMENTARY_LOCATION_ASSIGN = 'REVERSE_SUPPLEMENTARY_LOCATION:ASSIGN';

export const SHORTER_ABSENCE_NAME = 'SHORTER_ABSENCE_NAME';

export const RCP_GEOLOCALIZATION = 'RCP_GEOLOCALIZATION';

export const NEW_REPORTS_VIEW = 'NEW_REPORTS:VIEW';

export const OLD_REPORTS_VIEW = 'OLD_REPORTS:VIEW';

export const CALCULATE_SPMH_BASED_BONUS = 'CALCULATE_SPMH_BASED_BONUS';

export const LANGUAGE_SETTINGS_SHOW = 'LANGUAGE_SETTINGS:SHOW';

export const VALIDATE_REF_ID = 'VALIDATE_REF_ID';

export const NEW_SCHEDULE_VIEW = 'NEW_SCHEDULE_VIEW:VIEW';

export const OPENING_HOURS_VIEW = 'OPENING_HOURS:VIEW';

export const COMPANY_MANAGE_LOCATIONS_SHOW_ASSIGN = 'COMPANY_MANAGE_LOCATIONS:SHOW_ASSIGN';

export const ABSENCES_EDIT_SHOW = 'ABSENCES_EDIT:SHOW';

export const EXPORT_GSN_SHOW = 'EXPORT_GSN:SHOW';

export const ATTENDANCE_SURVEY = 'ATTENDANCE_SURVEY';

export const TALKJS_CHAT_ENABLE = 'TALKJS_CHAT:ENABLE';

export const EMPLOYEE_SCHEDULE_EDIT_ENABLE = 'EMPLOYEE_SCHEDULE_EDIT:ENABLE';

export const ENOVA_INTEGRATION = 'ENOVA_INTEGRATION';

export const IGNORE_VALIDATING_WORKING_RULES = 'IGNORE_VALIDATING_WORKING_RULES';

export const ENABLE_LOG_ROCKET = 'LOG_ROCKET:ENABLE';

export const AUTOSCHEDULER_PROXY_ENABLE = 'AUTOSCHEDULER_PROXY:ENABLE';

export const BUDGET_METRICS_ENABLE = 'BUDGET_METRICS:ENABLE';

export const HIRE_RELEASE_DATE_SHOW = 'HIRE_RELEASE_DATE:SHOW';

export const RCP_NEW_LOGIN_METHODS_SHOW = 'RCP_NEW_LOGIN_METHODS:SHOW';

export const MULTIPLE_ABSENCES_DURING_DAY_ENABLE = 'MULTIPLE_ABSENCES_DURING_DAY:ENABLE';

export const OVERTIME_V2_ENABLE = 'OVERTIME_V2:ENABLE';

export const OLD_PAYROLL_VIEW = 'OLD_PAYROLL:VIEW';
export const RELEASED_EMPLOYEE_CLEAR_DATA_ENABLE = 'RELEASED_EMPLOYEE_CLEAR_DATA:ENABLE';

export const LABELS_MANAGE = 'LABELS:MANAGE';

export const OLD_XLS_PAYROLL_EXPORT = 'OLD_XLS_PAYROLL_EXPORT';

export const SAGE_LEGACY_EXPORT = 'SAGE_LEGACY_EXPORT';

export const AUTO_GENERATE_SCHEDULE_VIEW = 'AUTO_GENERATE_SCHEDULE:VIEW';

export const CONTRACTS_EDIT_SHOW = 'CONTRACTS_EDIT:SHOW';

export const EMPLOYEE_PAYROLL_VIEW_SHOW = 'EMPLOYEE_PAYROLL_VIEW:SHOW';

export const EMPLOYEE_PAYROLL_VIEW_SETTINGS_SHOW = 'EMPLOYEE_PAYROLL_VIEW_SETTINGS:SHOW';

export const ACCOUNT_SWITCHER_VIEW = 'ACCOUNT_SWITCHER:VIEW';

export const ACCOUNT_SWITCHER_USER_SETTINGS_VIEW = 'ACCOUNT_SWITCHER_USER_SETTINGS:VIEW';

export const LOAN_EMPLOYEES_ENABLE = 'LOAN_EMPLOYEES:ENABLE';

export const PASSWORD_CHANGE_INTERVAL_VIEW = 'PASSWORD_CHANGE_INTERVAL:VIEW';

export const IMPORT_SHIFTS_AND_ATTENDANCES = 'IMPORT_SHIFTS_AND_ATTENDANCES';

export const MODIFICATION_BLOCKADES_ENABLE = 'MODIFICATION_BLOCKADES:ENABLE';

export const CUSTOM_FIELDS_SHOW = 'CUSTOM_FIELDS:SHOW';

export const FREE_DAYS_MARKING_ENABLE = 'FREE_DAYS_MARKING:ENABLE';

export const OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE = 'OPEN_SHIFTS_FOR_LOCATION_GROUPS:ENABLE';

export const WEEK_START_HOUR_ENABLE = 'WEEK_START_HOUR:ENABLE';

export const SENTRY_ENABLE = 'SENTRY:ENABLE';
