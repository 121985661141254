import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import ScheduleTableLoanEmployeesProposalCell from './ScheduleTableLoanEmployeesProposalsCell.tsx';

const mapStateToProps = state => ({
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
  restrictions: state.reducer.userPermissions.restrictions,
});
const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableLoanEmployeesProposalCell);
