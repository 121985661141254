import { memo } from 'react';
import { useIntl } from 'react-intl';

import messages from '../messages';

export const ScheduleTableWeatherRowTitle = memo(() => {
  const intl = useIntl();

  return <th className="k-weatherRow__title">{intl.formatMessage(messages.scheduleTableWeatherTitle)}</th>;
});

ScheduleTableWeatherRowTitle.displayName = 'ScheduleTableWeatherRowTitle';
