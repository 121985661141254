import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux-store/types';
import { Location } from '@/types';

const selectLocationSettings = (state: RootState) => state.reducer.settings.locationSettings;

const selectLocationSettingsByLocationId = (locationId: Location['id']) =>
  createSelector(selectLocationSettings, locationSettings => locationSettings[locationId]);

export const selectLocationCoordinates = (locationId: Location['id']) =>
  createSelector(
    selectLocationSettingsByLocationId(locationId),
    locationSettings => locationSettings?.location_coordinates,
  );

export const selectHasLocationCoordinates = (locationId: Location['id']) =>
  createSelector(selectLocationCoordinates(locationId), locationCoordinates => !!locationCoordinates);
