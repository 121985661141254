import { memo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { Location } from '@/types';

import ScheduleTableWeatherCell from '../ScheduleTableWeatherCell/ScheduleTableWeatherCell.redux';
import { ScheduleTableWeatherRowTitle } from '../ScheduleTableWeatherRowTitle/ScheduleTableWeatherRowTitle';

import './scheduleTableWeatherRow.scss';

type Props = {
  locationId: Location['id'];
};

export const ScheduleTableWeatherRow = memo(({ locationId }: Props) => {
  const dateArray = useAppSelector(selectDateArray);

  return (
    <tr className="k-weatherRow">
      <ScheduleTableWeatherRowTitle />
      {dateArray.map(date => (
        <ScheduleTableWeatherCell key={date} date={date} locationId={locationId} />
      ))}
    </tr>
  );
});

ScheduleTableWeatherRow.displayName = 'ScheduleTableWeatherRow';
