/* eslint-disable camelcase */
/* eslint-disable array-callback-return */

import { uniq } from '@/utils/array/array.helpers';
import { getLocaleConfig } from '@/utils/locale/locale.utils.ts';

import KadroBaseConnections from './BaseConnections.js';

class KadroConnections extends KadroBaseConnections {
  constructor(connections, authentication) {
    super(connections, authentication);
    this.companyID = undefined;
    // TODO: Temporary, moving it outside to connection handlers.
    // this.stagingapi = 'https://stagingapi.kadromierz.pl';
    // this.workerAddress = 'https://stagingapi.kadromierz.pl/workers';
  }

  setCompanyId(companyID) {
    this.companyID = companyID;
  }

  authenticateClient(email, password, requestType) {
    return super.postJSON(
      `${this.base_url}/security/authentication`,
      {
        email,
        password,
      },
      requestType,
    );
    // .then(data => {
    //     // TODO: Store the token inside the object
    //     console.log(data);
    // });
  }

  getAuthToken(code, requestType) {
    return super.postJSON(`${this.base_url}/token-generator/access-token`, { code }, requestType);
  }

  revokeToken() {
    return super.postJSON(`${this.base_url}/security/auth-token/revoke`, {});
  }

  autoschedule(payload, requestType) {
    return super.postJSON(`${this.base_url}/autoscheduler`, payload, requestType);
  }

  autoscheduleProxy(payload, requestType) {
    return super.postJSON(`${this.base_url}/autoscheduler-proxy`, payload, requestType);
  }

  fetchAutoschedulerResults(taskID, requestType) {
    return super.getJSON(`${this.base_url}/autoscheduler/${taskID}`, requestType);
  }

  getUnreadNotificationNumber(requestType) {
    return super.getJSON(`${this.base_url}/users/current/unread-notifications-number`, requestType);
  }

  markNotificationAsRead(notification_id, requestType) {
    return super.putJSON(`${this.base_url}/notifications/${notification_id}`, requestType);
  }

  markNotificationAsUnread(notification_id, requestType) {
    return super.putJSON(`${this.base_url}/notifications/${notification_id}/unread`, requestType);
  }

  markAllNotificationAsRead(requestType) {
    return super.postJSON(`${this.base_url}/users/current/notifications/read`, requestType);
  }

  deleteNotification(notification_id, requestType) {
    return super.deleteJSON(`${this.base_url}/notifications/${notification_id}`, requestType);
  }

  deleteAllNotifications(requestType) {
    return super.deleteJSON(`${this.base_url}/users/current/notifications/all`, requestType);
  }

  getActivityLog(limit = 100, from, requestType) {
    const query = from ? `&timestamp=${from}` : '';
    return super.getJSON(`${this.base_url}/activity-log?limit=${limit}${query}`, requestType);
  }

  getObjectsActivityLog(objectId, objectType, requestType) {
    return super.getJSON(
      `${this.base_url}/activity-log/object?object_id=${objectId}&object_type=${objectType}`,
      requestType,
    );
  }

  // Schedule

  getSchedule(location_id, from, to, requestType, show_drafts = true) {
    // TODO: Store all the settings info inside the app
    // {"notifications":[]}
    const queryString = `?from=${from}&to=${to}&show_drafts=${show_drafts}`;
    return super.getJSON(`${this.base_url}/locations/${location_id}/schedule${queryString}`, requestType);
  }

  getScheduleForMultipleLocations(location_id_array, from, to, employeeIds, requestType, show_drafts = true) {
    const baseQueryString = `?from=${from}&to=${to}&show_drafts=${show_drafts}&locations=${location_id_array.join(
      ',',
    )}`;
    const queryString = employeeIds ? `${baseQueryString}&employees=${employeeIds.join(',')}` : baseQueryString;
    return super.getJSON(`${this.base_url}/company/locations/schedule${queryString}`, requestType);
  }

  getScheduleForEmployee(employee_id, from, to, requestType) {
    return super.getJSON(`${this.base_url}/employee/${employee_id}/shifts?from=${from}&to=${to}`, requestType);
  }

  massDelete(del_obj, obj_array_to_delete, requestType) {
    switch (del_obj) {
      case 'userShiftblocks':
        return super.all([
          obj_array_to_delete.map(shiftblock_id => {
            this.deleteShiftblock(shiftblock_id, requestType);
          }),
        ]);
      case 'userLocations':
        return super.all([
          obj_array_to_delete.map(location_obj => {
            this.deleteLocation(location_obj.id, requestType);
          }),
        ]);
      case 'userEmployees':
        return super.all([
          obj_array_to_delete.map(employee_id => {
            this.deleteEmployee(employee_id, requestType);
          }),
        ]);
      case 'userJobTitles':
        return super.all([
          obj_array_to_delete.map(jobtitle_id => {
            this.deleteJobTitle(jobtitle_id, requestType);
          }),
        ]);
      default:
        return undefined;
    }
  }

  massAdd(add_obj, obj_array_to_add, requestType) {
    switch (add_obj) {
      case 'userLocations': {
        const objArr = obj_array_to_add.map(loc => this.addLocation(loc, requestType));
        return super.all(objArr);
      }
      case 'userJobTitles': {
        const jobArr = obj_array_to_add.map(job => this.addJobTitle(job, requestType));
        return super.all(jobArr);
      }
      case 'userEmployees': {
        const empArr = obj_array_to_add.map(emp => this.addEmployee(emp, requestType));
        return super.all(empArr);
      }
      default:
        return undefined;
    }
  }

  /**
   *
   *
   * @param {string} change_obj
   * @param {array} obj_array_to_change - Array of Objects that are passed to functions that accept those Objects
   * @returns {Promise}
   *
   * @memberOf KadroConnections
   */
  massChange(change_obj, obj_array_to_change, requestType) {
    switch (change_obj) {
      case 'userEmployees': {
        const empArr = obj_array_to_change.map(emp => this.changeEmployee(emp, requestType));
        return super.all(empArr);
      }
      default:
        break;
    }
    return undefined;
  }

  getScheduleEvents(location_id, from, to, requestType) {
    const queryString = `?from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/locations/${location_id}/scheduled-events${queryString}`, requestType);
  }

  getMultipleScheduleEventsWithPost(locationIds, from, to, requestType) {
    const payload = { locationIds, from, to };
    return super.postJSON(`${this.base_url}/companies/locations/scheduled-events`, payload, requestType);
  }

  deleteEmployeeArray(employee_id_arr, requestType) {
    return super.all(employee_id_arr.map(e => this.deleteEmployee(e, requestType)));
  }

  deleteJobTitleArray(job_title_id_arr, requestType) {
    return super.all(job_title_id_arr.map(e => this.deleteJobTitle(e, requestType)));
  }

  addShift(shift_object, requestType) {
    return super.postJSON(`${this.base_url}/shifts`, shift_object, requestType);
  }

  addMassShifts(shift_obj_array, requestType) {
    return super.all(shift_obj_array.map(s => this.addShift(s, requestType)));
  }

  changeShift(shift_object, requestType) {
    // This object also contains id
    return super.putJSON(`${this.base_url}/shifts/${shift_object.id}`, shift_object, requestType);
  }

  publishSchedule(scheduleObject) {
    return super.postJSON(`${this.base_url}/schedule/publish`, scheduleObject);
  }

  deleteShift(shift_id, requestType) {
    return super.deleteJSON(`${this.base_url}/shifts/${shift_id}`, requestType);
  }

  /**
   * Prepare this for throttling and keeping track of what has actually happened with the requests.
   * @param {shift id array} shift_id_array
   */
  deleteMassShifts(shift_id_array, requestType) {
    return super.deleteJSON(
      `${this.base_url}/shifts`,
      {
        data: { ids: shift_id_array },
      },
      requestType,
    );
    // return super.all(shift_id_array.map(s => this.deleteShift(s)));
  }

  swapShifts(originShift, targetShift) {
    return super.postJSON(`${this.base_url}/shifts/swap-shifts`, { originShift, targetShift });
  }

  // Availability
  signUp(email, requestType) {
    return super.postJSON('https://app.kadromierz.pl/register', { email }, requestType);
  }

  registerUser(reg_object, requestType) {
    return super.postJSON(`https://app.kadromierz.pl/register/${reg_object.email}`, reg_object, requestType);
  }

  createOwner(registrationObject, requestType) {
    return super.postJSON(`${this.base_url}/users`, registrationObject, requestType);
  }

  loginWithGoogleOAuth(access_token, requestType) {
    return super.postJSON(`${this.base_url}/security/oauth/google/login`, { access_token }, requestType);
  }

  getGoogleOAuthUserData(access_token, requestType) {
    return super.getJSON(`${this.base_url}/security/oauth/google/user/${access_token}`, requestType);
  }

  registerWithGoogleOAuth(companyData, requestType) {
    return super.postJSON(`${this.base_url}/security/oauth/google/register`, companyData, requestType);
  }

  loginWithEntraId(access_token, tenant_id, requestType = 'init') {
    return super.postJSON(`${this.base_url}/security/oauth/entra-id/login`, { access_token, tenant_id }, requestType);
  }

  checkEmail(email, requestType) {
    return super.postJSON(`${this.base_url}/check-email`, { email }, requestType);
  }

  getAvailability(user_id, from, to, requestType) {
    const queryString = `?from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/users/${user_id}/availability-blocks${queryString}`, requestType);
  }

  getCurrentAvailability(from, to, requestType) {
    const queryString = `?from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/users/current/availability-blocks${queryString}`, requestType);
  }

  createCurrentAvailability(availability_object, requestType) {
    return super.postJSON(
      `${this.base_url}/users/current/availability-blocks/${availability_object.type_id}`,
      availability_object,
      requestType,
    );
  }

  createAvailability(user_id, availability_object, requestType) {
    if (availability_object.type === 'custom') {
      return super.postJSON(
        `${this.base_url}/users/${user_id}/availability-blocks/${availability_object.type_id}`,
        availability_object,
        requestType,
      );
    }
    return super.postJSON(`${this.base_url}/users/${user_id}/availability-blocks`, availability_object, requestType);
  }

  changeAvailability(availability_object, requestType) {
    return super.putJSON(
      `${this.base_url}/availability-blocks/${availability_object.id}`,
      availability_object,
      requestType,
    );
  }

  deleteAvailability(availability_id_array, requestType) {
    // object = {ids : ["1", "2"]}
    return super.deleteJSON(
      `${this.base_url}/availability-blocks`,
      {
        data: { ids: availability_id_array.map(id => id.toString()) },
      },
      requestType,
    );
  }

  publishAvailability(availability_object, requestType) {
    return super.postJSON(
      `${this.base_url}/availability-blocks/${availability_object.id}/publish`,
      availability_object,
      requestType,
    );
  }

  // Events

  getEvent(user_id, from, to, requestType) {
    const queryString = `?from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/users/${user_id}/availability-blocks${queryString}`, requestType);
  }

  createEvent(event_object, requestType) {
    return super.postJSON(`${this.base_url}/events`, event_object, requestType);
  }

  changeEvent(event_object, requestType) {
    return super.putJSON(`${this.base_url}/events/${event_object.id}`, event_object, requestType);
  }

  deleteEvent(event_id, requestType) {
    // object = {ids : ["1", "2"]}
    return super.deleteJSON(`${this.base_url}/events/${event_id}`, requestType);
  }

  createMultipleEvents(events) {
    return super.postJSON(`${this.base_url}/events/add-multiple`, events);
  }

  // User

  getCurrentUserInfo(requestType) {
    return super.getJSON(`${this.base_url}/users/current`, requestType);
  }

  changeCurrentUserInfo(user_object, requestType) {
    return super.putJSON(`${this.base_url}/users/current`, user_object, requestType);
  }

  // Company

  getMilestones() {
    return super.getJSON(`${this.base_url}/milestones`);
  }

  getCurrentCompany(requestType) {
    return super.getJSON(`${this.base_url}/users/current/company`, requestType);
  }

  changeCurrentCompany(company_object, requestType) {
    return super.putJSON(`${this.base_url}/users/current/company`, company_object, requestType);
  }

  changeCompanyStatus(status) {
    return super.putJSON(`${this.base_url}/new-payments/company-status`, { status });
  }

  getCompanyLimitsUsage(companyId) {
    return super.getJSON(`${this.base_url}/companies/${companyId}/limitsUsage`);
  }

  // Locations

  getLocations(requestType) {
    // {"notifications":[]}
    return super.getJSON(`${this.base_url}/users/current/locations`, requestType);
  }

  getLocation(location_id, requestType) {
    // {"notifications":[]}
    return super.getJSON(`${this.base_url}/locations/${location_id}`, requestType);
  }

  addLocation(location_object, requestType) {
    return super.postJSON(`${this.base_url}/locations`, location_object, requestType);
  }

  changeLocation(location_object, requestType) {
    return super.putJSON(`${this.base_url}/locations/${location_object.id}`, location_object, requestType);
  }

  deleteLocation(id, requestType) {
    return super.deleteJSON(`${this.base_url}/locations/${id}`, requestType);
  }

  deleteLocationArray(location_id_arr, requestType) {
    return super.all(location_id_arr.map(e => this.deleteLocation(e, requestType)));
  }

  // Job Titles

  getJobTitles(company_id, query_deleted, requestType) {
    const queryString = query_deleted ? '?deleted=true' : '';
    return super.getJSON(`${this.base_url}/companies/${company_id}/job-titles${queryString}`, requestType);
  }

  addJobTitle(jobtitle_object, requestType) {
    return super.postJSON(`${this.base_url}/job-titles`, jobtitle_object, requestType);
  }

  changeJobTitle(jobtitle_object, requestType) {
    return super.putJSON(`${this.base_url}/job-titles/${jobtitle_object.id}`, jobtitle_object, requestType);
  }

  deleteJobTitle(jobtitle_id, requestType) {
    return super.deleteJSON(`${this.base_url}/job-titles/${jobtitle_id}`, requestType);
  }

  getShiftblocks(company_id, requestType) {
    return super.getJSON(`${this.base_url}/companies/${company_id}/shiftblocks`, requestType);
  }

  addShiftblock(shiftblock_object, requestType) {
    return super.postJSON(`${this.base_url}/shiftblocks`, shiftblock_object, requestType);
  }

  changeShiftblock(shiftblock_object, requestType) {
    return super.putJSON(`${this.base_url}/shiftblocks/${shiftblock_object.id}`, shiftblock_object, requestType);
  }

  deleteShiftblock(shiftblock_id, requestType) {
    return super.deleteJSON(`${this.base_url}/shiftblocks/${shiftblock_id}`, requestType);
  }

  deleteShiftblockArray(shift_id_arr, requestType) {
    return super.all(shift_id_arr.map(e => this.deleteShiftblock(e, requestType)));
  }

  // Availability types

  getAvaTypes(company_id, requestType) {
    return super.getJSON(`${this.base_url}/companies/${company_id}/availability-types`, requestType);
  }

  addAvaType(ava_object, requestType) {
    return super.postJSON(`${this.base_url}/availability-types`, ava_object, requestType);
  }

  changeAvaType(ava_object, requestType) {
    return super.putJSON(`${this.base_url}/availability-types/${ava_object.id}`, ava_object, requestType);
  }

  deleteAvaType(ava_id, requestType) {
    return super.deleteJSON(`${this.base_url}/availability-types/${ava_id}`, requestType);
  }

  deleteAvaTypeArray(ava_id_arr, requestType) {
    return super.all(ava_id_arr.map(e => this.deleteAvaType(e, requestType)));
  }

  // Employees

  getEmployees(company_id, requestType) {
    // TODO: Store all the settings info inside the app
    // {"employees":[]}
    return super.getJSON(`${this.base_url}/companies/employees`, requestType);
  }

  addEmployee(employee_object, requestType) {
    return super.postJSON(`${this.base_url}/v2/employees`, employee_object, requestType);
  }

  changeEmployee(employee_object, requestType) {
    return super.putJSON(`${this.base_url}/v2/employees/${employee_object.id}`, employee_object, requestType);
  }

  deleteEmployee(employee_id, requestType) {
    return super.deleteJSON(`${this.base_url}/employees/${employee_id}`, requestType);
  }

  deleteReleasedEmployeesData(employeeIds, deleteShifts, deleteAttendances, deleteAbsences) {
    return super.deleteJSON(`${this.base_url}/users/delete-released-data`, {
      data: {
        employeeIds,
        options: {
          deleteShifts,
          deleteAttendances,
          deleteAbsences,
        },
      },
    });
  }

  // Settings

  changeCurrentUserSettings(settingsObj, requestType) {
    return super.putJSON(`${this.base_url}/users/current/settings`, settingsObj, requestType);
  }

  changePassword(old_password, new_password, requestType) {
    return super.postJSON(
      `${this.base_url}/users/current/password`,
      {
        old_password,
        new_password,
      },
      requestType,
    );
  }

  getCompanySettings(requestType) {
    return super.getJSON(`${this.base_url}/users/current/company/settings`, requestType);
  }

  setCompanySettings(company_settings_object, requestType) {
    return super.putJSON(`${this.base_url}/users/current/company/settings`, company_settings_object, requestType);
  }

  getNotificationSettings(notification, requestType) {
    return super.getJSON(`${this.base_url}/notification-settings`, requestType);
  }

  setNotificationSettings(notification, requestType) {
    return super.postJSON(`${this.base_url}/notification-settings`, notification, requestType);
  }

  changeCompanySettings(company_settings_object, requestType) {
    return super.putJSON(`${this.base_url}/users/current/company`, company_settings_object, requestType);
  }

  getUserSettings(requestType) {
    return super.getJSON(`${this.base_url}/users/current/settings`, requestType);
  }

  changeUserSettings(requestType) {
    return super.putJSON(`${this.base_url}/users/current/settings`, requestType);
  }

  getMultipleLocationSettings(location_id_array, requestType) {
    return super.getJSON(
      `${this.base_url}/companies/locations/settings?locations=${location_id_array.join(',')}`,
      requestType,
    );
  }

  getLocationSettings(location_id, requestType) {
    // {"settings":{"schedule_is_public_for_employees":true,"tips_enabled":false}}
    return super.getJSON(`${this.base_url}/locations/${location_id}/settings`, requestType);
  }

  changeLocationSettings(locationId, settings_object, requestType) {
    return super.putJSON(`${this.base_url}/locations/${locationId}/settings`, settings_object, requestType);
  }

  changeLocationsSettingsMass(settings, requestType) {
    return super.putJSON(`${this.base_url}/locations/settings`, settings, requestType);
  }

  changeBonusSettings(data, requestType) {
    return super.putJSON(`${this.base_url}/bonus/settings`, data, requestType);
  }

  // Reports

  getGeneralReport(company_id, location_id_array, from, to, requestType) {
    const locations = location_id_array.join(',');
    const queryString = `?locations=${locations}&from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/company/${company_id}/general-report${queryString}`, requestType);
  }

  getJobTitlesReport(company_id, location_id_array, from, to, requestType) {
    const locations = location_id_array.join(',');
    const queryString = `?locations=${locations}&from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/company/${company_id}/job-titles-report${queryString}`, requestType);
  }

  getExpensesReport(company_id, location_id_array, from, to, requestType) {
    const locations = location_id_array.join(',');
    const queryString = `?locations=${locations}&from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/company/${company_id}/expenses-report${queryString}`, requestType);
  }

  getEmployeesReport(company_id, location_id_array, from, to, requestType) {
    const locations = location_id_array.join(',');
    const queryString = `?locations=${locations}&from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/company/${company_id}/employees-report${queryString}`, requestType);
  }

  // Invitations and Notifications
  sendEmployeeInvites(invitations, requestType) {
    return super.postJSON(`${this.workerAddress}/sendInvitation`, { invitations, locations: [] }, requestType);
  }

  // Permissions and Payments
  getUserStatus(requestType) {
    return super.postJSON(`${this.workerAddress}/getUserStatus`, {}, requestType);
  }

  // Timesheet

  getTimesheet(location_id, from, to, requestType) {
    const queryString = `?from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/locations/${location_id}/timesheet${queryString}`, requestType);
  }

  createAttendance(
    location_id,
    employee_id,
    start_timestamp,
    end_timestamp,
    labelIds,
    skipLocationCheck = false,
    requestType,
  ) {
    return super.postJSON(
      `${this.base_url}/attendances`,
      {
        location_id,
        employee_id,
        start_timestamp,
        end_timestamp,
        draft: false,
        breaks: [],
        labelIds,
        skipLocationCheck,
      },
      requestType,
    );
  }

  async createMassAttendances(attendances, { addMatchingShifts }) {
    const query = addMatchingShifts ? '?addMatchingShifts=true' : '';
    const result = await super.postJSON(`${this.base_url}/attendances/add-all${query}`, attendances);

    return result.data.attendances;
  }

  changeAttendance(attendance_id, start_timestamp, end_timestamp, requestType, newLocation) {
    return super.putJSON(
      `${this.base_url}/attendances/${attendance_id}`,
      {
        start_timestamp,
        end_timestamp,
        location_id: newLocation,
      },
      requestType,
    );
  }

  startAttendance(payload) {
    return super.postJSON(`${this.base_url}/users/current/attendances/open?ver=v2`, payload);
  }

  endAttendance(attendanceId, payload) {
    return super.postJSON(`${this.base_url}/users/current/attendances/${attendanceId}/close?ver=v2`, payload);
  }

  editAttendanceBonus(attendance_id, editBonusObject, requestType) {
    return super.putJSON(`${this.base_url}/attendances/${attendance_id}/bonus`, editBonusObject, requestType);
  }

  deleteAttendance(attendance_id, requestType) {
    return super.deleteJSON(`${this.base_url}/attendances/${attendance_id}`, requestType);
  }

  alignAttendances(alignObject, requestType) {
    return super.postJSON(`${this.base_url}/attendances/align`, alignObject, requestType);
  }

  alignAttendancesWeek(attendances, requestType) {
    return super.postJSON(
      `${this.base_url}/attendances/align-week`,
      {
        attendances,
      },
      requestType,
    );
  }

  // Payroll
  changePayrollDaysStatus(employeeIds, dates, newStatus) {
    return super.putJSON(`${this.base_url}/payroll-worker/payroll-approval`, {
      employee_ids: employeeIds,
      dates,
      new_status: newStatus,
    });
  }

  getNewPayroll(employeeId, locations, from, to, status, requestType) {
    let queryString = `?employee_id=${employeeId}&locations=${locations.join(',')}&from=${from}&to=${to}`;
    if (status) {
      queryString += `&status=${status}`;
    }
    return super.getJSON(`${this.base_url}/payroll-worker/payroll${queryString}`, requestType);
  }

  getShiftOrAttendanceForPayroll(id, isShift) {
    const editingThing = isShift ? 'shift_id' : 'attendance_id';
    const queryString = `?${editingThing}=${id}`;
    return super.getJSON(`${this.base_url}/payroll-worker/payroll-for-editing${queryString}`);
  }

  getWorkingUserIdsForPayroll(from, to, locationIds) {
    const body = { from, to, locationIds };
    return super.postJSON(`${this.base_url}/payroll-worker/working-user-ids`, body);
  }

  getNewPayrollLocation(from, to, employmentConditions, jobTitles, locations, status, requestType) {
    let queryString = `?from=${from}&to=${to}&employment_conditions=${employmentConditions.join(
      ',',
    )}&job_titles=${jobTitles.join(',')}&locations=${locations.join(',')}`;
    if (status) {
      queryString += `&status=${status}`;
    }
    return super.getJSON(`${this.base_url}/payroll-worker/payroll-location${queryString}`, requestType);
  }

  startPayrollLocationSession(
    from,
    to,
    employmentConditions,
    jobTitles,
    locations,
    employees,
    showInactive,
    calculateOvertime,
    status,
    requestType,
  ) {
    return super.postJSON(
      `${this.base_url}/payroll-worker/session/start`,
      {
        from,
        to,
        employment_conditions_ids: employmentConditions,
        job_titles: jobTitles,
        locations,
        employees,
        show_inactive: showInactive,
        calculate_overtime: calculateOvertime,
        status,
      },
      requestType,
    );
  }

  endPayrollLocationSession(sessionId, requestType) {
    return super.deleteJSON(`${this.base_url}/payroll-worker/session/${sessionId}/end`, requestType);
  }

  getPartOfPayrollLocation(employees, sessionId, requestType) {
    const queryString = `?employees=${employees.join(',')}&sessionId=${sessionId}`;
    return super.getJSON(`${this.base_url}/payroll-worker/payroll-location-portioned${queryString}`, requestType);
  }

  getEmployeesWithShiftOrAttendance(from, to, locations, requestType) {
    const queryString = `?from=${from}&to=${to}&locations=${locations.join(',')}`;
    return super.getJSON(`${this.base_url}/payroll-worker/active-user-ids${queryString}`, requestType);
  }

  getAttendances(companyId, locationIds, from, to, requestType) {
    const query = `?from=${from}&to=${to}&locations=${locationIds.join(',')}`;
    return super.getJSON(`${this.base_url}/companies/${companyId}/attendances${query}`, requestType);
  }

  getAttendancesImages(locationIds, from, to, requestType) {
    const queryString = `?locations=${locationIds.join(',')}&from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/images/rcp-photo-status${queryString}`, requestType);
  }

  getAttendanceImageUrls(locationId, attendanceId, requestType) {
    const queryString = `?location_id=${locationId}&attendance_id=${attendanceId}`;
    return super.getJSON(`${this.base_url}/images/rcp-photos${queryString}`, requestType);
  }

  getAttendanceForEmployee(from, to, requestType) {
    return super.getJSON(`${this.base_url}/user/current/attendances?from=${from}&to=${to}`, requestType);
  }

  getRcpData(employeeId) {
    return super.getJSON(`${this.base_url}/employees/${employeeId}/attendances`);
  }

  exportSchedule(format, exportData, exportMetaData, requestType) {
    return super.postJSON(`${this.workerAddress}/export/schedule`, { exportMetaData, exportData, format }, requestType);
  }

  exportEmployeeSchedule(format, exportData, exportMetaData, requestType) {
    return super.postJSON(
      `${this.workerAddress}/export/employee/schedule`,
      { exportMetaData, exportData, format },
      requestType,
    );
  }

  exportPayroll(format, exportData, exportMetaData, requestType) {
    return super.postJSON(`${this.workerAddress}/export/payroll`, { exportMetaData, exportData, format }, requestType);
  }

  exportEmployeesForQRCodes(format, exportData, exportMetaData, requestType) {
    return super.postJSON(`${this.workerAddress}/export/qr-code`, { exportMetaData, exportData, format }, requestType);
  }

  exportMultipleQRCodes(format, exportData, exportMetaData, requestType) {
    return super.postJSON(
      `${this.workerAddress}/export/qr-code/multiple`,
      { exportMetaData, exportData, format },
      requestType,
    );
  }

  exportMultiplePayroll(format, exportData, exportMetaData, requestType) {
    return super.postJSON(
      `${this.workerAddress}/export/payroll/multiple`,
      { exportMetaData, exportData, format },
      requestType,
    );
  }

  exportPayrollLocation(format, exportData, exportMetaData, requestType) {
    return super.postJSON(
      `${this.workerAddress}/export/payrollLocation`,
      { exportMetaData, exportData, format },
      requestType,
    );
  }

  exportAttendances(format, exportData, exportMetaData, requestType) {
    return super.postJSON(
      `${this.workerAddress}/export/attendance`,
      { exportMetaData, exportData, format },
      requestType,
    );
  }

  exportXLS(exportData, requestType) {
    return super.postJSON(`${this.workerAddress}/export/xls`, { exportData }, requestType);
  }

  // Open shifts
  createOpenShift(open_shift, requestType) {
    return super.postJSON(`${this.base_url}/open-shifts`, open_shift, requestType);
  }

  changeOpenShift(open_shift_id, open_shift, requestType) {
    return super.putJSON(`${this.base_url}/open-shifts/${open_shift_id}`, open_shift, requestType);
  }

  deleteOpenShift(open_shift_id, requestType) {
    return super.deleteJSON(`${this.base_url}/open-shifts/${open_shift_id}`, requestType);
  }

  massDeleteOpenShifts(open_shifts_ids, requestType) {
    return super.deleteJSON(
      `${this.base_url}/open-shifts`,
      {
        data: { ids: open_shifts_ids },
      },
      requestType,
    );
  }

  userApplyOpenShift(open_shift_id, requestType) {
    return super.postJSON(`${this.base_url}/open-shift/${open_shift_id}/apply`, {}, requestType);
  }

  userRemoveApplicationOpenShift(open_shift_id, requestType) {
    return super.postJSON(`${this.base_url}/open-shift/${open_shift_id}/remove-application`, requestType);
  }

  getOpenShift(open_shift_id, requestType) {
    return super.getJSON(`${this.base_url}/open-shift/${open_shift_id}/details`, requestType);
  }

  assignUsersOpenShift(open_shift_id, employee_ids, requestType) {
    return super.postJSON(`${this.base_url}/open-shift/${open_shift_id}/assign-users`, { employee_ids }, requestType);
  }

  // Production Quotas
  changeProductionQuotas(location_id, date, amounts_obj, requestType) {
    return super.postJSON(
      `${this.base_url}/location/${location_id}/production_quotas/${date}`,
      amounts_obj,
      requestType,
    );
  }

  getProductionQuotasInRange(location_id, from, to, requestType) {
    return super.getJSON(
      `${this.base_url}/location/${location_id}/production_quotas?from=${from}&to=${to}`,
      requestType,
    );
  }

  getProductionQuotasPayroll(location_id, from, to) {
    return super.getJSON(`${this.base_url}/locations/${location_id}/production_quotas_payroll?from=${from}&to=${to}`);
  }

  // Templates
  getScheduleTemplates(requestType) {
    return super.getJSON(`${this.base_url}/schedule-templates`, requestType);
  }

  getScheduleTemplateById(id, requestType) {
    return super.getJSON(`${this.base_url}/schedule-templates/${id}`, requestType);
  }

  addScheduleTemplate(template, requestType) {
    return super.postJSON(`${this.base_url}/schedule-templates`, template, requestType);
  }

  changeScheduleTemplate(id, template, requestType) {
    return super.putJSON(`${this.base_url}/schedule-templates/${id}`, template, requestType);
  }

  deleteScheduleTemplate(id, requestType) {
    return super.deleteJSON(`${this.base_url}/schedule-templates/${id}`, requestType);
  }

  // Trade Shifts
  createTradeShift(trade_shift) {
    return super.postJSON(`${this.base_url}/trade-shifts`, trade_shift);
  }

  changeTradeShift(trade_shift_id, trade_shift) {
    return super.putJSON(`${this.base_url}/trade-shifts/${trade_shift_id}`, trade_shift);
  }

  deleteTradeShift(trade_shift_id) {
    return super.deleteJSON(`${this.base_url}/trade-shifts/${trade_shift_id}`);
  }

  massDeleteTradeShifts(trade_shifts_ids) {
    return super.deleteJSON(`${this.base_url}/trade-shifts`, trade_shifts_ids);
  }

  getTradeShift(trade_shift_id) {
    return super.getJSON(`${this.base_url}/trade-shifts/${trade_shift_id}`);
  }

  closeTradeShift(trade_shift_id, employee_id) {
    return super.postJSON(`${this.base_url}/trade-shifts/${trade_shift_id}/close`, { employee_id });
  }

  indicateCoworkerStatusTradeShift(trade_shift_id, status) {
    return super.putJSON(`${this.base_url}/indicate-coworkers/${trade_shift_id}/change-status`, { status });
  }

  createOpenShiftFromTradeShift(trade_shift_id) {
    return super.postJSON(`${this.base_url}/trade-shifts/${trade_shift_id}/create-open-shift`);
  }

  // Working Rules
  getWorkingRulesViolations(employeeIdArray, from, to) {
    const query = `start_timestamp=${from}&end_timestamp=${to}&employee_ids=${employeeIdArray.join(',')}`;
    return super.getJSON(`${this.base_url}/validate-schedule?${query}`);
  }

  addBreak(breakObject) {
    return super.postJSON(`${this.base_url}/attendances/${breakObject.attendance_id}/breaks`, breakObject);
  }

  changeBreak(breakObject) {
    return super.putJSON(
      `${this.base_url}/attendances/${breakObject.attendance_id}/breaks/${breakObject.id}`,
      breakObject,
    );
  }

  deleteBreak(breakObject) {
    return super.deleteJSON(`${this.base_url}/attendances/${breakObject.attendance_id}/breaks/${breakObject.id}`);
  }

  startBreak(attendanceId, payload) {
    return super.postJSON(`${this.base_url}/users/current/attendances/${attendanceId}/breaks`, payload);
  }

  endBreak(attendanceId, breakId, payload) {
    return super.postJSON(`${this.base_url}/users/current/attendances/${attendanceId}/breaks/${breakId}`, payload);
  }

  // Reset password
  sendPasswordReset(email) {
    return super.postJSON(`${this.workerAddress}/sendPasswordReset/`, {
      email,
    });
  }

  resetPassword(uid, password, passwordConfirm) {
    return super.postJSON(`${this.workerAddress}/resetPassword/`, {
      uid,
      password,
      passwordConfirm,
    });
  }

  checkPasswordToken(uid) {
    return super.postJSON(`${this.workerAddress}/checkPasswordToken/`, {
      uid,
    });
  }

  confirmRegulations(regulations_id) {
    return super.postJSON(`${this.base_url}/accepted-regulations/${regulations_id}`);
  }

  getRegulations() {
    return super.getJSON(`${this.base_url}/accepted-regulations`);
  }

  getEmployeesOrder() {
    return super.getJSON(`${this.base_url}/schedule-addons/sort-order`);
  }

  changeEmployeesOrderForLocation(locationId, orderList) {
    return super.putJSON(`${this.base_url}/schedule-addons/sort-order`, {
      location_id: locationId,
      sort_order: orderList.sort_order,
    });
  }

  getHolidays(from, to) {
    return super.getJSON(`${this.base_url}/schedule-addons/holidays?from=${from}&to=${to}`);
  }

  getShiftsSummary(from, to, locationIds) {
    return super.getJSON(
      `${this.base_url}/reports/schedule-shifts-summary?from=${from}&to=${to}&locations=${locationIds?.join(',')}`,
    );
  }

  // ------------------------------------------------------------------------
  getPaymentData(companyId) {
    return super.getJSON(`${this.base_url}/payments/clients/${companyId}`);
  }

  getUnlockPaymentData() {
    return super.getJSON(`${this.base_url}/payments/client-payment-info`);
  }

  getInvoices(companyId) {
    return super.getJSON(`${this.paymentsAddress}/invoices/${companyId}`);
  }

  payForInvoice(invoiceId) {
    return super.postJSON(`${this.base_url}/payments/invoices/${invoiceId}/pay`);
  }

  getInvoicePdf(invoiceId) {
    return super.getJSON(`${this.base_url}/payments/invoices/${invoiceId}/pdf`);
  }

  editPaymentData({ companyId, invoiceData }) {
    return super.putJSON(`${this.base_url}/payments/clients/${companyId}`, {
      company_id: companyId,
      new_data: invoiceData,
    });
  }

  getEmploymentConditions(companyId, requestType) {
    return super.getJSON(`${this.base_url}/employment-conditions/templates?companyId=${companyId}`, requestType);
  }

  addEmploymentCondition(employmentCondition, requestType) {
    return super.postJSON(`${this.base_url}/employment-conditions/templates`, employmentCondition, requestType);
  }

  updateEmploymentCondition(employmentCondition, requestType) {
    return super.putJSON(
      `${this.base_url}/employment-conditions/templates/${employmentCondition.id}?ver=v2`,
      employmentCondition,
      requestType,
    );
  }

  deleteEmploymentCondition(employmentConditionId, templateToReplace, requestType) {
    return super.deleteJSON(
      `${this.base_url}/employment-conditions/templates/${employmentConditionId}?ver=v2`,
      {
        data: { templateToReplace },
      },
      requestType,
    );
  }

  deleteEmploymentConditionArray(employmentConditionsIds, requestType) {
    return super.all(employmentConditionsIds.map(e => this.deleteEmploymentCondition(e, requestType)));
  }

  getBudgetEstimates(from, to, locationIds) {
    const query = `?from=${from}&to=${to}&location_id=${locationIds.join(',')}`;
    return super.getJSON(`${this.base_url}/budget-estimates${query}`);
  }

  addMultipleBudgetEstimates(estimates) {
    return super.postJSON(`${this.base_url}/budget-estimates/multiple`, estimates);
  }

  addBudgetEstimates(estimate) {
    return super.postJSON(`${this.base_url}/budget-estimates`, estimate);
  }

  upsertBudgetEstimates(estimate) {
    return super.putJSON(`${this.base_url}/budget-estimates`, estimate);
  }

  deleteBudgetEstimates(estimate) {
    return super.deleteJSON(`${this.base_url}/budget-estimates/${estimate.id}`);
  }

  getBudgetTargets(from, to, locationId) {
    return super.getJSON(`${this.base_url}/budget-targets?from=${from}&to=${to}&location_ids=${locationId}`);
  }

  getBudgetTargetUsage(from, to, locationIds) {
    return super.getJSON(
      `${this.base_url}/schedule/budget-usage?from=${from}&to=${to}&locations=${locationIds.join(',')}`,
    );
  }

  editBudgetTargets(budgetTargets) {
    return super.putJSON(`${this.base_url}/budget-targets`, budgetTargets);
  }

  mockInitialData() {
    return super.postJSON(`${this.base_url}/mock-data`);
  }

  getOvertime(data) {
    return super.postJSON(`${this.base_url}/overtime`, data);
  }

  getRecommendedSchedule(from, to, locationId) {
    const query = `?from=${from}&to=${to}&location_id=${locationId}`;
    return super.getJSON(`${this.base_url}/recommended-schedule${query}`);
  }

  addRecommendedSchedule(recommendedSchedule) {
    return super.putJSON(`${this.base_url}/recommended-schedule`, recommendedSchedule);
  }

  deleteRecommendedSchedule(from, to, locationId) {
    const query = `?from=${from}&to=${to}&location_id=${locationId}`;
    return super.deleteJSON(`${this.base_url}/recommended-schedule${query}`);
  }

  getOvertimeForScheduleCycle({ from, to, employeesIds, options }) {
    return super.postJSON(`${this.base_url}/overtime/summary`, { from, to, employeesIds, options });
  }

  getLeftOvertimeCollection(employeesIds, month, year) {
    const query = `?employees=${employeesIds.join(',')}&month=${month}&year=${year}`;
    return super.getJSON(`${this.base_url}/absences/overtime-collections/v2/left-overtime${query}`);
  }

  getPromoContent(query) {
    return super.getJSON(`${this.base_url}/promo-content/random${query}`);
  }

  addAttendancesOvertime(attendancesOvertime) {
    return super.putJSON(`${this.base_url}/attendance/overtime`, attendancesOvertime);
  }

  duplicateSchedulePeriod(params) {
    return super.postJSON(`${this.base_url}/schedule-addons/functions/duplicate`, params);
  }

  getManagerNotes(from, to, locationIds) {
    const query = `?from=${from}&to=${to}&location_id=${locationIds.join(',')}`;
    return super.getJSON(`${this.base_url}/schedule-addons/manager-notes${query}`);
  }

  addManagerNote(managerNote) {
    return super.putJSON(`${this.base_url}/schedule-addons/manager-notes`, managerNote);
  }

  deleteManagerNotes(notesIds) {
    return super.deleteJSON(`${this.base_url}/schedule-addons/manager-notes`, {
      data: notesIds,
    });
  }

  sendCustomNotification(notificationData) {
    return super.postJSON(`${this.base_url}/messages/send`, notificationData);
  }

  addMultipeShifts(shifts) {
    return super.postJSON(`${this.base_url}/schedule-addons/functions/add-multiple`, { shifts });
  }

  getPayrollSettings() {
    return super.getJSON(`${this.base_url}/payroll-worker/settings`);
  }

  savePayrollSettings(payrollSettings) {
    return super.putJSON(`${this.base_url}/payroll-worker/settings`, payrollSettings);
  }

  // Devices

  getDevices(company_id) {
    return super.getJSON(`${this.base_url}/device-registry/${company_id}`);
  }

  addDevice(payload) {
    return super.postJSON(`${this.base_url}/device-registry/authorize`, payload);
  }

  checkDevice(authCode) {
    return super.getJSON(`${this.base_url}/device-registry/check/authcode/${authCode}`);
  }

  getSettingsDevices(uuids) {
    return super.postJSON(`${this.base_url}/device-registry/multiple-settings`, { uuids });
  }

  saveSettingsDevice(uuid, settings) {
    return super.postJSON(`${this.base_url}/device-registry/settings/${uuid}`, { ...settings });
  }

  deleteDevice(uuids) {
    return super.deleteJSON(`${this.base_url}/device-registry/delete`, {
      data: {
        uuids,
      },
    });
  }

  // rcp-sockets
  emitRcpSockets(deviceUuids, type, data) {
    const payload = { device_uuids: deviceUuids, type, data };
    return super.postJSON(`${this.base_url}/rcp-sockets/emit`, payload);
  }

  // New async exports

  getAvailableExports() {
    return super.getJSON(`${this.base_url}/exports/available-exports`);
  }

  runExport(exportId, payload, type = 'EXPORT') {
    return super.postJSON(`${this.base_url}/exports/export`, { export_id: exportId, payload, type });
  }

  getPermissions() {
    return super.getJSON(`${this.base_url}/permissions/list`);
  }

  getRoles() {
    return super.getJSON(`${this.base_url}/permissions/roles`);
  }

  saveRoles(data) {
    return super.putJSON(`${this.base_url}/permissions/roles`, data);
  }

  deleteRole(id) {
    return super.deleteJSON(`${this.base_url}/permissions/roles/${id}`);
  }

  saveRoleForUsers(data) {
    return super.putJSON(`${this.base_url}/permissions/users/saveRole`, data);
  }

  deleteRoleForUsers(user_ids) {
    return super.deleteJSON(`${this.base_url}/permissions/users/deleteRole`, {
      data: {
        user_ids,
      },
    });
  }

  addOvertimeCollection(absence) {
    return super.postJSON(`${this.base_url}/absences/overtime-collections/v2`, absence);
  }

  addOldOvertimeCollection(absence) {
    return super.postJSON(`${this.base_url}/absences/overtime-collections`, {
      absence,
    });
  }

  editOvertimeCollection(absence) {
    return super.putJSON(`${this.base_url}/absences/overtime-collections`, absence);
  }

  getOvertimeCollections(from, to, employees, requestType = 'regular') {
    return super.getJSON(
      `${this.base_url}/absences/overtime-collections?from=${from}&to=${to}&employees=${uniq(employees).join(',')}`,
      requestType,
    );
  }

  getNewOvertimeCollections(from, to, employees, requestType = 'regular') {
    return super.getJSON(
      `${this.base_url}/absences/overtime-collections/v2?from=${from}&to=${to}&employees=${uniq(employees).join(',')}`,
      requestType,
    );
  }

  deleteOvertimeCollections(ids) {
    return super.deleteJSON(`${this.base_url}/absences/overtime-collections`, {
      data: { ids },
    });
  }

  deleteNewOvertimeCollections(ids) {
    return super.deleteJSON(`${this.base_url}/absences/overtime-collections/v2`, {
      data: { ids },
    });
  }

  publishOvertimeCollections(from, to, employees) {
    return super.postJSON(`${this.base_url}/absences/overtime-collections/publish`, { from, to, employees });
  }

  getAbsences(from, to, offset, size, filter, orderBy, direction, requestType = 'regular') {
    const orderByQuery = orderBy && direction ? `&order_by=${orderBy}&direction=${direction}` : '';

    return super.getJSON(
      `${this.base_url}/absences?from=${from}&to=${to}&size=${size}&offset=${offset}${filter}${orderByQuery}`,
      requestType,
    );
  }

  getAbsencesForEmployee(from, to, offset, employeeId, requestType = 'regular') {
    return super.getJSON(
      `${this.base_url}/absences?from=${from}&to=${to}&offset=${offset}&employees=${employeeId}`,
      requestType,
    );
  }

  getAbsence(id, requestType = 'regular') {
    return super.getJSON(`${this.base_url}/absences/${id}`, requestType);
  }

  addAbsence(absence) {
    return super.postJSON(`${this.base_url}/absences`, { ...absence });
  }

  editAbsence(id, status) {
    return super.putJSON(`${this.base_url}/absences/${id}`, { status });
  }

  deleteAbsence(id) {
    return super.deleteJSON(`${this.base_url}/absences/${id}`);
  }

  getAbsencesTypes() {
    return super.getJSON(`${this.base_url}/absences/absence-types`);
  }

  addAbsenceType(absenceType) {
    return super.postJSON(`${this.base_url}/absences/absence-types`, absenceType);
  }

  editAbsenceType(id, absenceType) {
    return super.putJSON(`${this.base_url}/absences/absence-types/${id}`, absenceType);
  }

  deleteAbsenceTypes(absenceTypeIds) {
    return super.deleteJSON(`${this.base_url}/absences/absence-types`, {
      data: {
        ids: absenceTypeIds,
      },
    });
  }

  getAbsencesForSchedule(from, to, locationIds, requestType = 'blocking') {
    const queryString = `from=${from}&to=${to}&locations=${locationIds.join(',')}&status=accepted,draft`;
    return super.getJSON(`${this.base_url}/absences/schedule?${queryString}`, requestType);
  }

  exportAbsence(exportData, requestType = 'blocking') {
    return super.postJSON(`${this.workerAddress}/export/absence-details`, { exportData }, requestType);
  }

  getAbsenceHistory(absenceId, requestType) {
    return super.getJSON(`${this.base_url}/absences/${absenceId}/history`, requestType);
  }

  getAbsenceLimitsUsage(absenceType, from, to, size, offset, employeeQueryString, requestType = 'regular') {
    const searchQuery = employeeQueryString ? `&search=${employeeQueryString}` : '';
    const params = `from=${from}&to=${to}&type=${absenceType}&size=${size}&offset=${offset}${searchQuery}`;

    return super.getJSON(`${this.base_url}/absences/limit-usage?${params}`, requestType);
  }

  getAbsencesLimitTemplates(conditionsId) {
    return super.getJSON(`${this.base_url}/absences/limit-templates?employment_conditions=${conditionsId}`);
  }

  getAbsencesLimitsEmployee(employeeId) {
    return super.getJSON(`${this.base_url}/absences/absence-limits?employee_id=${employeeId}`);
  }

  addAbsencesLimitTemplates(templates) {
    return super.postJSON(`${this.base_url}/absences/limit-templates`, {
      templates,
    });
  }

  addAbsencesLimitsEmployee(limits, employeeId) {
    return super.postJSON(`${this.base_url}/absences/absence-limits`, {
      employee_id: employeeId,
      limits,
    });
  }

  getAbsenceLimitsForEmployee(employeeId, from, to) {
    return super.getJSON(`${this.base_url}/absences/limit-usage/employee/${employeeId}?from=${from}&to=${to}`);
  }

  // dashboard
  getDashboardForManager(timestamp, requestType = 'blocking') {
    return super.getJSON(`${this.base_url}/new-reports/dashboard?timestamp=${timestamp}`, requestType);
  }

  // reports

  getWorkTimeReport(from, to, locationIds, jobTitleIds, employmentConditionsIds, requestType = 'blocking') {
    const queryString = `?from=${from}&to=${to}&locations=${locationIds.join(',')}&job_titles=${jobTitleIds.join(
      ',',
    )}&employment_conditions=${employmentConditionsIds.join(',')}`;

    return super.getJSON(`${this.base_url}/new-reports/worktime-report${queryString}`, requestType);
  }

  getSalesReport(from, to, locationIds, jobTitleIds, employmentConditionsIds, requestType = 'blocking') {
    const queryString = `?from=${from}&to=${to}&locations=${locationIds.join(',')}&job_titles=${jobTitleIds.join(
      ',',
    )}&employment_conditions=${employmentConditionsIds.join(',')}`;

    return super.getJSON(`${this.base_url}/new-reports/sales-report${queryString}`, requestType);
  }

  // spmh based bonuses. Custom feature for a specific customer(s)

  calculateSpmhBasedBonuses(employeeIds, locationIds, from, to) {
    return super.postJSON(`${this.base_url}/bonus/calculate-bonuses`, {
      employee_ids: employeeIds,
      location_ids: locationIds,
      from,
      to,
    });
  }

  getBonus(employeeIds, locationIds, from, to, requestType = 'blocking') {
    const queryString = `?from=${from}&to=${to}&locations=${locationIds.join(',')}&employees=${employeeIds.join(',')}`;
    return super.getJSON(`${this.base_url}/bonus${queryString}`, requestType);
  }

  // chat

  createConversation(participants, subject) {
    return super.postJSON(`${this.base_url}/chat/conversations`, { participants, subject });
  }

  getChatSessionSignature() {
    return super.getJSON(`${this.base_url}/chat/session`);
  }

  getChatUsers() {
    return super.getJSON(`${this.base_url}/chat/users`);
  }

  // employee timesheet

  deleteCurrentUserShifts(shiftIdsToDelete, requestType) {
    return super.deleteJSON(`${this.base_url}/users/current/shifts`, { data: { shiftIdsToDelete } }, requestType);
  }

  addCurrentUserShifts(shifts) {
    return super.postJSON(`${this.base_url}/users/current/shifts`, shifts);
  }

  editCurrentUserShift(shift) {
    const { id, ...rest } = shift;
    return super.putJSON(`${this.base_url}/users/current/shifts/${id}`, rest);
  }

  getFreePin() {
    return super.getJSON(`${this.base_url}/employees/pin`);
  }

  // enova exports

  exportEnovaAttendances(employeeIds, locationIds, from, to, requestType = 'blocking') {
    return super.postJSON(
      `${this.base_url}/enova/export-attendances`,
      {
        employeeIds,
        locationIds,
        from,
        to,
      },
      requestType,
    );
  }

  exportEnovaSchedule(employeeIds, locationIds, from, to, requestType = 'blocking') {
    return super.postJSON(
      `${this.base_url}/enova/export-schedule`,
      {
        employeeIds,
        locationIds,
        from,
        to,
      },
      requestType,
    );
  }

  // Weather forecast
  getWeatherForecast(locationIds, language) {
    return super.getJSON(
      `${this.base_url}/weather?locationIds=${locationIds.join(',')}&language=${
        getLocaleConfig(language).weatherLanguage
      }`,
    );
  }

  // Budget metrics data
  getBudgetMetricsData(metricId, from, to) {
    const params = `from=${from}&to=${to}`;
    return super.getJSON(`${this.base_url}/budget-metrics/${metricId}/data?${params}`);
  }

  updateBudgetMetricData(metricId, date, data) {
    const payload = {
      date,
      data,
    };
    return super.putJSON(`${this.base_url}/budget-metrics/${metricId}/data`, payload);
  }

  // Budget metrics
  getBudgetMetrics(metricIds) {
    const params = metricIds ? `?metricIds=${metricIds.join(',')}` : '';
    return super.getJSON(`${this.base_url}/budget-metrics${params}`);
  }

  createBudgetMetrics(metrics) {
    return super.postJSON(`${this.base_url}/budget-metrics`, metrics);
  }

  editBudgetMetrics(metrics) {
    return super.putJSON(`${this.base_url}/budget-metrics`, metrics);
  }

  deleteBudgetMetrics(metricIds) {
    return super.deleteJSON(`${this.base_url}/budget-metrics?metricIds=${metricIds.join(',')}`);
  }

  // export history
  getExportHistory(size, offset, requestType = 'blocking') {
    const queryString = `?size=${size}&offset=${offset}`;
    return super.getJSON(`${this.base_url}/exports/history${queryString}`, requestType);
  }

  deleteExportHistoryItem(id, requestType) {
    return super.deleteJSON(`${this.base_url}/exports/history/${id}`, requestType);
  }

  downloadExport(id, requestType) {
    return super.getJSON(`${this.base_url}/exports/file-url/${id}`, requestType);
  }

  addMassEmployees(employees) {
    return super.postJSON(`${this.base_url}/employees/add-multiple`, employees);
  }

  validateToken() {
    return super.getJSON(`${this.base_url}/security/auth-token/validate`);
  }

  getLabels() {
    return super.getJSON(`${this.base_url}/labels`);
  }

  createLabel(label) {
    return super.postJSON(`${this.base_url}/labels`, label);
  }

  updateLabel(label) {
    return super.putJSON(`${this.base_url}/labels/${label.id}`, { label: label.label, color: label.color });
  }

  deleteLabels(labelIds) {
    return super.deleteJSON(`${this.base_url}/labels`, { data: { labelIds } });
  }

  assignLabelsToAttendance(attendanceId, labelIds) {
    return super.postJSON(`${this.base_url}/attendances/${attendanceId}/labels`, { labelIds });
  }

  unassignLabelsFromAttendance(attendanceId, labelIds) {
    return super.deleteJSON(`${this.base_url}/attendances/${attendanceId}/labels`, { data: { labelIds } });
  }

  getPayrollForEmployee(from, to) {
    return super.getJSON(`${this.base_url}/payroll/current?from=${from}&to=${to}`);
  }

  getContracts(employeeIds, from, to, requestType) {
    return super.postJSON(
      `${this.base_url}/contracts/list`,
      { from, to, employeeIds: employeeIds.map(String) },
      requestType,
    );
  }

  getAppVersion() {
    return super.getJSON(`${this.base_url}/app-version/current?time=${new Date().getTime()}`);
  }

  createContracts(contracts) {
    return super.postJSON(`${this.base_url}/contracts`, { contracts });
  }

  deleteContracts(ids) {
    return super.deleteJSON(`${this.base_url}/contracts`, { data: { ids } });
  }

  updateContracts(contracts) {
    return super.putJSON(`${this.base_url}/contracts`, { contracts });
  }

  getLoanEmployeesProposalsWitPost(from, to, locationIds) {
    const payload = { locations: locationIds, from, to };
    return super.postJSON(`${this.base_url}/loan-employees-proposals/list`, payload);
  }

  acceptLoanEmployeesProposal(data) {
    return super.putJSON(`${this.base_url}/loan-employees-proposals/accept`, data);
  }

  createLoanEmployeesProposal(data) {
    return super.postJSON(`${this.base_url}/loan-employees-proposals`, data);
  }

  deleteLoanEmployeesProposal(id) {
    return super.deleteJSON(`${this.base_url}/loan-employees-proposals/${id}`);
  }

  getByLocationGroupAndJobTitle(locationId, jobTitleId, date) {
    return super.getJSON(
      `${this.base_url}/employees/by-location-and-job-title?location=${locationId}&job_title=${jobTitleId}&date=${date}`,
    );
  }

  getEmployeesNames(employeeIds) {
    return super.getJSON(`${this.base_url}/employees/employee-names?employees=${employeeIds.join(',')}`);
  }

  getLoanEmployeesProposalsAssignments(from, to, locationIds) {
    return super.getJSON(
      `${this.base_url}/loan-employees-proposals-assignments?from=${from}&to=${to}&locations=${locationIds.join()}`,
    );
  }

  getOpenShiftsAssignments(from, to, locationIds) {
    return super.getJSON(
      `${this.base_url}/open-shifts/assignments?from=${from}&to=${to}&locations=${locationIds.join()}`,
    );
  }

  getCustomFieldsDefinitions() {
    return super.getJSON(`${this.base_url}/custom-fields-definitions`);
  }

  getUsersCustomFields(employeeIds) {
    return super.postJSON(`${this.base_url}/companies/employees/custom-fields`, { employeeIds });
  }

  getModificationBlockades(from, to, employeeIds) {
    return super.getJSON(
      `${this.base_url}/modification-blockades?from=${from}&to=${to}&employee_ids=${employeeIds.join(',')}`,
    );
  }

  updateMultipleEmployees(employees, requestType) {
    return super.putJSON(`${this.base_url}/employees`, { employees }, requestType);
  }

  setDefaultEntraIdAccount(userId) {
    return super.putJSON(`${this.base_url}/employees/azure-ids/${userId}/default`, {});
  }

  unsetDefaultEntraIdAccount(userId) {
    return super.putJSON(`${this.base_url}/employees/azure-ids/${userId}/default/unset`, {});
  }

  getFreeDaysMarkings(from, to, employees) {
    const query = `?from=${from}&to=${to}&employees=${employees.join(',')}`;
    return super.getJSON(`${this.base_url}/free-days-markings${query}`);
  }

  createFreeDayMarkings(freeDaysMarkings) {
    return super.postJSON(`${this.base_url}/free-days-markings`, { items: freeDaysMarkings });
  }

  deleteFreeDayMarkings(freeDaysIds) {
    return super.deleteJSON(`${this.base_url}/free-days-markings`, { data: { ids: freeDaysIds } });
  }

  updateFreeDayMarkings(freeDaysMarkings) {
    return super.putJSON(`${this.base_url}/free-days-markings`, { items: freeDaysMarkings });
  }

  getLocationGroupsForUser() {
    return super.getJSON(`${this.base_url}/location-groups`);
  }
}

export default KadroConnections;
