import { addDays, differenceInDays } from 'date-fns';
import moment from 'moment';

import { dateModes } from '@/constants/dateModes';
import { viewDateModes } from '@/constants/viewDateModes';
import { DateModeEnum, MainDateStore } from '@/types/mainDateStore';

import { convertDateToStandardFormat } from '../dateHelper';

export const getCustomDateByPathname = (
  customDate: MainDateStore['customDate'],
  dateMode: MainDateStore['dateMode'],
  pathname: Location['pathname'],
): MainDateStore['customDate'] => {
  const viewDateMode = viewDateModes[pathname];
  if (!viewDateMode) return customDate;

  const isCustomDateMode = dateMode === DateModeEnum.CUSTOM;
  if (!isCustomDateMode && viewDateMode.allowed.includes(dateMode)) return customDate;

  const amountOfDays = differenceInDays(new Date(customDate.end), new Date(customDate.start));
  if (viewDateMode.maxDayDiff && viewDateMode.maxDayDiff >= amountOfDays) return customDate;

  const { length: possibleModeLength } = dateModes.find(mode => mode.type === dateMode);
  const { length: defaultModeLength } = dateModes.find(mode => mode.type === viewDateMode.default);
  const modeLength = isCustomDateMode ? defaultModeLength : possibleModeLength;
  const daysToAdd = typeof modeLength === 'number' ? modeLength : 7;
  return {
    start: customDate.start,
    end: convertDateToStandardFormat(addDays(new Date(customDate.start), daysToAdd)),
  };
};

export const addNDaysToDate = (daysToAdd: number, date?: string) => {
  const startDate = date ? moment(date) : moment();
  return convertDateToStandardFormat(startDate.add(daysToAdd, 'd'));
};
