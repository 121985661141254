import { defineMessage } from 'react-intl';

import { GlobalNotifications } from '@/middlewares/notifications/notifications';

import { createUserEvent, createUserEvents, deleteUserEvent, updateUserEvent } from './userEvents.asyncActions';

export const userEventNotifications: GlobalNotifications = {
  [createUserEvent.rejected.type]: {
    title: defineMessage({
      id: 'userEvents.createUserEvent.error',
      defaultMessage: 'Wystąpił błąd podczas dodawania wydarzenia',
    }),
  },
  [createUserEvent.fulfilled.type]: {
    title: defineMessage({
      id: 'userEvents.createUserEvent.success',
      defaultMessage: 'Pomyślnie dodano wydarzenie',
    }),
  },
  [createUserEvents.rejected.type]: {
    title: defineMessage({
      id: 'userEvents.createUserEvents.error',
      defaultMessage: 'Wystąpił błąd podczas dodawania wydarzeń',
    }),
  },
  [createUserEvents.fulfilled.type]: {
    title: defineMessage({
      id: 'userEvents.createUserEvents.success',
      defaultMessage: 'Pomyślnie dodano wydarzenia',
    }),
  },
  [deleteUserEvent.rejected.type]: {
    title: defineMessage({
      id: 'userEvents.deleteUserEvent.error',
      defaultMessage: 'Wystąpił błąd podczas usuwania wydarzenia',
    }),
  },
  [deleteUserEvent.fulfilled.type]: {
    title: defineMessage({
      id: 'userEvents.deleteUserEvent.success',
      defaultMessage: 'Pomyślnie usunięto wydarzenie',
    }),
  },
  [updateUserEvent.rejected.type]: {
    title: defineMessage({
      id: 'userEvents.updateUserEvent.error',
      defaultMessage: 'Wystąpił błąd podczas edycji wydarzenia',
    }),
  },
  [updateUserEvent.fulfilled.type]: {
    title: defineMessage({
      id: 'userEvents.updateUserEvent.success',
      defaultMessage: 'Pomyślnie zmieniono wydarzenie',
    }),
  },
};
