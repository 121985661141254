import * as actionTypes from '@/constants/ActionTypes.js';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { conn } from './index';

export const getEmployeesNamesSuccesful = (employeeIds: string[]) => ({
  type: actionTypes.GET_EMPLOYEES_NAMES,
  payload: employeeIds,
});

export const getEmployeesNames = (employeeIds: string[]) => async dispatch => {
  try {
    if (isEmptyArray(employeeIds)) return;
    const { data } = await conn.getEmployeesNames(employeeIds);
    dispatch(getEmployeesNamesSuccesful(data));
  } catch (e) {
    console.error(e);
  }
};
