import * as actionTypes from '@/constants/ActionTypes.js';

import { conn } from './index';

export const addAttendancesOvertimeSuccess = (attendancesOvertime, attendances) => ({
  type: actionTypes.ADD_ATTENDANCES_OVERTIME_SUCCESS,
  payload: {
    attendances,
    attendancesOvertime,
  },
});

export const addAttendancesOvertime = (attendancesOvertime, attendances) => async dispatch => {
  await conn.addAttendancesOvertime(attendancesOvertime).then(() => {
    dispatch(addAttendancesOvertimeSuccess(attendancesOvertime, attendances));
  });
};
