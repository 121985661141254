import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { startEdit } from '@/actions/companymanage';
import Button from '@/components/common/Basic/Button.jsx';
import Icon from '@/components/common/Basic/Icon/Icon';
import browserHistory from '@/constants/browserHistory';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectCurrentUserRoleId } from '@/redux-store/currentUser';
import { selectCompanyRoles } from '@/redux-store/roles';
import { selectLocationById } from '@/redux-store/userLocations';
import { selectWeatherForecastByLocationIdAndDate } from '@/redux-store/weatherForecast';
import { Location } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { canEmployeeEditLocations } from '@/utils/userEmployeesHelpers';

import { BUTTON_MODIFIERS, messages } from './ScheduleTableWeatherPopoverContent.utils';

import './scheduleTableWeatherPopoverContent.scss';

type Props = {
  locationCoordinatesExist: boolean;
  date: StandardDate;
  locationId: Location['id'];
};

export const ScheduleTableWeatherPopoverContent = ({ locationCoordinatesExist, locationId, date }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const location = useAppSelector(selectLocationById(locationId));
  const weatherForecast = useAppSelector(selectWeatherForecastByLocationIdAndDate(locationId, date));
  const companyRoles = useAppSelector(selectCompanyRoles);
  const currentUserRoleId = useAppSelector(selectCurrentUserRoleId);

  const canEditLocations = useMemo(
    () => canEmployeeEditLocations(companyRoles, currentUserRoleId),
    [companyRoles, currentUserRoleId],
  );

  const onClick = useCallback(() => {
    browserHistory.push('/companymanage/locations');
    dispatch(startEdit(locationId, 'locations'));
  }, [dispatch, locationId]);

  if (!locationCoordinatesExist)
    return (
      <div className="k-weatherPopover k-weatherPopover__noCoordinates">
        <div className="k-weatherPopover__noCoordinatesTitle">{intl.formatMessage(messages.noCoordinatesTitle)}</div>
        <div className="k-weatherPopover__noCoordinatesDescription">
          {intl.formatMessage(messages.noCoordinatesText)}
        </div>
        {canEditLocations && (
          <Button modifiers={BUTTON_MODIFIERS} onClick={onClick}>
            {intl.formatMessage(messages.settingsButton)}
          </Button>
        )}
      </div>
    );

  if (!weatherForecast)
    return (
      <div className="k-weatherPopover k-weatherPopover__noCoordinates">
        <div className="k-weatherPopover__description">{intl.formatMessage(messages.noWeatherText)}</div>
      </div>
    );

  const { minTemp, maxTemp, chanceForPrecipitation, weatherType, description } = weatherForecast;

  return (
    <div className="k-weatherPopover">
      <Icon type="material-symbols" className="k-weatherPopover__weatherIcon" name={weatherType} />
      <div className="k-weatherPopover__temperatureContainer">
        <span className="k-weatherPopover__tmperature k-weatherPopover__temperature--high">{maxTemp}°</span>
        <span className="k-weatherPopover__tmperature k-weatherPopover__temperature--low">{minTemp}°</span>
      </div>
      <div className="k-weatherPopover__precipitationContainer">
        <Icon type="material-outlined" className="k-weatherPopover__precipitationIcon" name="water_drop" />
        {chanceForPrecipitation}%
      </div>
      <div className="k-weatherPopover__description">{description}</div>
      <div className="k-weatherPopover__location">{location?.city}</div>
    </div>
  );
};
