import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { showModal } from '@/actions/uiState.js';
import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { SCSS_COLORS } from '@/constants/colors.js';
import { ADD_EVENT_MODAL, EDIT_EVENT_MODAL } from '@/constants/modalTypes';
import {
  selectUserEventById,
  showDeleteUserEventConfirmModal,
  UserEvent,
  UserEventTypeEnum,
} from '@/redux-store/events';
import { useAppDispatch, useAppSelector } from '@/redux-store/hooks';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectScheduleLocationFilter } from '@/redux-store/scheduleLocationFilter';
import { selectLocationById } from '@/redux-store/userLocations';
import { selectIsLoggedUserEmployee } from '@/redux-store/userPermissions';
import { checkIsMonthlyScheduleView } from '@/utils/schedule/scheduleStyles/scheduleStyles';
import { truncateText } from '@/utils/stringHelpers';

import ScheduleShiftBlock from '../ScheduleShiftBlock/ScheduleShiftBlock.jsx';

type Props = {
  eventId: UserEvent['id'];
};

const eventsColors: Record<UserEventTypeEnum, string> = {
  [UserEventTypeEnum.IMPORTANT]: SCSS_COLORS.red_400,
  [UserEventTypeEnum.NOTE]: SCSS_COLORS.yellow_500,
};

export const ScheduleEvent = memo(({ eventId }: Props) => {
  const dispatch = useAppDispatch();
  const isEmployee = useAppSelector(selectIsLoggedUserEmployee);
  const dateArray = useAppSelector(selectDateArray);
  const event = useAppSelector(selectUserEventById(eventId));
  const locationsIds = useAppSelector(selectScheduleLocationFilter);
  const { date, hours, type, description, location } = event || {};
  const locationName = useAppSelector(selectLocationById(location.id))?.name;
  const [from, to] = hours.split('-');
  const isMonthView = checkIsMonthlyScheduleView(dateArray);
  const size = isMonthView ? 'month' : 'week';

  const title = !isMonthView && locationsIds.length === 1 ? truncateText(description) : locationName;
  const isImportantEvent = type === UserEventTypeEnum.IMPORTANT;

  const onEdit = useCallback(() => dispatch(showModal(EDIT_EVENT_MODAL, event)), [dispatch, event]);

  const onAdd = useCallback(
    e => {
      e.stopPropagation();
      dispatch(showModal(ADD_EVENT_MODAL, { date }));
    },
    [date, dispatch],
  );

  const onDelete = useCallback(
    e => {
      e.stopPropagation();
      dispatch(showDeleteUserEventConfirmModal(event));
    },
    [dispatch, event],
  );

  return (
    <HoverPopOver
      popover={{
        title: <FormattedMessage id="schedule.events.popupHeader" defaultMessage="Opis" />,
        content: description,
      }}
    >
      <div>
        <ScheduleShiftBlock
          from={from}
          to={to}
          color={eventsColors[type]}
          size={size}
          showNoteIcon={!isImportantEvent}
          showImportantNoteIcon={isImportantEvent}
          title={title}
          onEdit={onEdit}
          onAdd={onAdd}
          onDelete={onDelete}
          onClick={onEdit}
          isEmployee={isEmployee}
        />
      </div>
    </HoverPopOver>
  );
});

ScheduleEvent.displayName = 'ScheduleEvent';
