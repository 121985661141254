import { connect } from 'react-redux';

import { addShift, addShiftsWithRepeat, editShift, showConfirmModal } from '@/actions';
import { showDeleteAttendanceConfirmModal } from '@/actions/attendances.js';
import { getHistoryForShift } from '@/actions/history.js';
import { toggleNonWorkingDays } from '@/actions/schedule.jsx';
import { showDeleteShiftConfirmModal } from '@/actions/schedule/shifts';
import { hideModal } from '@/actions/uiState.js';
import { ADD_SHIFT_MODAL } from '@/constants/modalTypes.js';

import AddShiftModal from '../../modals/AddShiftModal/AddShiftModal.jsx';

const mapStateToProps = state => {
  const employeeId = state.reducer.uiState.modalObject[ADD_SHIFT_MODAL]?.employee.id;
  return {
    showModal: state.reducer.uiState.showModal === ADD_SHIFT_MODAL,
    modalObject: state.reducer.uiState.modalObject[ADD_SHIFT_MODAL],
    mainDateStore: state.reducer.mainDateStore,
    userJobTitles: state.reducer.userJobTitles,
    userShiftblocks: state.reducer.userShiftblocks,
    demoAccount: state.reducer.demo.demoAccount,
    userEmployees: state.reducer.userEmployees,
    nonWorkingDays: state.reducer.scheduleUIState.nonWorkingDays,
    employeeAbsences: state.reducer.overtimeCollections[employeeId],
    locationSettings: state.reducer.settings.locationSettings,
    employeeContracts: state.reducer.contracts[employeeId],
    userLocations: state.reducer.userLocations,
  };
};

const mapDispatchToProps = {
  addShift,
  addShiftsWithRepeat,
  onHide: hideModal,
  editShift,
  getHistoryForShift,
  toggleNonWorkingDays,
  showConfirmModal,
  showDeleteAttendanceConfirmModal,
  showDeleteShiftConfirmModal,
};
const AddShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddShiftModal);

export default AddShiftModalContainer;
