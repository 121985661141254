import { createSelector } from '@reduxjs/toolkit';

import { Location } from '@/types';
import { StandardDate } from '@/types/dates.types';

import { RootState } from '../types';

export const selectWeatherForecast = (state: RootState) => state.reducer.weatherForecast;

export const selectWeatherForecastByLocationIdAndDate = (locationId: Location['id'], date: StandardDate) =>
  createSelector(selectWeatherForecast, weatherForecast => weatherForecast[locationId]?.[date]);
