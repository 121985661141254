import { connect } from 'react-redux';

import { selectUserEventsArray } from '@/redux-store/events';

import EventsProposals from './EventsProposals.jsx';

const mapStateToProps = state => ({
  userEvents: selectUserEventsArray(state),
});

export default connect(mapStateToProps)(EventsProposals);
