import classnames from 'classnames';
import { memo, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/redux-store';
import { getUserEventsForSchedule } from '@/redux-store/events';
import { selectDateArray, selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { checkIsMonthlyScheduleView } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import ScheduleHeaderDate from '../ScheduleHeaderDate/ScheduleHeaderDate';
import { EventsTableCell } from './EventsTableCell/EventsTableCell';

import './EventsTable.scss';

const EventsTableComponent = memo(() => {
  const dispatch = useAppDispatch();
  const dateArray = useAppSelector(selectDateArray);
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const isMonthView = checkIsMonthlyScheduleView(dateArray);

  const tableClassName = classnames('k-newScheduleTable k-eventsTable', {
    'k-newScheduleTable--month': isMonthView,
  });
  const scheduleTableScrollClassName = classnames('k-newScheduleTable__scroll', {
    'k-newScheduleTable__scroll--hideScroll': isSingleDay,
  });

  useEffect(() => {
    dispatch(getUserEventsForSchedule());
  }, [dispatch, dateArray]);

  return (
    <div className={tableClassName}>
      <div className={scheduleTableScrollClassName}>
        <table>
          <thead>
            <tr className="scheduleTableHeader">
              <th>
                <FormattedMessage id="schedule.events.header" defaultMessage="Wydarzenia" />
              </th>
              {dateArray.map(date => (
                <th key={date}>
                  <ScheduleHeaderDate date={date} isMonthView={isMonthView} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              {dateArray.map(date => (
                <EventsTableCell key={date} date={date} />
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

EventsTableComponent.displayName = 'EventsTableComponent';

export const EventsTable = memo(() => {
  const { ref, inView } = useInView({ rootMargin: '100px' });
  return <div ref={ref}>{inView && <EventsTableComponent />}</div>;
});

EventsTable.displayName = 'EventsTable';
