import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createUserEvent,
  createUserEvents,
  deleteUserEvent,
  getManyEvents,
  updateUserEvent,
} from './userEvents.asyncActions';
import { UserEvent, UserEventsStoreState } from './userEvents.types';
import { createEvent, deleteEvent, getUserEvents, sliceName, sortStructure, updateEvent } from './userEvents.utils';

export const initialState: UserEventsStoreState = {
  data: {},
  lastFetchedData: {},
  structure: {},
};

const UserEventSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLastFetchedData: (state, action: PayloadAction<UserEventsStoreState['lastFetchedData']>) => {
      state.lastFetchedData = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getManyEvents.fulfilled, (state, action: PayloadAction<UserEvent[]>) => {
      getUserEvents(action.payload, state);
    });
    builder.addCase(createUserEvent.fulfilled, (state, action: PayloadAction<UserEvent>) => {
      createEvent(action.payload, state);
      sortStructure(state);
    });
    builder.addCase(createUserEvents.fulfilled, (state, action: PayloadAction<UserEvent[]>) => {
      action.payload.forEach(event => createEvent(event, state));
      sortStructure(state);
    });
    builder.addCase(deleteUserEvent.fulfilled, (state, action: PayloadAction<UserEvent>) => {
      deleteEvent(action.payload, state);
    });
    builder.addCase(updateUserEvent.fulfilled, (state, action: PayloadAction<UserEvent>) => {
      updateEvent(action.payload, state);
    });
  },
});

export const userEventsReducer = UserEventSlice.reducer;

export const { setLastFetchedData } = UserEventSlice.actions;
