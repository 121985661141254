import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import MDSelectPopover from '@/components/common/inputs/MDSelect/MDSelectPopover/MDSelectPopover.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { ADD_TEMPLATE_MODAL } from '@/constants/modalTypes.js';

import { messages } from './AddTemplateFromExistingModal.messages';

const MODAL_MODIFIERS = ['narrow'];
const Y_SELECT_OFFSET = -40;

const AddTemplateFromExistingModal = (props, { intl }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  useEffect(() => {
    setSelectedTemplateId(null);
  }, [props.isVisibleModal]);

  const templatesOptions = useMemo(
    () => props.templatesArray.map(t => ({ key: t.name, value: t.id })),
    [props.templatesArray],
  );

  const onCancelFooter = () => {
    props.showModal(ADD_TEMPLATE_MODAL);
  };

  const onSubmit = () => {
    const template = props.templatesArray.find(({ id }) => id === selectedTemplateId);
    props.addNewTemplate(`Kopia ${template.name}`, template.shifts, template.type);
    props.hideModal();
  };

  return (
    <MDKadroModal
      show={props.isVisibleModal}
      onSubmit={onSubmit}
      onHide={props.hideModal}
      title={intl.formatMessage(messages.title)}
      modifiers={MODAL_MODIFIERS}
      disableConfirm={!selectedTemplateId}
      onCancelFooter={onCancelFooter}
      cancelText={intl.formatMessage(messages.back)}
      withoutOverlayScroll
    >
      <MDSelectPopover
        name={intl.formatMessage(messages.chooseTemplate)}
        options={templatesOptions}
        defaultValue={selectedTemplateId}
        onChange={setSelectedTemplateId}
        closeOnClick
        withSearch
        yDropdownOffset={Y_SELECT_OFFSET}
      />
    </MDKadroModal>
  );
};

AddTemplateFromExistingModal.contextTypes = {
  intl: PropTypes.shape({}),
};

AddTemplateFromExistingModal.propTypes = {
  hideModal: PropTypes.func,
  isVisibleModal: PropTypes.bool,
  showModal: PropTypes.func,
  templatesArray: PropTypes.arrayOf(PropTypes.shape({})),
  addNewTemplate: PropTypes.func,
};

export default AddTemplateFromExistingModal;
