import { useAppSelector } from '@/redux-store';
import { selectDateArray, selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { selectBorderCell } from '@/redux-store/schedule/quickPlanning';
import { checkIsMonthlyScheduleView } from '@/utils/schedule/scheduleStyles/scheduleStyles';

export const useScheduleTable = () => {
  const dateArray = useAppSelector(selectDateArray);
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const isMonthlySchedule = checkIsMonthlyScheduleView(dateArray);
  const borderCell = useAppSelector(selectBorderCell);
  const shouldBeTableFocused = !!borderCell.employeeId;

  return { isMonthlySchedule, isSingleDay, shouldBeTableFocused };
};
