import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux-store/types';
import { StandardDate } from '@/types/dates.types';

import { UserEvent } from './userEvents.types';

export const selectLastFetchedData = (state: RootState) => state.reducer.userEvents.lastFetchedData;

export const selectUserEventsData = (state: RootState) => state.reducer.userEvents.data;

const selectUserEventsStructure = (state: RootState) => state.reducer.userEvents.structure;

export const selectUserEventById = (id: UserEvent['id']) => createSelector(selectUserEventsData, data => data[id]);

export const selectUserEventsArray = createSelector(selectUserEventsData, data => Object.values(data));

export const selectUserEventIdsForDate = (date: StandardDate) =>
  createSelector(selectUserEventsStructure, structure => structure[date]);
