import { defineMessages } from 'react-intl';

import { convertDateToCustomFormat } from '@/utils/dateHelper.js';
import { getEditorName } from '@/utils/objectHistoryHelpers';

type ActivityLogType = 'attendance' | 'shift' | 'availability' | 'schedule' | 'schedule_shift';
type ActivityLogAction = 'create' | 'edit' | 'delete' | 'open' | 'close' | 'publish';

interface ActivityLogDisplayData {
  employees: {
    [key: number]: {
      id: number;
      first_name: string;
      last_name: string;
    };
  };
  jobTitles: {
    [key: number]: {
      id: number;
      title: string;
    };
  };
  locations: {
    [key: number]: {
      id: number;
      name: string;
      additionalInformation?: string;
    };
  };
}

interface ActivityLog {
  uuid: string;
  user_id: number;
  type: ActivityLogType;
  action: ActivityLogAction;
  old_values?: {
    job_title_id?: string;
    start_date?: string;
    end_date?: string;
    start_timestamp?: string;
    end_timestamp?: string;
  };
  new_values?: {
    job_title_id?: string;
    start_date?: string;
    end_date?: string;
    start_timestamp?: string;
    end_timestamp?: string;
    from?: string;
    to?: string;
  };
  location_id: number;
  company_id: string;
  created_at: string;
  object_user_id?: number;
}

const actionsToShow = ['create', 'edit', 'delete', 'open', 'close', 'publish'];

const messages = defineMessages({
  createShift: {
    id: 'activityLog.createShift',
    defaultMessage: '{editorName} dodał/a zmianę w lokalizacji: {locationName}',
  },
  createAttendance: {
    id: 'activityLog.createAttendance',
    defaultMessage: '{editorName} dodał/a obecność w lokalizacji {locationName}',
  },
  editShift: {
    id: 'activityLog.editShift',
    defaultMessage:
      '{editorName} edytował/a zmianę w lokalizacji: {locationName}, na stanowisku: {jobTitleName}, w dniu: {date}',
  },
  editAttendance: {
    id: 'activityLog.editAttendance',
    defaultMessage: '{editorName} edytował/a obecność w lokalizacji: {locationName}, w dniu: {date}',
  },
  deleteShift: {
    id: 'activityLog.deleteShift',
    defaultMessage:
      '{editorName} usunął/ęła zmianę w lokalizacji: {locationName}, na stanowisku: {jobTitleName}, w dniu: {date}',
  },
  deleteAttendance: {
    id: 'activityLog.deleteAttendance',
    defaultMessage: '{editorName} usunął/ęła obecność w lokalizacji: {locationName}, w dniu: {date}',
  },
  openAttendance: {
    id: 'activityLog.openAttendance',
    defaultMessage: '{editorName} zarejestrował/a rozpoczęcie obecności w lokalizacji {locationName}',
  },
  closeAttendance: {
    id: 'activityLog.closeAttendance',
    defaultMessage: '{editorName} zarejestrował/a zakończenie obecności w lokalizacji {locationName}',
  },
  createShiftDetailed: {
    id: 'activityLog.addShiftDetailed',
    defaultMessage:
      '{editorName} dodał/a zmianę dla użytkownika {employeeName} w lokalizacji: {locationName}, na stanowisku: {jobTitleName}, w dniu {date}',
  },
  editShiftDetailed: {
    id: 'activityLog.editShiftDetailed',
    defaultMessage:
      '{editorName} edytował/a zmianę użytkownika {employeeName} w lokalizacji: {locationName}, na stanowisku: {jobTitleName}, w dniu: {date}',
  },
  deleteShiftDetailed: {
    id: 'activityLog.deleteShiftDetailed',
    defaultMessage:
      '{editorName} usunął/ęła zmianę użytkownika {employeeName} w lokalizacji: {locationName}, na stanowisku: {jobTitleName}, w dniu: {date}',
  },
  openAttendanceDetailed: {
    id: 'activityLog.openAttendanceDetailed',
    defaultMessage: '{editorName} zarejestrował/a rozpoczęcie obecności w lokalizacji {locationName}, w dniu {date}',
  },
  closeAttendanceDetailed: {
    id: 'activityLog.closeAttendanceDetailed',
    defaultMessage: '{editorName} zarejestrował/a zakończenie obecności w lokalizacji {locationName}, w dniu {date}',
  },
  createAttendanceDetailed: {
    id: 'activityLog.createAttendanceDetailed',
    defaultMessage:
      '{editorName} dodał/a obecność użytkownikowi {employeeName} w lokalizacji {locationName}, w dniu {date}',
  },
  editAttendanceDetailed: {
    id: 'activityLog.editAttendanceDetailed',
    defaultMessage:
      '{editorName} edytował/a obecność użytkownika {employeeName} w lokalizacji: {locationName}, w dniu: {date}',
  },
  deleteAttendanceDetailed: {
    id: 'activityLog.deleteAttendanceDetailed',
    defaultMessage:
      '{editorName} usunął/ęła obecność użytkownika {employeeName} w lokalizacji: {locationName}, w dniu: {date}',
  },
  publishSchedule: {
    id: 'activityLog.publishSchedule',
    defaultMessage: 'Nowy grafik został opublikowany',
  },
  publishScheduleDetailed: {
    id: 'activityLog.publishScheduleDetailed',
    defaultMessage: '{editorName} opublikował/a grafik w lokalizacji: {locationName}, w okresie {date} ',
  },
});

const basicMessages = {
  shiftMessages: {
    create: messages.createShift,
    edit: messages.editShift,
    delete: messages.deleteShift,
  },
  attendanceMessages: {
    create: messages.createAttendance,
    open: messages.openAttendance,
    close: messages.closeAttendance,
    edit: messages.editAttendance,
    delete: messages.deleteAttendance,
  },
  scheduleMessages: {
    publish: messages.publishSchedule,
  },
};

const detailedMessages = {
  shiftMessages: {
    create: messages.createShiftDetailed,
    edit: messages.editShiftDetailed,
    delete: messages.deleteShiftDetailed,
  },
  attendanceMessages: {
    create: messages.createAttendanceDetailed,
    open: messages.openAttendanceDetailed,
    close: messages.closeAttendanceDetailed,
    edit: messages.editAttendanceDetailed,
    delete: messages.deleteAttendanceDetailed,
  },
  scheduleMessages: {
    publish: messages.publishScheduleDetailed,
  },
};

export const getMessageType = (log: ActivityLog) => {
  const { type, action, object_user_id: objectUserId } = log;
  let message: { defaultMessage: string; id: string } | '' = '';
  const hasObjectUserId = Boolean(objectUserId);
  if (hasObjectUserId) {
    if (type === 'attendance') message = detailedMessages.attendanceMessages[action];
    if (type === 'shift' || type === 'schedule_shift') message = detailedMessages.shiftMessages[action];
    if (type === 'schedule') message = detailedMessages.scheduleMessages[action];
  } else {
    if (type === 'attendance') message = basicMessages.attendanceMessages[action];
    if (type === 'shift' || type === 'schedule_shift') message = basicMessages.shiftMessages[action];
    if (type === 'schedule') message = basicMessages.scheduleMessages[action];
  }
  return message;
};

export const getDateForMessageLog = (log: ActivityLog) => {
  const { old_values: oldValues, new_values: newValues, type, action } = log;
  if (type === 'attendance')
    return convertDateToCustomFormat(oldValues?.start_timestamp || newValues?.start_timestamp, 'YYYY-MM-DD') || '';
  if (type === 'schedule' && action === 'publish') {
    const { from, to } = newValues;
    return `${convertDateToCustomFormat(from, 'YYYY-MM-DD')} - ${convertDateToCustomFormat(to, 'YYYY-MM-DD')}`;
  }
  return convertDateToCustomFormat(oldValues?.start_date || newValues?.start_date, 'YYYY-MM-DD') || '';
};

export const prepareMessage = (log: ActivityLog, displayData: ActivityLogDisplayData, intl: any) => {
  const { action, old_values: oldValues, object_user_id: objectUserId, new_values: newValues } = log;
  if (!actionsToShow.includes(action)) return;
  const { employees, jobTitles, locations } = displayData;
  const editorName = getEditorName(log, employees) || '[Nieznany]';
  const employee = objectUserId ? employees[objectUserId] : null;
  const employeeName = employee ? `${employee.first_name} ${employee.last_name}` : '';
  const location = locations[log.location_id];
  const locationName = location.name;
  const jobTitle = jobTitles[Number(oldValues?.job_title_id)] || jobTitles[Number(newValues?.job_title_id)];
  const jobTitleName = jobTitle ? jobTitle.title : '';
  const date = getDateForMessageLog(log);

  const message = getMessageType(log);
  if (!message) return;
  return {
    id: log.uuid,
    datetime: convertDateToCustomFormat(log.created_at, 'DD.MM.YYYY HH:mm'),
    createdAt: log.created_at,
    locationId: log.location_id,
    type: log.type,
    date,
    message: intl.formatMessage(message, {
      editorName,
      locationName,
      jobTitleName,
      employeeName,
      date,
    }),
  };
};

export const mapActivityLogMessages = (logs: ActivityLog[], displayData: ActivityLogDisplayData, intl: any) =>
  logs.map(log => prepareMessage(log, displayData, intl)).filter(Boolean);
