import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getOpenShiftTitle } from '@/components/scheduler/ScheduleTable/ScheduleTableOpenShift/OpenShiftShift/OpenShiftShift.helpers';
import { DATE_MODE_DAY } from '@/constants/dateModes';
import { ScheduleItemType } from '@/constants/dragAndDrop';
import { OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE } from '@/constants/Permissions.js';
import { checkIfContractHasJobTitle, getRelevantContractForDate } from '@/utils/contracts';
import { calculateShiftEagerUsers } from '@/utils/schedulerHelpers';

import ScheduleShiftBlock from '../../ScheduleShiftBlock/ScheduleShiftBlock.jsx';
import ScheduleSingleDayShiftBlock from '../../ScheduleSingleDayShiftBlock/ScheduleSingleDayShiftBlock.jsx';
import { messages } from './OpenShiftShift.messages';

import './OpenShiftShift.scss';

const OpenShiftShift = ({
  shift,
  date,
  locationId,
  jobTitleColor,
  showDeleteOpenShiftConfirmModal,
  openOpenShiftModal,
  openAssignUsersOpenShiftModal,
  size,
  startOpenShiftDrag,
  stopOpenShiftDrag,
  isEmployee,
  openApplyForOpenShiftModal,
  contracts,
  changeOpenShift,
  permissions,
  userLocations,
  currentUserContracts,
  isEditingScheduleDisabled,
}) => {
  const intl = useIntl();
  const color = jobTitleColor || '#FF0000';
  let eagerUsersCount = permissions.includes(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE)
    ? shift.eager_users_count
    : calculateShiftEagerUsers(shift.users, shift.location.id, shift.job_title.id, date, contracts, permissions);
  if (eagerUsersCount > 9) eagerUsersCount = '9+';
  const eagerUsersClassname = classnames('k-openShiftBlock__eagerUsers', `k-openShiftBlock__eagerUsers--${size}`);
  const [from, to] = shift.working_hours.split('-');
  const { draft } = shift;
  const hasUserLocation = useMemo(
    () => userLocations.some(location => location.id === shift.location.id),
    [userLocations, shift],
  );
  const onEdit = e => {
    if (e) e.stopPropagation();
    if (isEmployee || !hasUserLocation) {
      openApplyForOpenShiftModal(shift);
    } else if (shift.eager_users_count) {
      openAssignUsersOpenShiftModal(shift, shift.date);
    } else {
      openOpenShiftModal(shift, shift.date, locationId);
    }
  };
  const onAdd = e => {
    if (e) e.stopPropagation();
    openOpenShiftModal({}, date, locationId);
  };
  const onDelete = useMemo(() => {
    if (hasUserLocation) {
      return e => {
        if (e) e.stopPropagation();
        showDeleteOpenShiftConfirmModal(shift.id);
      };
    }
    return undefined;
  }, [hasUserLocation, showDeleteOpenShiftConfirmModal, shift.id]);

  const tooltipText = useMemo(() => {
    if (!hasUserLocation)
      return intl.formatMessage(messages.openShiftFroLocationGroups, { locationName: shift.location.name });
  }, [hasUserLocation, intl, shift.location.name]);

  const onDragStart = () => startOpenShiftDrag(shift.id, from, to);

  const editEmployeeShift = workingHours => {
    changeOpenShift(shift.id, {
      ...shift,
      draft: true,
      working_hours: workingHours,
    });
  };

  const title = getOpenShiftTitle(size, shift.shifts_remaining, shift.job_title.title, isEmployee);

  const props = {
    className: 'k-openShiftBlock',
    color,
    from,
    to,
    date,
    size,
    isDraft: draft,
    onDelete,
    deleteEmployeeShift: onDelete,
    onAdd,
    onEdit,
    showEditShiftModal: onEdit,
    editEmployeeShift,
    isDraggable: true,
    onDragStart,
    onDragEnd: stopOpenShiftDrag,
    title,
    isEmployee,
    type: ScheduleItemType.OPEN_SHIFT,
    tooltipText,
    isEditingScheduleDisabled,
  };
  const currentContract = useMemo(
    () => getRelevantContractForDate(currentUserContracts, date),
    [currentUserContracts, date],
  );
  const hasContractJobTitle = useMemo(
    () => checkIfContractHasJobTitle(currentContract, shift.job_title.id),
    [currentContract, shift],
  );

  if (!onDelete && !isEmployee && !hasContractJobTitle) return null;

  return size === DATE_MODE_DAY && !isEmployee ? (
    <ScheduleSingleDayShiftBlock {...props} draft={draft} hourFirst>
      {eagerUsersCount ? <div className={eagerUsersClassname}>{eagerUsersCount}</div> : null}
    </ScheduleSingleDayShiftBlock>
  ) : (
    <ScheduleShiftBlock {...props} onClick={onEdit}>
      {eagerUsersCount ? <div className={eagerUsersClassname}>{eagerUsersCount}</div> : null}
    </ScheduleShiftBlock>
  );
};

OpenShiftShift.propTypes = {
  size: PropTypes.string,
  date: PropTypes.string,
  locationId: PropTypes.string,
  shift: PropTypes.shape({
    id: PropTypes.string,
    working_hours: PropTypes.string,
    shifts_remaining: PropTypes.number,
    job_title: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
    date: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({})),
    draft: PropTypes.bool,
    eager_users_count: PropTypes.number,
  }),
  jobTitleColor: PropTypes.string,
  showDeleteOpenShiftConfirmModal: PropTypes.func,
  openOpenShiftModal: PropTypes.func,
  startOpenShiftDrag: PropTypes.func,
  stopOpenShiftDrag: PropTypes.func,
  isEmployee: PropTypes.bool,
  openApplyForOpenShiftModal: PropTypes.func,
  changeOpenShift: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OpenShiftShift;
