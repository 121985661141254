import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  generalSectionTitle: {
    id: 'payrollSettingsModal.generalSectionTitle',
    defaultMessage: 'Ogólne',
  },
  breakSectionTitle: {
    id: 'payrollSettingsModal.breakSectionTitle',
    defaultMessage: 'Przerwy',
  },
  workTimeSectionTitle: {
    id: 'payrollSettingsModal.workTimeSectionTitle',
    defaultMessage: 'Czas pracy',
  },
  settingsTitle: {
    id: 'payrollSettingsModal.settingsTitle',
    defaultMessage: 'Ustawienia',
  },
  generalSectionDescription: {
    id: 'payrollSettingsModal.generalSectionDescription',
    defaultMessage: 'Opcje mające wpływ na ogólne parametry ewidencji czasu pracy.',
  },
  breakSectionDescription: {
    id: 'payrollSettingsModal.breakSectionDescription',
    defaultMessage: 'Opcje mające wpływ na rozliczenie przerw w pracy rejestrowanych w module rejestracji czasu pracy.',
  },
  workTimeSectionDescription: {
    id: 'payrollSettingsModal.workTimeSectionDescription',
    defaultMessage: 'Opcje mające wpływ kolejno na sposób wyliczenia godziny rozpoczęcia oraz zakończenia pracy.',
  },
  clickHere: {
    id: 'payrollSettingsModal.clickHere',
    defaultMessage: 'KLIKNIJ TUTAJ, aby dowiedzieć się więcej.',
  },
});

export const tooltipMessages = defineMessages({
  payout: {
    id: 'payrollSettingsModal.tooltipMessages.payout',
    defaultMessage:
      'Od tej opcji zależy czy godziny pracy będą ewidencjonowane na podstawie grafiku pracy, czy obecności (tj. czas pracy). {clickHere}',
  },
  roundingGeneral: {
    id: 'payrollSettingsModal.tooltipMessages.roundingGeneral',
    defaultMessage:
      'Wartość, do jakiej będzie zaokrąglany czas pracy pracownika na przestrzeni jednej obecności. {clickHere}',
  },
  timeFormat: {
    id: 'payrollSettingsModal.tooltipMessages.timeFormat',
    defaultMessage:
      'Format wyświetlania wszystkich statystyk związanych z czasem pracy w tym sumy godzin, przerw, nadgodzin i innych. {clickHere}',
  },
  nightHoursTime: {
    id: 'payrollSettingsModal.tooltipMessages.nightHoursTime',
    defaultMessage: 'Zakres, w którym system wyliczy przepracowane godziny nocne. {clickHere}',
  },
  reduceBreaks: {
    id: 'payrollSettingsModal.tooltipMessages.reduceBreaks',
    defaultMessage:
      'Od tej opcji zależy, wedle jakiego ustawienia rozliczany jest czas przerw. Wybierz “Stały”, aby od każdego dnia pracy odjąć wybraną długość przerwy. Wybierz “Tolerancja”, aby ustawić maksymalny czas przerwy w ciągu dnia pracownika. Wybierz “Nie”, aby nie uwzględniać czasu przerw w sumie godzin pracy. {clickHere}',
  },
  rounding: {
    id: 'payrollSettingsModal.tooltipMessages.rounding',
    defaultMessage: 'Wartość, do jakiej będzie zaokrąglany czas rozpoczęcia pracy pracownika. {clickHere}',
  },
  round: {
    id: 'payrollSettingsModal.tooltipMessages.round',
    defaultMessage: 'Wybierz kierunek zaokrąglenia. {clickHere}',
  },
  toleranceStart: {
    id: 'payrollSettingsModal.tooltipMessages.toleranceStart',
    defaultMessage:
      'Wybierz zakres tolerancji względem godziny rozpoczęcia zmiany wg grafiku pracy. W czasie, w którym obecność nie przekroczy zakresu tolerancji, będzie ona wyrównana do godziny wg grafiku pracy. {clickHere}',
  },
  toleranceEnd: {
    id: 'payrollSettingsModal.tooltipMessages.toleranceEnd',
    defaultMessage:
      'Wybierz zakres tolerancji względem godziny rozpoczęcia zmiany wg grafiku pracy. W czasie, w którym obecność nie przekroczy zakresu tolerancji, będzie ona wyrównana do godziny wg grafiku pracy. {clickHere}',
  },
  weekStartHour: {
    id: 'payrollSettingsModal.tooltipMessages.weekStartHour',
    defaultMessage:
      'Początek okresu 24 godzin, oznaczających niedzielę lub święto. Wartość, przypadająca pomiędzy godziną 00:00 w dzień oznaczony jako niedziela lub święto\n a 00:00 w dniu następującym.',
  },
});

export const tooltipLinks = defineMessages({
  payout: {
    id: 'payrollSettingsModal.tooltipLinks.payout',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_68c811bc32',
  },
  roundingGeneral: {
    id: 'payrollSettingsModal.tooltipLinks.roundingGeneral',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_993c02c4fc',
  },
  timeFormat: {
    id: 'payrollSettingsModal.tooltipLinks.timeFormat',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_0ac57da827',
  },
  nightHoursTime: {
    id: 'payrollSettingsModal.tooltipLinks.nightHoursTime',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_8ccbd54e77',
  },
  reduceBreaks: {
    id: 'payrollSettingsModal.tooltipLinks.reduceBreaks',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_7b47d842b7',
  },
  rounding: {
    id: 'payrollSettingsModal.tooltipLinks.rounding',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_297f9f0f00',
  },
  round: {
    id: 'payrollSettingsModal.tooltipLinks.round',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_297f9f0f00',
  },
  toleranceStart: {
    id: 'payrollSettingsModal.tooltipLinks.toleranceStart',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_857ab3e776',
  },
  toleranceEnd: {
    id: 'payrollSettingsModal.tooltipLinks.toleranceEnd',
    defaultMessage: 'https://pomoc.kadromierz.pl/pl/articles/7955127-ustawienia-rozliczen#h_d926294b70',
  },
});
