import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ScheduleTableWeatherPopover } from '@/components/scheduler/ScheduleTable/ScheduleTableWeather/ScheduleTableWeatherPopover/ScheduleTableWeatherPopover';
import { isWeekend } from '@/utils/dateHelper.js';

import { ScheduleTableWeatherPreview } from '../ScheduleTableWeatherPreview/ScheduleTableWeatherPreview';
import { useScheduleTableWeatherCell } from './useScheduleTableWeatherCell';

const ScheduleTableWeatherCell = ({ date, selectedDisplayModeType, holidaysEnabled, dayType, locationId }) => {
  const { colSpan } = useScheduleTableWeatherCell();
  const showHolidays = selectedDisplayModeType !== 'templates' && holidaysEnabled;
  const classNames = classnames('k-weatherRow__background', {
    'k-weatherRow__background--holiday': showHolidays && dayType === 'holiday',
    'k-weatherRow__background--freeDayHoliday': showHolidays && dayType === 'freeFromWorkHoliday',
    'k-weatherRow__background--weekend': isWeekend(date),
  });
  return (
    <td colSpan={colSpan} className="k-weatherRow__cell">
      <div className={classNames} />

      <ScheduleTableWeatherPopover {...{ locationId, date }}>
        <ScheduleTableWeatherPreview {...{ locationId, date }} />
      </ScheduleTableWeatherPopover>
    </td>
  );
};

ScheduleTableWeatherCell.propTypes = {
  date: PropTypes.string,
  dayType: PropTypes.string,
  selectedDisplayModeType: PropTypes.string,
  holidaysEnabled: PropTypes.bool,
  locationId: PropTypes.string,
};
export default ScheduleTableWeatherCell;
