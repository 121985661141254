import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { logoutUser } from '@/actions/auth.jsx';
import { useAppDispatch } from '@/redux-store';

export const Logout = memo(() => {
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    console.log('handling logout 1');
    dispatch(logoutUser());
  }, [dispatch]);

  return (
    <li className="k-sideBarMenu__item">
      <button className="k-sideBarMenu__itemLink" onClick={handleLogout} type="button">
        <i className="k-sideBarMenu__itemIcon material-icons">exit_to_app</i>{' '}
        <span className="k-sideBarMenu__itemLabel">
          <FormattedMessage id="view.sidebar.logout" defaultMessage="Wyloguj" />
        </span>
      </button>
    </li>
  );
});

Logout.displayName = 'Logout';
