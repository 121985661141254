import { UserEventsStoreState } from '@/redux-store/events';
import { Location } from '@/types';

export const getEvents = (
  locationIds: Location['id'][],
  eventsIdsForDate: RecordSecondType<UserEventsStoreState['structure']>,
) =>
  Object.keys(eventsIdsForDate || {}).reduce((prev, locationId) => {
    if (locationIds.includes(locationId)) return [...prev, ...(eventsIdsForDate[locationId] || [])];
    return prev;
  }, []);
