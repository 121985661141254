import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import MDKadroModalSection from '@/components/common/MDKadroModal/MDKadroModalSection/MDKadroModalSection';
import {
  isSettingTypeAsShifts,
  MODAL_MODIFIERS,
} from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModal.helpers.jsx';
import PayrollSettingsModalBreakSection from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModalBreakSection/PayrollSettingsModalBreakSection';
import Lock from '@/components/settings/SettingsFeature/EditDisabledView';
import { DEFAULT_WEEK_START_HOUR, newPayrollBreaksSettings } from '@/constants/payrollSettings.ts';
import { WEEK_START_HOUR_ENABLE } from '@/constants/Permissions.js';
import { useInputChange } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers';
import { userHasRestrictedPayrollSettingsEdit } from '@/utils/permissionHelpers';

import { messages } from './PayrollSettingsModal.messages.js';
import PayrollSettingsModalGeneralSection from './PayrollSettingsModalGeneralSection/PayrollSettingsModalGeneralSection';
import PayrollSettingsModalWorkTimeSection from './PayrollSettingsModalWorkTimeSection/PayrollSettingsModalWorkTimeSection';

import './payrollSettingsModal.scss';

const PayrollSettingsModal = (
  {
    showModal,
    togglePayrollSettingsModal,
    changePayrollSettings,
    saveNewPayrollSettings,
    userPermissions,
    settings,
    onSave,
  },
  { intl },
) => {
  const showWeekStartHour = userPermissions.permissions.some(permission => permission === WEEK_START_HOUR_ENABLE);

  const defaultState = {
    ...(showWeekStartHour && { weekStartHour: DEFAULT_WEEK_START_HOUR }),
    ...settings,
  };

  const [state, handlers] = useInputChange(defaultState, intl, { preventClearErrors: true });
  const { payoutSetting, roundingSetting, timeFormatSetting, nighthours, breaksValue, breaksSetting, weekStartHour } =
    state;
  const isWorkTimeAndBreakSectionDisabled = isSettingTypeAsShifts(payoutSetting.type);
  const isModalDisabled = userHasRestrictedPayrollSettingsEdit(userPermissions);

  const onSubmit = useCallback(async () => {
    const hasError = Object.values(state.errors || {}).some(error => error);

    if (hasError) return;

    const changes = { ...state };
    if (changes.nighthours.search('_') >= 0) delete changes.nighthours;
    changePayrollSettings(changes);
    await saveNewPayrollSettings();
    if (onSave) onSave();
    togglePayrollSettingsModal(false);
  }, [state, changePayrollSettings, saveNewPayrollSettings, togglePayrollSettingsModal]);

  const onHide = useCallback(() => {
    handlers.setInitState();
    togglePayrollSettingsModal(false);
  }, [handlers, togglePayrollSettingsModal]);

  const changeSetting = useCallback(
    (key, value) => {
      if (userHasRestrictedPayrollSettingsEdit(userPermissions)) return;
      handlers.changeInput(createEvent(key, value));
    },
    [userHasRestrictedPayrollSettingsEdit, userPermissions, handlers],
  );

  useEffect(() => {
    if (state.payoutSetting.id !== 0) {
      changeSetting('breaksSetting', newPayrollBreaksSettings[0]);
      if (state.breaksValue !== '0') changeSetting('breaksValue', '0');
    }
  }, [state.payoutSetting.id]);

  return (
    <MDKadroModal
      show={showModal}
      onHide={onHide}
      onSubmit={onSubmit}
      title={intl.formatMessage(messages.settingsTitle)}
      modifiers={MODAL_MODIFIERS}
      disableConfirm={isModalDisabled}
      className="k-payrollSettingsModal"
    >
      <div className="k-payrollSettingsModal__wrapper">
        {isModalDisabled && <Lock />}
        <MDKadroModalSection
          title={intl.formatMessage(messages.generalSectionTitle)}
          description={intl.formatMessage(messages.generalSectionDescription)}
          content={
            <PayrollSettingsModalGeneralSection
              {...{
                changeSetting,
                payoutSetting,
                roundingSetting,
                timeFormatSetting,
                nighthours,
                weekStartHour,
                showWeekStartHour,
                errors: state.errors,
                setError: handlers.setError,
              }}
            />
          }
          disabled={isModalDisabled}
        />
        <MDKadroModalSection
          title={intl.formatMessage(messages.breakSectionTitle)}
          description={intl.formatMessage(messages.breakSectionDescription)}
          content={
            <PayrollSettingsModalBreakSection
              {...{
                breaksValue,
                breaksSetting,
                changeSetting,
                payoutSetting,
              }}
            />
          }
          disabled={isModalDisabled || isWorkTimeAndBreakSectionDisabled}
        />
        <MDKadroModalSection
          title={intl.formatMessage(messages.workTimeSectionTitle)}
          description={intl.formatMessage(messages.workTimeSectionDescription)}
          content={<PayrollSettingsModalWorkTimeSection {...{ changeSetting }} settings={state} />}
          fullWidthContent
          disabled={isWorkTimeAndBreakSectionDisabled || isModalDisabled}
        />
      </div>
    </MDKadroModal>
  );
};

PayrollSettingsModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollSettingsModal.propTypes = {
  showModal: PropTypes.bool,
  togglePayrollSettingsModal: PropTypes.func,
  settings: PropTypes.shape({
    payoutSetting: PropTypes.shape({}),
    roundingSetting: PropTypes.shape({}),
    nighthours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    breaksValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    breaksSetting: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    }),
    timeFormatSetting: PropTypes.shape({}),
  }),
  changePayrollSettings: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  saveNewPayrollSettings: PropTypes.func,
  onSave: PropTypes.func,
};

export default PayrollSettingsModal;
